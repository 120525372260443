export default {
  triangle: function triangle(width, length, d) {
    if (width === void 0) {
      width = 10;
    }
    if (length === void 0) {
      length = 15;
    }
    if (d === void 0) {
      d = 0;
    }
    var begin = d * 2;
    var path = "M ".concat(begin, ",0 L ").concat(begin + length, ",-").concat(width / 2, " L ").concat(begin + length, ",").concat(width / 2, " Z");
    return path;
  },
  vee: function vee(width, length, d) {
    if (width === void 0) {
      width = 15;
    }
    if (length === void 0) {
      length = 20;
    }
    if (d === void 0) {
      d = 0;
    }
    var begin = d * 2;
    var path = "M ".concat(begin, ",0 L ").concat(begin + length, ",-").concat(width / 2, "\n        L ").concat(begin + 2 * length / 3, ",0 L ").concat(begin + length, ",").concat(width / 2, " Z");
    return path;
  },
  circle: function circle(r, d) {
    if (r === void 0) {
      r = 5;
    }
    if (d === void 0) {
      d = 0;
    }
    var begin = d * 2;
    var path = "M ".concat(begin, ", 0\n            a ").concat(r, ",").concat(r, " 0 1,0 ").concat(r * 2, ",0\n            a ").concat(r, ",").concat(r, " 0 1,0 ").concat(-r * 2, ",0");
    return path;
  },
  rect: function rect(width, length, d) {
    if (width === void 0) {
      width = 10;
    }
    if (length === void 0) {
      length = 10;
    }
    if (d === void 0) {
      d = 0;
    }
    var begin = d * 2;
    var path = "M ".concat(begin, ",").concat(-width / 2, " \n        L ").concat(begin + length, ",").concat(-width / 2, " \n        L ").concat(begin + length, ",").concat(width / 2, " \n        L ").concat(begin, ",").concat(width / 2, " Z");
    return path;
  },
  diamond: function diamond(width, length, d) {
    if (width === void 0) {
      width = 15;
    }
    if (length === void 0) {
      length = 15;
    }
    if (d === void 0) {
      d = 0;
    }
    var begin = d * 2;
    var path = "M ".concat(begin, ",0 \n        L ").concat(begin + length / 2, ",").concat(-width / 2, " \n        L ").concat(begin + length, ",0 \n        L ").concat(begin + length / 2, ",").concat(width / 2, " Z");
    return path;
  },
  triangleRect: function triangleRect(tWidth, tLength, rWidth, rLength, gap, d) {
    if (tWidth === void 0) {
      tWidth = 15;
    }
    if (tLength === void 0) {
      tLength = 15;
    }
    if (rWidth === void 0) {
      rWidth = 15;
    }
    if (rLength === void 0) {
      rLength = 3;
    }
    if (gap === void 0) {
      gap = 5;
    }
    if (d === void 0) {
      d = 0;
    }
    var begin = d * 2;
    var rectBegin = begin + tLength + gap;
    var path = "M ".concat(begin, ",0 L ").concat(begin + tLength, ",-").concat(tWidth / 2, " L ").concat(begin + tLength, ",").concat(tWidth / 2, " Z\n            M ").concat(rectBegin, ", -").concat(rWidth / 2, "\n            L ").concat(rectBegin + rLength, " -").concat(rWidth / 2, "\n            L ").concat(rectBegin + rLength, " ").concat(rWidth / 2, "\n            L ").concat(rectBegin, " ").concat(rWidth / 2, "\n            Z");
    return path;
  }
};