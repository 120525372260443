import { __assign } from "tslib";
import { each, isPlainObject, isString, isBoolean, mix, deepMix, clone } from '@antv/util';
import Shape from '../element/shape';
import { getBBox } from '../util/graphic';
import { translate } from '../util/math';
import { uniqueId } from '../util/base';
var CACHE_BBOX = 'bboxCache';
var CACHE_CANVAS_BBOX = 'bboxCanvasCache';
var ARROWS = ['startArrow', 'endArrow'];
var ItemBase = /** @class */function () {
  function ItemBase(cfg) {
    this._cfg = {};
    this.destroyed = false;
    // 是否开启优化，由 graph 根据当前图上节点数量来控制
    this.optimize = false;
    var defaultCfg = {
      /**
       * id
       * @type {string}
       */
      id: undefined,
      /**
       * 类型
       * @type {string}
       */
      type: 'item',
      /**
       * data model
       * @type {object}
       */
      model: {},
      /**
       * g group
       * @type {G.Group}
       */
      group: undefined,
      /**
       * is open animate
       * @type {boolean}
       */
      animate: false,
      /**
       * visible - not group visible
       * @type {boolean}
       */
      visible: true,
      /**
       * locked - lock node
       * @type {boolean}
       */
      locked: false,
      /**
       * capture event
       * @type {boolean}
       */
      event: true,
      /**
       * key shape to calculate item's bbox
       * @type object
       */
      keyShape: undefined,
      /**
       * item's states, such as selected or active
       * @type Array
       */
      states: []
    };
    this._cfg = Object.assign(defaultCfg, this.getDefaultCfg(), cfg);
    var model = this.get('model');
    var id = model.id;
    var itemType = this.get('type');
    if (typeof id === 'undefined') {
      id = uniqueId(itemType);
    } else if (typeof id !== 'string') {
      id = String(id);
    }
    this.get('model').id = id;
    this.set('id', id);
    var group = cfg.group;
    if (group) {
      group.set('item', this);
      group.set('id', id);
    }
    this.init();
    this.draw();
    var shapeType = model.shape || model.type || (itemType === 'edge' ? 'line' : 'circle');
    var shapeFactory = this.get('shapeFactory');
    if (shapeFactory && shapeFactory[shapeType]) {
      var options = shapeFactory[shapeType].options;
      // merge the stateStyles from item and shape
      if (options && options.stateStyles) {
        var styles = this.get('styles') || model.stateStyles;
        styles = deepMix({}, options.stateStyles, styles);
        this.set('styles', styles);
      }
    }
  }
  /**
   * 根据 keyshape 计算包围盒
   */
  ItemBase.prototype.calculateBBox = function () {
    var keyShape = this.get('keyShape');
    var group = this.get('group');
    // 因为 group 可能会移动，所以必须通过父元素计算才能计算出正确的包围盒
    var bbox = getBBox(keyShape, group);
    bbox.x = bbox.minX;
    bbox.y = bbox.minY;
    bbox.width = bbox.maxX - bbox.minX;
    bbox.height = bbox.maxY - bbox.minY;
    bbox.centerX = (bbox.minX + bbox.maxX) / 2;
    bbox.centerY = (bbox.minY + bbox.maxY) / 2;
    return bbox;
  };
  /**
   * 根据 keyshape 计算包围盒
   */
  ItemBase.prototype.calculateCanvasBBox = function () {
    var keyShape = this.get('keyShape');
    var group = this.get('group');
    // 因为 group 可能会移动，所以必须通过父元素计算才能计算出正确的包围盒
    var bbox = getBBox(keyShape, group);
    bbox.x = bbox.minX;
    bbox.y = bbox.minY;
    bbox.width = bbox.maxX - bbox.minX;
    bbox.height = bbox.maxY - bbox.minY;
    bbox.centerX = (bbox.minX + bbox.maxX) / 2;
    bbox.centerY = (bbox.minY + bbox.maxY) / 2;
    return bbox;
  };
  /**
   * draw shape
   */
  ItemBase.prototype.drawInner = function () {
    var self = this;
    var shapeFactory = self.get('shapeFactory');
    var group = self.get('group');
    var model = self.get('model');
    group.clear();
    var visible = model.visible;
    if (visible !== undefined && !visible) self.changeVisibility(visible);
    if (!shapeFactory) {
      return;
    }
    self.updatePosition(model);
    var cfg = self.getShapeCfg(model); // 可能会附加额外信息
    var shapeType = cfg.type;
    var keyShape = shapeFactory.draw(shapeType, cfg, group);
    if (keyShape) {
      self.set('keyShape', keyShape);
      keyShape.set('isKeyShape', true);
      keyShape.set('draggable', true);
    }
    this.setOriginStyle();
    // 防止由于用户外部修改 model 中的 shape 导致 shape 不更新
    this.set('currentShape', shapeType);
    this.restoreStates(shapeFactory, shapeType);
  };
  /**
   * 设置图元素原始样式
   * @param keyShape 图元素 keyShape
   * @param group Group 容器
   */
  ItemBase.prototype.setOriginStyle = function () {
    var group = this.get('group');
    var children = group.get('children');
    var keyShape = this.getKeyShape();
    var self = this;
    var keyShapeName = keyShape.get('name');
    if (!this.get('originStyle')) {
      // 第一次 set originStyle，直接拿首次渲染所有图形的 attrs
      var originStyles = {};
      for (var i = 0; i < children.length; i++) {
        var child = children[i];
        var shapeType = child.get('type');
        var name_1 = child.get('name');
        if (name_1 && name_1 !== keyShapeName) {
          originStyles[name_1] = shapeType !== 'image' ? clone(child.attr()) : self.getShapeStyleByName(name_1);
          // The text's position and matrix is not allowed to be affected by states
          if (shapeType === 'text' && originStyles[name_1]) {
            delete originStyles[name_1].x;
            delete originStyles[name_1].y;
            delete originStyles[name_1].matrix;
          }
        } else {
          var keyShapeStyle = self.getShapeStyleByName(); // 可优化，需要去除 child.attr 中其他 shape 名的对象
          delete keyShapeStyle.path;
          delete keyShapeStyle.matrix;
          if (!keyShapeName) {
            Object.assign(originStyles, keyShapeStyle);
          } else {
            // 若 keyShape 有 name 且 !name，这个图形不是 keyShape，给这个图形一个 name
            if (!name_1) {
              var shapeName = uniqueId('shape');
              child.set('name', shapeName);
              group['shapeMap'][shapeName] = child;
              originStyles[shapeName] = shapeType !== 'image' ? clone(child.attr()) : self.getShapeStyleByName(name_1);
            } else {
              originStyles[keyShapeName] = keyShapeStyle;
            }
          }
        }
      }
      self.set('originStyle', originStyles);
    } else {
      // 第二次 set originStyles，需要找到不是 stateStyles 的样式，更新到 originStyles 中
      // 上一次设置的 originStyle，是初始的 shape attrs
      var styles_1 = this.get('originStyle');
      // let styles: ShapeStyle = {};
      if (keyShapeName && !styles_1[keyShapeName]) styles_1[keyShapeName] = {};
      // 获取当前状态样式
      var currentStatesStyle_1 = this.getCurrentStatesStyle();
      var _loop_1 = function _loop_1(i) {
        var child = children[i];
        var name_2 = child.get('name');
        var shapeAttrs = child.attr();
        if (name_2 && name_2 !== keyShapeName) {
          // 有 name 的非 keyShape 图形
          var shapeStateStyle_1 = currentStatesStyle_1[name_2];
          if (!styles_1[name_2]) styles_1[name_2] = {};
          if (shapeStateStyle_1) {
            Object.keys(shapeAttrs).forEach(function (key) {
              var value = shapeAttrs[key];
              if (value !== shapeStateStyle_1[key]) styles_1[name_2][key] = value;
            });
          } else {
            styles_1[name_2] = child.get('type') !== 'image' ? clone(shapeAttrs) : self.getShapeStyleByName(name_2);
          }
        } else {
          var shapeAttrs_1 = child.attr();
          var keyShapeStateStyles_1 = {};
          Object.keys(currentStatesStyle_1).forEach(function (styleKey) {
            var subStyle = currentStatesStyle_1[styleKey];
            if (styleKey === keyShapeName || !isPlainObject(subStyle)) {
              keyShapeStateStyles_1[styleKey] = subStyle;
            }
          });
          Object.keys(shapeAttrs_1).forEach(function (key) {
            var value = shapeAttrs_1[key];
            // 如果是对象且不是 arrow，则是其他 shape 的样式
            // if (isPlainObject(value) && ARROWS.indexOf(name) === -1) return;
            if (keyShapeStateStyles_1[key] !== value) {
              if (keyShapeName) styles_1[keyShapeName][key] = value;else styles_1[key] = value;
            }
          });
        }
      };
      // 遍历当前所有图形的 attrs，找到不是 stateStyles 的样式更新到 originStyles 中
      for (var i = 0; i < children.length; i++) {
        _loop_1(i);
      }
      delete styles_1.path;
      delete styles_1.matrix;
      delete styles_1.x;
      delete styles_1.y;
      if (styles_1[keyShapeName]) {
        delete styles_1[keyShapeName].x;
        delete styles_1[keyShapeName].y;
        delete styles_1[keyShapeName].matrix;
        delete styles_1[keyShapeName].path;
      }
      self.set('originStyle', styles_1);
    }
  };
  /**
   * restore shape states
   * @param shapeFactory
   * @param shapeType
   */
  ItemBase.prototype.restoreStates = function (shapeFactory, shapeType) {
    var self = this;
    var states = self.get('states');
    each(states, function (state) {
      shapeFactory.setState(shapeType, state, true, self);
    });
  };
  ItemBase.prototype.init = function () {
    var shapeFactory = Shape.getFactory(this.get('type'));
    this.set('shapeFactory', shapeFactory);
  };
  /**
   * 获取属性
   * @internal 仅内部类使用
   * @param  {String} key 属性名
   * @return {object | string | number} 属性值
   */
  ItemBase.prototype.get = function (key) {
    return this._cfg[key];
  };
  /**
   * 设置属性
   * @internal 仅内部类使用
   * @param {String|Object} key 属性名，也可以是对象
   * @param {object | string | number} val 属性值
   */
  ItemBase.prototype.set = function (key, val) {
    if (isPlainObject(key)) {
      this._cfg = __assign(__assign({}, this._cfg), key);
    } else {
      this._cfg[key] = val;
    }
  };
  ItemBase.prototype.getDefaultCfg = function () {
    return {};
  };
  /**
   * 更新/刷新等操作后，清除 cache
   */
  ItemBase.prototype.clearCache = function () {
    this.set(CACHE_BBOX, null);
    this.set(CACHE_CANVAS_BBOX, null);
  };
  /**
   * 渲染前的逻辑，提供给子类复写
   */
  ItemBase.prototype.beforeDraw = function () {};
  /**
   * 渲染后的逻辑，提供给子类复写
   */
  ItemBase.prototype.afterDraw = function () {};
  /**
   * 更新后做一些工作
   */
  ItemBase.prototype.afterUpdate = function () {};
  /**
   * draw shape
   */
  ItemBase.prototype.draw = function () {
    this.beforeDraw();
    this.drawInner();
    this.afterDraw();
  };
  ItemBase.prototype.getShapeStyleByName = function (name) {
    var group = this.get('group');
    var currentShape;
    if (name) {
      currentShape = group['shapeMap'][name] || group.find(function (element) {
        return element.get('name') === name;
      });
    } else {
      currentShape = this.getKeyShape();
    }
    if (currentShape) {
      var styles_2 = {};
      each(currentShape.attr(), function (val, key) {
        // 修改 img 通过 updateItem 实现
        if (key !== 'img' || isString(val)) {
          styles_2[key] = val;
        }
      });
      return styles_2;
    }
    return {};
  };
  ItemBase.prototype.getShapeCfg = function (model, updateType) {
    var styles = this.get('styles');
    if (styles) {
      // merge graph的item样式与数据模型中的样式
      var newModel = model;
      newModel.style = __assign(__assign({}, styles), model.style);
      return newModel;
    }
    return model;
  };
  /**
   * 获取指定状态的样式，去除了全局样式
   * @param state 状态名称
   */
  ItemBase.prototype.getStateStyle = function (state) {
    var styles = this.get('styles');
    var stateStyle = styles && styles[state];
    return stateStyle;
  };
  /**
   * get keyshape style
   */
  ItemBase.prototype.getOriginStyle = function () {
    return this.get('originStyle');
  };
  ItemBase.prototype.getCurrentStatesStyle = function () {
    var self = this;
    var styles = {};
    var states = self.getStates();
    if (!states || !states.length) {
      return this.get('originStyle');
    }
    each(self.getStates(), function (state) {
      styles = Object.assign(styles, self.getStateStyle(state));
    });
    return styles;
  };
  /**
   * 更改元素状态， visible 不属于这个范畴
   * @internal 仅提供内部类 graph 使用
   * @param {String} state 状态名
   * @param {Boolean} value 节点状态值
   */
  ItemBase.prototype.setState = function (state, value) {
    var states = this.get('states');
    var shapeFactory = this.get('shapeFactory');
    var stateName = state;
    var filterStateName = state;
    if (isString(value)) {
      stateName = "".concat(state, ":").concat(value);
      filterStateName = "".concat(state, ":");
    }
    var newStates = states;
    if (isBoolean(value)) {
      var index = states.indexOf(filterStateName);
      if (value) {
        if (index > -1) {
          return;
        }
        states.push(stateName);
      } else if (index > -1) {
        states.splice(index, 1);
      }
    } else if (isString(value)) {
      // 过滤掉 states 中 filterStateName 相关的状态
      var filterStates = states.filter(function (name) {
        return name.includes(filterStateName);
      });
      if (filterStates.length > 0) {
        this.clearStates(filterStates);
      }
      newStates = newStates.filter(function (name) {
        return !name.includes(filterStateName);
      });
      newStates.push(stateName);
      this.set('states', newStates);
    }
    if (shapeFactory) {
      var model = this.get('model');
      var type = model.type;
      // 调用 shape/shape.ts 中的 setState
      shapeFactory.setState(type, state, value, this);
    }
  };
  /**
   * 清除指定的状态，如果参数为空，则不做任务处理
   * @param states 状态名称
   */
  ItemBase.prototype.clearStates = function (states) {
    var self = this;
    var originStates = self.getStates();
    var shapeFactory = self.get('shapeFactory');
    var model = self.get('model');
    var shape = model.type;
    if (!states) {
      states = originStates;
    }
    if (isString(states)) {
      states = [states];
    }
    var newStates = originStates.filter(function (state) {
      return states.indexOf(state) === -1;
    });
    self.set('states', newStates);
    states.forEach(function (state) {
      shapeFactory.setState(shape, state, false, self);
    });
  };
  /**
   * 设置是否开启性能优化模式
   * 目前影响：节点的状态样式更新是否影响相关边的更新
   * @param {Boolean} enableOptimize 是否开启
   */
  ItemBase.prototype.setOptimize = function (enableOptimize) {
    this.optimize = enableOptimize;
  };
  /**
   * 节点的图形容器
   * @return {G.Group} 图形容器
   */
  ItemBase.prototype.getContainer = function () {
    return this.get('group');
  };
  /**
   * 节点的关键形状，用于计算节点大小，连线截距等
   * @return {IShapeBase} 关键形状
   */
  ItemBase.prototype.getKeyShape = function () {
    return this.get('keyShape');
  };
  /**
   * 节点数据模型
   * @return {Object} 数据模型
   */
  ItemBase.prototype.getModel = function () {
    return this.get('model');
  };
  /**
   * 节点类型
   * @return {string} 节点的类型
   */
  ItemBase.prototype.getType = function () {
    return this.get('type');
  };
  /**
   * 获取 Item 的ID
   */
  ItemBase.prototype.getID = function () {
    return this.get('id');
  };
  /**
   * 是否是 Item 对象，悬空边情况下进行判定
   */
  ItemBase.prototype.isItem = function () {
    return true;
  };
  /**
   * 获取当前元素的所有状态
   * @return {Array} 元素的所有状态
   */
  ItemBase.prototype.getStates = function () {
    return this.get('states');
  };
  /**
   * 当前元素是否处于某状态
   * @param {String} state 状态名
   * @return {Boolean} 是否处于某状态
   */
  ItemBase.prototype.hasState = function (state) {
    var states = this.getStates();
    return states.indexOf(state) >= 0;
  };
  /**
   * 刷新一般用于处理几种情况
   * 1. item model 在外部被改变
   * 2. 边的节点位置发生改变，需要重新计算边
   *
   * 因为数据从外部被修改无法判断一些属性是否被修改，直接走位置和 shape 的更新
   */
  ItemBase.prototype.refresh = function (updateType) {
    var model = this.get('model');
    // 更新元素位置
    this.updatePosition(model);
    // 更新元素内容，样式
    this.updateShape(updateType);
    // 做一些更新之后的操作
    this.afterUpdate();
    // 清除缓存
    this.clearCache();
  };
  ItemBase.prototype.getUpdateType = function (cfg) {
    return undefined;
  };
  /**
   * 将更新应用到 model 上，刷新属性
   * @internal 仅提供给 Graph 使用，外部直接调用 graph.update 接口
   * @param  {Object} cfg       配置项，可以是增量信息
   */
  ItemBase.prototype.update = function (cfg, updateType) {
    if (updateType === void 0) {
      updateType = undefined;
    }
    var model = this.get('model');
    // 仅仅移动位置时，既不更新，也不重绘
    if (updateType === 'move') {
      this.updatePosition(cfg);
    } else {
      var oriVisible = model.visible;
      var cfgVisible = cfg.visible;
      if (oriVisible !== cfgVisible && cfgVisible !== undefined) this.changeVisibility(cfgVisible);
      var originPosition = {
        x: model.x,
        y: model.y
      };
      cfg.x = isNaN(+cfg.x) ? model.x : +cfg.x;
      cfg.y = isNaN(+cfg.y) ? model.y : +cfg.y;
      var styles = this.get('styles');
      if (cfg.stateStyles) {
        // 更新 item 时更新 this.get('styles') 中的值
        var stateStyles = cfg.stateStyles;
        mix(styles, stateStyles);
        delete cfg.stateStyles;
      }
      // 直接将更新合到原数据模型上，可以保证用户在外部修改源数据然后刷新时的样式符合期待。
      Object.assign(model, cfg);
      // 如果 x,y 有变化，先重置位置
      if (originPosition.x !== cfg.x || originPosition.y !== cfg.y) {
        this.updatePosition(cfg);
      }
      this.updateShape(updateType);
    }
    this.afterUpdate();
    this.clearCache();
  };
  /**
   * 更新元素内容，样式
   */
  ItemBase.prototype.updateShape = function (updateType) {
    var shapeFactory = this.get('shapeFactory');
    var model = this.get('model');
    var shape = model.type;
    // 判定是否允许更新
    // 1. 注册的节点允许更新（即有继承的/复写的 update 方法，即 update 方法没有被复写为 undefined）
    // 2. 更新后的 shape 等于原先的 shape
    if (shapeFactory.shouldUpdate(shape) && shape === this.get('currentShape')) {
      var updateCfg = this.getShapeCfg(model, updateType);
      shapeFactory.baseUpdate(shape, updateCfg, this, updateType);
      // 更新完以后重新设置原始样式
      if (updateType !== 'move') this.setOriginStyle();
    } else {
      // 如果不满足上面两种状态，重新绘制
      this.draw();
    }
    // 更新后重置节点状态
    this.restoreStates(shapeFactory, shape);
  };
  /**
   * 更新位置，避免整体重绘
   * @param {object} cfg 待更新数据
   */
  ItemBase.prototype.updatePosition = function (cfg) {
    var model = this.get('model');
    var x = isNaN(+cfg.x) ? +model.x : +cfg.x;
    var y = isNaN(+cfg.y) ? +model.y : +cfg.y;
    var group = this.get('group');
    if (isNaN(x) || isNaN(y)) {
      return false;
    }
    model.x = x;
    model.y = y;
    var matrix = group.getMatrix();
    if (matrix && matrix[6] === x && matrix[7] === y) return false;
    group.resetMatrix();
    // G 4.0 element 中移除了矩阵相关方法，详见https://www.yuque.com/antv/blog/kxzk9g#4rMMV
    translate(group, {
      x: x,
      y: y
    });
    this.clearCache(); // 位置更新后需要清除缓存
    return true;
  };
  /**
   * 获取 item 的包围盒，这个包围盒是相对于 item 自己，不会将 matrix 计算在内
   * @return {Object} 包含 x,y,width,height, centerX, centerY
   */
  ItemBase.prototype.getBBox = function () {
    // 计算 bbox 开销有些大，缓存
    var bbox = this.get(CACHE_BBOX);
    if (!bbox) {
      bbox = this.calculateBBox();
      this.set(CACHE_BBOX, bbox);
    }
    return bbox;
  };
  /**
   * 获取 item 相对于画布的包围盒，会将从顶层到当前元素的 matrix 都计算在内
   * @return {Object} 包含 x,y,width,height, centerX, centerY
   */
  ItemBase.prototype.getCanvasBBox = function () {
    // 计算 bbox 开销大，缓存
    var bbox = this.get(CACHE_CANVAS_BBOX);
    if (!bbox) {
      bbox = this.calculateCanvasBBox();
      this.set(CACHE_CANVAS_BBOX, bbox);
    }
    return bbox;
  };
  /**
   * 将元素放到最前面
   */
  ItemBase.prototype.toFront = function () {
    var group = this.get('group');
    group.toFront();
  };
  /**
   * 将元素放到最后面
   */
  ItemBase.prototype.toBack = function () {
    var group = this.get('group');
    group.toBack();
  };
  /**
   * 显示元素
   */
  ItemBase.prototype.show = function () {
    this.changeVisibility(true);
  };
  /**
   * 隐藏元素
   */
  ItemBase.prototype.hide = function () {
    this.changeVisibility(false);
  };
  /**
   * 更改是否显示
   * @param  {Boolean} visible 是否显示
   */
  ItemBase.prototype.changeVisibility = function (visible) {
    var group = this.get('group');
    if (visible) {
      group.show();
    } else {
      group.hide();
    }
    this.set('visible', visible);
  };
  /**
   * 元素是否可见
   * @return {Boolean} 返回该元素是否可见
   */
  ItemBase.prototype.isVisible = function () {
    return this.get('visible');
  };
  /**
   * 是否拾取及出发该元素的交互事件
   * @param {Boolean} enable 标识位
   */
  ItemBase.prototype.enableCapture = function (enable) {
    var group = this.get('group');
    if (group) {
      group.set('capture', enable);
    }
  };
  ItemBase.prototype.destroy = function () {
    if (!this.destroyed) {
      var animate = this.get('animate');
      var group = this.get('group');
      if (animate) {
        group.stopAnimate();
      }
      group['shapeMap'] = {};
      this.clearCache();
      group.remove();
      this._cfg = null;
      this.destroyed = true;
    }
  };
  return ItemBase;
}();
export default ItemBase;