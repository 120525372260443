import { __assign } from "tslib";
import Global from '../../global';
import Shape from '../shape';
import { isNumber, clone, mix, isArray } from '@antv/util';
// 圆形 Combo
Shape.registerCombo('circle', {
  // 自定义节点时的配置
  options: {
    size: [Global.defaultCombo.size[0], Global.defaultCombo.size[0]],
    padding: Math.max.apply(Math, Global.defaultCombo.padding),
    animate: true,
    style: {
      stroke: Global.defaultCombo.style.stroke,
      fill: Global.defaultCombo.style.fill,
      lineWidth: Global.defaultCombo.style.lineWidth
    },
    labelCfg: {
      style: {
        fill: Global.comboLabel.style.fill,
        fontSize: Global.comboLabel.style.fontSize
      },
      refX: 0,
      refY: 0
    },
    stateStyles: __assign({}, Global.comboStateStyles),
    collapsedSubstituteIcon: {
      show: false,
      img: 'https://gw.alipayobjects.com/mdn/rms_f8c6a0/afts/img/A*RsnHRqLfJn4AAAAAAAAAAAAAARQnAQ'
    }
  },
  shapeType: 'circle',
  // 文本位置
  labelPosition: 'top',
  drawShape: function drawShape(cfg, group) {
    var style = this.getShapeStyle(cfg);
    delete style.height;
    delete style.width;
    var keyShape = group.addShape('circle', {
      attrs: style,
      className: 'circle-combo',
      name: 'circle-combo',
      draggable: true
    });
    return keyShape;
  },
  /**
   * 获取 Combo 的样式，供基于该 Combo 自定义时使用
   * @param {Object} cfg Combo 数据模型
   * @return {Object} Combo 的样式
   */
  getShapeStyle: function getShapeStyle(cfg) {
    var defaultStyle = this.options.style;
    var padding = cfg.padding || this.options.padding;
    if (isArray(padding)) padding = Math.max.apply(Math, padding);
    var strokeStyle = {
      stroke: cfg.color
    };
    // 如果设置了color，则覆盖默认的stroke属性
    var style = mix({}, defaultStyle, strokeStyle, cfg.style);
    var fixSize = cfg.collapsed && cfg.fixCollapseSize ? cfg.fixCollapseSize : cfg.fixSize;
    var r;
    if (fixSize) {
      r = isNumber(fixSize) ? fixSize / 2 : fixSize[0] / 2;
    } else {
      var size = this.getSize(cfg);
      if (!isNumber(style.r) || isNaN(style.r)) r = size[0] / 2 || Global.defaultCombo.style.r;else r = Math.max(style.r, size[0] / 2) || size[0] / 2;
    }
    style.r = r + padding;
    var styles = __assign({
      x: 0,
      y: 0
    }, style);
    if (cfg.style) cfg.style.r = r;else {
      cfg.style = {
        r: r
      };
    }
    return styles;
  },
  update: function update(cfg, item) {
    var size = this.getSize(cfg);
    var padding = cfg.padding || this.options.padding;
    if (isArray(padding)) padding = Math.max.apply(Math, padding);
    var cfgStyle = clone(cfg.style);
    var fixSize = cfg.collapsed && cfg.fixCollapseSize ? cfg.fixCollapseSize : cfg.fixSize;
    var r;
    if (fixSize) {
      r = isNumber(fixSize) ? fixSize / 2 : fixSize[0] / 2;
    } else {
      r = Math.max(cfgStyle.r, size[0] / 2) || size[0] / 2;
    }
    cfgStyle.r = r + padding;
    var itemCacheSize = item.get('sizeCache');
    if (itemCacheSize) {
      itemCacheSize.r = cfgStyle.r;
    }
    // 下面这些属性需要覆盖默认样式与目前样式，但若在 cfg 中有指定则应该被 cfg 的相应配置覆盖。
    var strokeStyle = {
      stroke: cfg.color
    };
    // 与 getShapeStyle 不同在于，update 时需要获取到当前的 style 进行融合。即新传入的配置项中没有涉及的属性，保留当前的配置。
    var keyShape = item.get('keyShape');
    var style = mix({}, keyShape.attr(), strokeStyle, cfgStyle);
    if (cfg.style) cfg.style.r = r;else {
      cfg.style = {
        r: r
      };
    }
    this.updateShape(cfg, item, style, true);
  }
}, 'single-combo');