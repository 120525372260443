import { __assign } from "tslib";
import { clone, deepMix, each, isArray, isObject, isString, upperFirst, throttle } from '@antv/util';
import Edge from '../../item/edge';
import Node from '../../item/node';
import Combo from '../../item/combo';
import { traverseTreeUp, traverseTree, getComboBBox } from '../../util/graphic';
var NODE = 'node';
var EDGE = 'edge';
var VEDGE = 'vedge';
var COMBO = 'combo';
var CFG_PREFIX = 'default';
var MAPPER_SUFFIX = 'Mapper';
var STATE_SUFFIX = 'stateStyles';
var ItemController = /** @class */function () {
  function ItemController(graph) {
    var _this = this;
    this.edgeToBeUpdateMap = {};
    /**
     * 更新边限流，同时可以防止相同的边频繁重复更新
     * */
    this.throttleRefresh = throttle(function (_) {
      var graph = _this.graph;
      if (!graph || graph.get('destroyed')) return;
      var edgeToBeUpdateMap = _this.edgeToBeUpdateMap;
      if (!edgeToBeUpdateMap) return;
      var edgeValues = Object.values(edgeToBeUpdateMap);
      if (!edgeValues.length) return;
      edgeValues.forEach(function (obj) {
        var edge = obj.edge;
        if (!edge || edge.destroyed) return;
        var source = edge.getSource();
        var target = edge.getTarget();
        if (!source || source.destroyed || !target || target.destroyed) return;
        edge.refresh(obj.updateType);
      });
      _this.edgeToBeUpdateMap = {};
    }, 16, {
      trailing: true,
      leading: true
    });
    this.graph = graph;
    this.destroyed = false;
  }
  /**
   * 增加 Item 实例
   *
   * @param {ITEM_TYPE} type 实例类型，node 或 edge
   * @param {(NodeConfig & EdgeConfig)} model 数据模型
   * @returns {(Item)}
   * @memberof ItemController
   */
  ItemController.prototype.addItem = function (type, model) {
    var graph = this.graph;
    var vType = type === VEDGE ? EDGE : type;
    var parent = graph.get("".concat(vType, "Group")) || graph.get('group');
    var upperType = upperFirst(vType);
    var item = null;
    // 获取 this.get('styles') 中的值
    var styles = graph.get(vType + upperFirst(STATE_SUFFIX)) || {};
    var defaultModel = graph.get(CFG_PREFIX + upperType);
    if (model[STATE_SUFFIX]) {
      // 设置 this.get('styles') 中的值
      styles = model[STATE_SUFFIX];
    }
    if (defaultModel) {
      // 很多布局会直接修改原数据模型，所以不能用 merge 的形式，逐个写入原 model 中
      each(defaultModel, function (val, cfg) {
        if (isObject(val) && !isArray(val)) {
          model[cfg] = deepMix({}, val, model[cfg]);
        } else if (isArray(val)) {
          model[cfg] = model[cfg] || clone(defaultModel[cfg]);
        } else {
          model[cfg] = model[cfg] || defaultModel[cfg];
        }
      });
    }
    var mapper = graph.get(vType + MAPPER_SUFFIX);
    if (mapper) {
      var mappedModel_1 = mapper(model);
      if (mappedModel_1[STATE_SUFFIX]) {
        // 设置 this.get('styles') 中的值
        styles = mappedModel_1[STATE_SUFFIX];
        delete mappedModel_1[STATE_SUFFIX];
      }
      // 如果配置了 defaultEdge 或 defaultNode，则将默认配置的数据也合并进去
      each(mappedModel_1, function (val, cfg) {
        if (isObject(val) && !isArray(val)) {
          model[cfg] = deepMix({}, model[cfg], val);
        } else {
          model[cfg] = mappedModel_1[cfg] || model[cfg];
        }
      });
    }
    graph.emit('beforeadditem', {
      type: type,
      model: model
    });
    if (type === EDGE || type === VEDGE) {
      var source = void 0;
      var target = void 0;
      source = model.source; // eslint-disable-line prefer-destructuring
      target = model.target; // eslint-disable-line prefer-destructuring
      if (source && isString(source)) {
        source = graph.findById(source);
      }
      if (target && isString(target)) {
        target = graph.findById(target);
      }
      if (!source || !target) {
        console.warn("The source or target node of edge ".concat(model.id, " does not exist!"));
        return;
      }
      if (source.getType && source.getType() === 'combo') {
        model.isComboEdge = true;
        // graph.updateCombo(source as ICombo);
      }

      if (target.getType && target.getType() === 'combo') {
        model.isComboEdge = true;
        // graph.updateCombo(target as ICombo);
      }

      item = new Edge({
        model: model,
        source: source,
        target: target,
        styles: styles,
        linkCenter: graph.get('linkCenter'),
        group: parent.addGroup()
      });
    } else if (type === NODE) {
      item = new Node({
        model: model,
        styles: styles,
        group: parent.addGroup()
      });
    } else if (type === COMBO) {
      var children = model.children;
      var comboBBox = getComboBBox(children, graph);
      var bboxX = void 0,
        bboxY = void 0;
      if (!isNaN(comboBBox.x)) bboxX = comboBBox.x;else if (isNaN(model.x)) bboxX = Math.random() * 100;
      if (!isNaN(comboBBox.y)) bboxY = comboBBox.y;else if (isNaN(model.y)) bboxY = Math.random() * 100;
      if (isNaN(model.x) || isNaN(model.y)) {
        model.x = bboxX;
        model.y = bboxY;
      } else {
        // if there is x y in model, place the combo according to it and move its succeed items. that means, the priority of the combo's position is higher than succeed items'
        var dx = model.x - bboxX;
        var dy = model.y - bboxY;
        // In the same time, adjust the children's positions
        this.updateComboSucceeds(model.id, dx, dy, children);
      }
      var comboGroup = parent.addGroup();
      comboGroup.setZIndex(model.depth);
      item = new Combo({
        model: model,
        styles: styles,
        animate: graph.get('animate'),
        bbox: model.collapsed ? getComboBBox([], graph) : comboBBox,
        group: comboGroup
      });
      // if it is a circle combo, diagonal length of the children's bbox should be the diameter of the combo's bbox
      if (!model.collapsed && item.getKeyShape().get('type') === 'circle') {
        comboBBox.width = Math.hypot(comboBBox.height, comboBBox.width);
        comboBBox.height = comboBBox.width;
        item.set('bbox', comboBBox);
        item.refresh();
      }
      var comboModel_1 = item.getModel();
      (children || []).forEach(function (child) {
        var childItem = graph.findById(child.id);
        item.addChild(childItem);
        child.depth = comboModel_1.depth + 2;
      });
    }
    if (item) {
      item.setOptimize(graph.getNodes().length > graph.get('optimizeThreshold'));
      graph.get("".concat(type, "s")).push(item);
      graph.get('itemMap')[item.get('id')] = item;
      graph.emit('afteradditem', {
        item: item,
        model: model
      });
      // eslint-disable-next-line consistent-return
      return item;
    }
  };
  /**
   * 更新节点或边
   *
   * @param {Item} item ID 或 实例
   * @param {(EdgeConfig | Partial<NodeConfig>)} cfg 数据模型
   * @returns
   * @memberof ItemController
   */
  ItemController.prototype.updateItem = function (item, cfg) {
    var _this = this;
    var _a, _b;
    var graph = this.graph;
    if (isString(item)) {
      item = graph.findById(item);
    }
    if (!item || item.destroyed) {
      return;
    }
    // 更新的 item 的类型
    var type = '';
    if (item.getType) type = item.getType();
    var mapper = graph.get(type + MAPPER_SUFFIX);
    var model = item.getModel();
    var oriX = model.x,
      oriY = model.y;
    var updateType = item.getUpdateType(cfg);
    if (mapper) {
      var result = deepMix({}, model, cfg);
      var mappedModel = mapper(result);
      // 将 update 时候用户传入的参数与mapperModel做deepMix，以便复用之前设置的参数值
      var newModel = deepMix({}, model, mappedModel, cfg);
      if (mappedModel[STATE_SUFFIX]) {
        item.set('styles', newModel[STATE_SUFFIX]);
        delete newModel[STATE_SUFFIX];
      }
      each(newModel, function (val, key) {
        cfg[key] = val;
      });
    } else {
      // merge update传进来的对象参数，model中没有的数据不做处理，对象和字符串值也不做处理，直接替换原来的
      each(cfg, function (val, key) {
        if (model[key]) {
          if (isObject(val) && !isArray(val)) {
            cfg[key] = __assign(__assign({}, model[key]), cfg[key]);
          }
        }
      });
    }
    // emit beforeupdateitem 事件
    graph.emit('beforeupdateitem', {
      item: item,
      cfg: cfg
    });
    if (type === EDGE) {
      // 若是边要更新source || target, 为了不影响示例内部model，并且重新计算startPoint和endPoint，手动设置
      if (cfg.source) {
        var source = cfg.source;
        if (isString(source)) {
          source = graph.findById(source);
        }
        item.setSource(source);
      }
      if (cfg.target) {
        var target = cfg.target;
        if (isString(target)) {
          target = graph.findById(target);
        }
        item.setTarget(target);
      }
      item.update(cfg);
    } else if (type === NODE) {
      item.update(cfg, updateType);
      var edges = item.getEdges();
      if (updateType === 'move') {
        each(edges, function (edge) {
          _this.edgeToBeUpdateMap[edge.getID()] = {
            edge: edge,
            updateType: updateType
          };
          _this.throttleRefresh();
        });
      } else if (updateType === null || updateType === void 0 ? void 0 : updateType.includes('bbox')) {
        each(edges, function (edge) {
          edge.refresh(updateType);
        });
      }
    } else if (type === COMBO) {
      item.update(cfg, updateType);
      if (!isNaN(cfg.x) || !isNaN(cfg.y)) {
        // if there is x y in model, place the combo according to it and move its succeed items. that means, the priority of the combo's position is higher than succeed items'
        var dx = cfg.x - oriX || 0;
        var dy = cfg.y - oriY || 0;
        // In the same time, adjust the children's positions
        this.updateComboSucceeds(model.id, dx, dy);
      }
      var edges_1 = item.getEdges();
      var refreshEdge = (updateType === null || updateType === void 0 ? void 0 : updateType.includes('bbox')) || updateType === 'move';
      if (refreshEdge && type === COMBO) {
        var shapeFactory = item.get('shapeFactory');
        var shapeType = model.type || 'circle';
        var comboAnimate = model.animate === undefined || cfg.animate === undefined ? (_b = (_a = shapeFactory[shapeType]) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.animate : model.animate || cfg.animate;
        if (comboAnimate) {
          setTimeout(function () {
            if (!item || item.destroyed) return;
            var keyShape = item.getKeyShape();
            if (!keyShape || keyShape.destroyed) return;
            each(edges_1, function (edge) {
              if (edge && !edge.destroyed) edge.refresh();
            });
          }, 201);
        } else {
          each(edges_1, function (edge) {
            edge.refresh();
          });
        }
      }
    }
    item.setOptimize(graph.getNodes().length > graph.get('optimizeThreshold'));
    graph.emit('afterupdateitem', {
      item: item,
      cfg: cfg
    });
  };
  /**
   * 根据 combo 的子元素更新 combo 的位置及大小
   *
   * @param {ICombo} combo ID 或 实例
   * @returns
   * @memberof ItemController
   */
  ItemController.prototype.updateCombo = function (combo, children, followCombo) {
    var _this = this;
    var _a, _b;
    var graph = this.graph;
    if (isString(combo)) {
      combo = graph.findById(combo);
    }
    if (!combo || combo.destroyed) {
      return;
    }
    var model = combo.getModel();
    var comboBBox = getComboBBox(children, graph, combo);
    var comboX = comboBBox.x,
      comboY = comboBBox.y;
    combo.set('bbox', comboBBox);
    var x = comboX,
      y = comboY;
    if (followCombo) {
      // position of combo model first
      x = isNaN(model.x) ? comboX : model.x;
      y = isNaN(model.y) ? comboY : model.y;
    } else {
      // position of succeed items first
      x = isNaN(comboX) ? model.x : comboX;
      y = isNaN(comboY) ? model.y : comboY;
    }
    combo.update({
      x: x,
      y: y
    });
    var shapeFactory = combo.get('shapeFactory');
    var shapeType = model.type || 'circle';
    var comboAnimate = model.animate === undefined ? (_b = (_a = shapeFactory[shapeType]) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.animate : model.animate;
    if (comboAnimate) {
      setTimeout(function () {
        if (!combo || combo.destroyed) return;
        var keyShape = combo.getKeyShape();
        if (!keyShape || keyShape.destroyed) return;
        combo.getShapeCfg(model); // 更新 combo 缓存的 size
        _this.updateComboEdges(combo);
      }, 201);
    } else {
      this.updateComboEdges(combo);
    }
  };
  ItemController.prototype.updateComboEdges = function (combo) {
    var _a, _b;
    var combEdges = combo.getEdges() || [];
    for (var i = 0; i < combEdges.length; i++) {
      var edge = combEdges[i];
      if (!(edge === null || edge === void 0 ? void 0 : edge.destroyed) && !((_a = edge === null || edge === void 0 ? void 0 : edge.getSource()) === null || _a === void 0 ? void 0 : _a.destroyed) && !((_b = edge === null || edge === void 0 ? void 0 : edge.getTarget()) === null || _b === void 0 ? void 0 : _b.destroyed)) {
        edge.refresh();
      }
    }
  };
  /**
   * 收起 combo，隐藏相关元素
   */
  ItemController.prototype.collapseCombo = function (combo, stack) {
    if (stack === void 0) {
      stack = true;
    }
    var graph = this.graph;
    if (isString(combo)) {
      combo = graph.findById(combo);
    }
    var children = combo.getChildren();
    children.nodes.forEach(function (node) {
      graph.hideItem(node, stack);
    });
    children.combos.forEach(function (c) {
      graph.hideItem(c, stack);
    });
  };
  /**
   * 根据位置差量 dx dy，更新 comboId 后继元素的位置
   * */
  ItemController.prototype.updateComboSucceeds = function (comboId, dx, dy, children) {
    var _this = this;
    if (children === void 0) {
      children = [];
    }
    var graph = this.graph;
    if (!dx && !dy) return;
    var kids = children;
    if (!(kids === null || kids === void 0 ? void 0 : kids.length)) {
      var comboTrees = graph.get('comboTrees');
      comboTrees === null || comboTrees === void 0 ? void 0 : comboTrees.forEach(function (child) {
        traverseTree(child, function (subTree) {
          if (subTree.id === comboId) {
            kids = subTree.children;
            return false;
          }
          return true;
        });
      });
    }
    kids === null || kids === void 0 ? void 0 : kids.forEach(function (child) {
      var childItem = graph.findById(child.id);
      if (childItem) {
        var childModel = childItem.getModel();
        _this.updateItem(child.id, {
          x: (childModel.x || 0) + dx,
          y: (childModel.y || 0) + dy
        });
      }
    });
  };
  /**
   * 展开 combo，相关元素出现
   * 若子 combo 原先是收起状态，则保持它的收起状态
   */
  ItemController.prototype.expandCombo = function (combo, stack) {
    if (stack === void 0) {
      stack = true;
    }
    var graph = this.graph;
    if (isString(combo)) {
      combo = graph.findById(combo);
    }
    var children = combo.getChildren();
    var edgeSet = new Set();
    children.nodes.forEach(function (node) {
      graph.showItem(node, stack);
      node.getEdges().forEach(function (edge) {
        return edgeSet.add(edge);
      });
    });
    children.combos.forEach(function (c) {
      if (c.getModel().collapsed) {
        c.show();
      } else {
        graph.showItem(c, stack);
      }
      c.getEdges().forEach(function (edge) {
        return edgeSet.add(edge);
      });
    });
    edgeSet.forEach(function (edge) {
      return edge.refresh();
    });
  };
  /**
   * 删除指定的节点或边
   *
   * @param {Item} item item ID 或实例
   * @returns {void}
   * @memberof ItemController
   */
  ItemController.prototype.removeItem = function (item) {
    var _this = this;
    var graph = this.graph;
    if (isString(item)) {
      item = graph.findById(item);
    }
    if (!item || item.destroyed) {
      return;
    }
    var itemModel = clone(item.getModel());
    var type = '';
    if (item.getType) type = item.getType();
    graph.emit('beforeremoveitem', {
      item: itemModel,
      type: type
    });
    var items = graph.get("".concat(type, "s"));
    var index = items.indexOf(item);
    if (index > -1) items.splice(index, 1);
    if (type === EDGE) {
      var vitems = graph.get("v".concat(type, "s"));
      var vindex = vitems.indexOf(item);
      if (vindex > -1) vitems.splice(vindex, 1);
    }
    var itemId = item.get('id');
    var itemMap = graph.get('itemMap');
    delete itemMap[itemId];
    var comboTrees = graph.get('comboTrees');
    var id = item.get('id');
    if (type === NODE) {
      var comboId = item.getModel().comboId;
      if (comboTrees && comboId) {
        var brothers_1 = comboTrees;
        var found_1 = false; // the flag to terminate the forEach circulation
        // remove the node from the children array of its parent fromt he tree
        comboTrees.forEach(function (ctree) {
          if (found_1) return;
          traverseTree(ctree, function (combo) {
            if (combo.id === id && brothers_1) {
              var bidx = brothers_1.indexOf(combo);
              brothers_1.splice(bidx, 1);
              found_1 = true;
              return false; // terminate the traverse
            }

            brothers_1 = combo.children;
            return true;
          });
        });
      }
      // 若移除的是节点，需要将与之相连的边一同删除
      var edges = item.getEdges();
      for (var i = edges.length - 1; i >= 0; i--) {
        graph.removeItem(edges[i], false);
      }
      if (comboId) graph.updateCombo(comboId);
    } else if (type === COMBO) {
      var parentId = item.getModel().parentId;
      var comboInTree_1;
      // find the subtree rooted at the item to be removed
      var found_2 = false; // the flag to terminate the forEach circulation
      (comboTrees || []).forEach(function (ctree) {
        if (found_2) return;
        traverseTree(ctree, function (combo) {
          if (combo.id === id) {
            comboInTree_1 = combo;
            found_2 = true;
            return false; // terminate the traverse
          }

          return true;
        });
      });
      comboInTree_1.removed = true;
      if (comboInTree_1 && comboInTree_1.children) {
        comboInTree_1.children.forEach(function (child) {
          _this.removeItem(child.id);
        });
      }
      // 若移除的是 combo，需要将与之相连的边一同删除
      var edges = item.getEdges();
      for (var i = edges.length; i >= 0; i--) {
        graph.removeItem(edges[i], false);
      }
      if (parentId) graph.updateCombo(parentId);
    }
    item.destroy();
    graph.emit('afterremoveitem', {
      item: itemModel,
      type: type
    });
  };
  /**
   * 更新 item 状态
   *
   * @param {Item} item Item 实例
   * @param {string} state 状态名称
   * @param {boolean} value 是否启用状态或状态值
   * @returns {void}
   * @memberof ItemController
   */
  ItemController.prototype.setItemState = function (item, state, value) {
    var graph = this.graph;
    var stateName = state;
    if (isString(value)) {
      stateName = "".concat(state, ":").concat(value);
    }
    // 已经存在要设置的 state，或不存在 state 的样式为 undefined
    if (item.hasState(stateName) === value && value ||
    // 当该状态已经存在且现在需要设置为 true 时，不需要继续。当该状态不存在，且设置为 false 时，需要继续
    isString(value) && item.hasState(stateName)) {
      // 当该状态 value 是字符串，且已经存在该状态，不需要继续
      return;
    }
    graph.emit('beforeitemstatechange', {
      item: item,
      state: stateName,
      enabled: value
    });
    item.setState(state, value);
    graph.autoPaint();
    graph.emit('afteritemstatechange', {
      item: item,
      state: stateName,
      enabled: value
    });
  };
  /**
   * 将指定状态的优先级提升为最高优先级
   * @param {Item} item 元素id或元素实例
   * @param state 状态名称
   */
  ItemController.prototype.priorityState = function (item, state) {
    var graph = this.graph;
    var currentItem = item;
    if (isString(item)) {
      currentItem = graph.findById(item);
    }
    // 先取消已有的 state
    this.setItemState(currentItem, state, false);
    // 再设置state，则此时该优先级为最高
    this.setItemState(currentItem, state, true);
  };
  /**
   * 清除所有指定的状态
   *
   * @param {Item} item Item 实例
   * @param {string[]} states 状态名称集合
   * @memberof ItemController
   */
  ItemController.prototype.clearItemStates = function (item, states) {
    var graph = this.graph;
    if (isString(item)) {
      item = graph.findById(item);
    }
    graph.emit('beforeitemstatesclear', {
      item: item,
      states: states
    });
    item.clearStates(states);
    graph.emit('afteritemstatesclear', {
      item: item,
      states: states
    });
  };
  /**
   * 刷新指定的 Item
   *
   * @param {Item} item Item ID 或 实例
   * @memberof ItemController
   */
  ItemController.prototype.refreshItem = function (item) {
    var graph = this.graph;
    if (isString(item)) {
      item = graph.findById(item);
    }
    graph.emit('beforeitemrefresh', {
      item: item
    });
    // 调用 Item 的 refresh 方法，实现刷新功能
    item.refresh();
    graph.emit('afteritemrefresh', {
      item: item
    });
  };
  /**
   * 根据 graph 上用 combos 数据生成的 comboTree 来增加所有 combos
   *
   * @param {ComboTree[]} comboTrees graph 上用 combos 数据生成的 comboTree
   * @param {ComboConfig[]} comboModels combos 数据
   * @memberof ItemController
   */
  ItemController.prototype.addCombos = function (comboTrees, comboModels) {
    var _this = this;
    var graph = this.graph;
    (comboTrees || []).forEach(function (ctree) {
      traverseTreeUp(ctree, function (child) {
        var comboModel;
        comboModels.forEach(function (model) {
          if (model.id === child.id) {
            model.children = child.children;
            model.depth = child.depth;
            comboModel = model;
          }
        });
        if (comboModel) {
          _this.addItem('combo', comboModel);
        }
        return true;
      });
    });
    var comboGroup = graph.get('comboGroup');
    if (comboGroup) comboGroup.sort();
  };
  /**
   * 改变Item的显示状态
   *
   * @param {Item} item Item ID 或 实例
   * @param {boolean} visible 是否显示
   * @memberof ItemController
   */
  ItemController.prototype.changeItemVisibility = function (item, visible) {
    var _this = this;
    var graph = this.graph;
    if (isString(item)) {
      item = graph.findById(item);
    }
    if (!item) {
      console.warn('The item to be shown or hidden does not exist!');
      return;
    }
    graph.emit('beforeitemvisibilitychange', {
      item: item,
      visible: visible
    });
    item.changeVisibility(visible);
    if (item.getType && item.getType() === NODE) {
      var edges = item.getEdges();
      each(edges, function (edge) {
        // 若隐藏节点，则将与之关联的边也隐藏
        // 若显示节点，则将与之关联的边也显示，但是需要判断边两端的节点都是可见的
        if (visible && !(edge.get('source').isVisible() && edge.get('target').isVisible())) {
          return;
        }
        _this.changeItemVisibility(edge, visible);
      });
    } else if (item.getType && item.getType() === COMBO) {
      var comboTrees = graph.get('comboTrees');
      var id_1 = item.get('id');
      var children_1 = [];
      var found_3 = false; // flag the terminate the forEach
      (comboTrees || []).forEach(function (ctree) {
        if (found_3) return;
        if (!ctree.children || ctree.children.length === 0) return;
        traverseTree(ctree, function (combo) {
          if (combo.id === id_1) {
            children_1 = combo.children;
            found_3 = true;
            return false; // terminate the traverse
          }

          return true;
        });
      });
      if (children_1 && (!visible || visible && !item.getModel().collapsed)) {
        children_1.forEach(function (child) {
          var childItem = graph.findById(child.id);
          _this.changeItemVisibility(childItem, visible);
        });
      }
      var edges = item.getEdges();
      each(edges, function (edge) {
        // 若隐藏 combo，则将与 combo 本身关联的边也隐藏
        // 若显示 combo，则将与 combo 本身关联的边也显示，但是需要判断边两端的节点都是可见的
        if (visible && !(edge.get('source').isVisible() && edge.get('target').isVisible())) {
          return;
        }
        _this.changeItemVisibility(edge, visible);
      });
    }
    graph.emit('afteritemvisibilitychange', {
      item: item,
      visible: visible
    });
    return item;
  };
  ItemController.prototype.destroy = function () {
    this.graph = null;
    this.destroyed = true;
  };
  return ItemController;
}();
export default ItemController;