import { __assign } from "tslib";
import { vec2 } from '@antv/matrix-util';
import { clone, isArray, isNumber, isObject, isString } from '@antv/util';
import Global from '../global';
import letterAspectRatio from './letterAspectRatio';
import { applyMatrix } from './math';
var PI = Math.PI,
  sin = Math.sin,
  cos = Math.cos;
// 一共支持8个方向的自环，每个环占的角度是45度，在计算时再二分，为22.5度
var SELF_LINK_SIN = sin(PI / 8);
var SELF_LINK_COS = cos(PI / 8);
export var getBBox = function getBBox(element, group) {
  var bbox = element.getBBox();
  var leftTop = {
    x: bbox.minX,
    y: bbox.minY
  };
  var rightBottom = {
    x: bbox.maxX,
    y: bbox.maxY
  };
  // 根据父元素变换矩阵
  if (group) {
    var matrix = group.getMatrix();
    if (!matrix) {
      matrix = [1, 0, 0, 0, 1, 0, 0, 0, 1];
    }
    leftTop = applyMatrix(leftTop, matrix);
    rightBottom = applyMatrix(rightBottom, matrix);
  }
  var lx = leftTop.x,
    ly = leftTop.y;
  var rx = rightBottom.x,
    ry = rightBottom.y;
  return {
    x: lx,
    y: ly,
    minX: lx,
    minY: ly,
    maxX: rx,
    maxY: ry,
    width: rx - lx,
    height: ry - ly
  };
};
/**
 * get loop edge config
 * @param cfg edge config
 */
export var getLoopCfgs = function getLoopCfgs(cfg) {
  var item = cfg.sourceNode || cfg.targetNode;
  var container = item.get('group');
  var containerMatrix = container.getMatrix();
  if (!containerMatrix) containerMatrix = [1, 0, 0, 0, 1, 0, 0, 0, 1];
  var keyShape = item.getKeyShape();
  var bbox = keyShape.getBBox();
  var loopCfg = cfg.loopCfg || {};
  // 距离keyShape边的最高距离
  var dist = loopCfg.dist || Math.max(bbox.width, bbox.height) * 2;
  // 自环边与keyShape的相对位置关系
  var position = loopCfg.position || Global.defaultLoopPosition;
  // 中心取节点 keyShape bbox 的中心位置 + 节点位置坐标
  var center = [(bbox.minX + bbox.maxX) / 2 + containerMatrix[6], (bbox.minY + bbox.maxY) / 2 + containerMatrix[7]];
  var startPoint = [cfg.startPoint.x, cfg.startPoint.y];
  var endPoint = [cfg.endPoint.x, cfg.endPoint.y];
  var halfOfHeight = bbox.height / 2;
  var halfOfWidth = bbox.width / 2;
  var rstart = halfOfHeight;
  var rend = halfOfHeight;
  var sinDeltaStart = rstart * SELF_LINK_SIN;
  var cosDeltaStart = rstart * SELF_LINK_COS;
  var sinDeltaEnd = rend * SELF_LINK_SIN;
  var cosDeltaEnd = rend * SELF_LINK_COS;
  var shapeType = keyShape.get('type');
  // 美观考虑，pointPadding 默认取宽高中最小的1/4
  var defaultPointPadding = Math.min(halfOfHeight / 2, halfOfWidth / 2);
  var maxPointPadding = Math.min(halfOfHeight, halfOfWidth);
  // 对于非圆形节点设置的连接点与节点中心坐标（`top-right`，`bottom-right`,`top-left`,`bottom-left`较特殊，为四个角坐标）在 x 轴或 y 轴方向的偏移量，默认为  `节点宽高中最小值的1/4`
  var pointPadding = (loopCfg === null || loopCfg === void 0 ? void 0 : loopCfg.pointPadding) ? Math.min(maxPointPadding, loopCfg === null || loopCfg === void 0 ? void 0 : loopCfg.pointPadding) : defaultPointPadding;
  // 如果定义了锚点的，直接用锚点坐标，否则，根据自环的 cfg 计算
  if (startPoint[0] === endPoint[0] && startPoint[1] === endPoint[1]) {
    switch (position) {
      case 'top':
        if (shapeType === 'circle') {
          startPoint = [center[0] - sinDeltaStart, center[1] - cosDeltaStart];
          endPoint = [center[0] + sinDeltaEnd, center[1] - cosDeltaEnd];
        } else {
          startPoint = [center[0] - pointPadding, center[1] - halfOfHeight];
          endPoint = [center[0] + pointPadding, center[1] - halfOfHeight];
        }
        break;
      case 'top-right':
        rstart = halfOfHeight;
        rend = halfOfWidth;
        if (shapeType === 'circle') {
          sinDeltaStart = rstart * SELF_LINK_SIN;
          cosDeltaStart = rstart * SELF_LINK_COS;
          sinDeltaEnd = rend * SELF_LINK_SIN;
          cosDeltaEnd = rend * SELF_LINK_COS;
          startPoint = [center[0] + sinDeltaStart, center[1] - cosDeltaStart];
          endPoint = [center[0] + cosDeltaEnd, center[1] - sinDeltaEnd];
        } else {
          startPoint = [center[0] + halfOfWidth - pointPadding, center[1] - halfOfHeight];
          endPoint = [center[0] + halfOfWidth, center[1] - halfOfHeight + pointPadding];
        }
        break;
      case 'right':
        rstart = halfOfWidth;
        rend = halfOfWidth;
        if (shapeType === 'circle') {
          sinDeltaStart = rstart * SELF_LINK_SIN;
          cosDeltaStart = rstart * SELF_LINK_COS;
          sinDeltaEnd = rend * SELF_LINK_SIN;
          cosDeltaEnd = rend * SELF_LINK_COS;
          startPoint = [center[0] + cosDeltaStart, center[1] - sinDeltaStart];
          endPoint = [center[0] + cosDeltaEnd, center[1] + sinDeltaEnd];
        } else {
          startPoint = [center[0] + halfOfWidth, center[1] - pointPadding];
          endPoint = [center[0] + halfOfWidth, center[1] + pointPadding];
        }
        break;
      case 'bottom-right':
        rstart = halfOfWidth;
        rend = halfOfHeight;
        if (shapeType === 'circle') {
          sinDeltaStart = rstart * SELF_LINK_SIN;
          cosDeltaStart = rstart * SELF_LINK_COS;
          sinDeltaEnd = rend * SELF_LINK_SIN;
          cosDeltaEnd = rend * SELF_LINK_COS;
          startPoint = [center[0] + cosDeltaStart, center[1] + sinDeltaStart];
          endPoint = [center[0] + sinDeltaEnd, center[1] + cosDeltaEnd];
        } else {
          startPoint = [center[0] + halfOfWidth, center[1] + halfOfHeight - pointPadding];
          endPoint = [center[0] + halfOfWidth - pointPadding, center[1] + halfOfHeight];
        }
        break;
      case 'bottom':
        rstart = halfOfHeight;
        rend = halfOfHeight;
        if (shapeType === 'circle') {
          sinDeltaStart = rstart * SELF_LINK_SIN;
          cosDeltaStart = rstart * SELF_LINK_COS;
          sinDeltaEnd = rend * SELF_LINK_SIN;
          cosDeltaEnd = rend * SELF_LINK_COS;
          startPoint = [center[0] + sinDeltaStart, center[1] + cosDeltaStart];
          endPoint = [center[0] - sinDeltaEnd, center[1] + cosDeltaEnd];
        } else {
          startPoint = [center[0] - pointPadding, center[1] + halfOfHeight];
          endPoint = [center[0] + pointPadding, center[1] + halfOfHeight];
        }
        break;
      case 'bottom-left':
        rstart = halfOfHeight;
        rend = halfOfWidth;
        if (shapeType === 'circle') {
          sinDeltaStart = rstart * SELF_LINK_SIN;
          cosDeltaStart = rstart * SELF_LINK_COS;
          sinDeltaEnd = rend * SELF_LINK_SIN;
          cosDeltaEnd = rend * SELF_LINK_COS;
          startPoint = [center[0] - sinDeltaStart, center[1] + cosDeltaStart];
          endPoint = [center[0] - cosDeltaEnd, center[1] + sinDeltaEnd];
        } else {
          startPoint = [center[0] - halfOfWidth, center[1] + halfOfHeight - pointPadding];
          endPoint = [center[0] - halfOfWidth + pointPadding, center[1] + halfOfHeight];
        }
        break;
      case 'left':
        rstart = halfOfWidth;
        rend = halfOfWidth;
        if (shapeType === 'circle') {
          sinDeltaStart = rstart * SELF_LINK_SIN;
          cosDeltaStart = rstart * SELF_LINK_COS;
          sinDeltaEnd = rend * SELF_LINK_SIN;
          cosDeltaEnd = rend * SELF_LINK_COS;
          startPoint = [center[0] - cosDeltaStart, center[1] + sinDeltaStart];
          endPoint = [center[0] - cosDeltaEnd, center[1] - sinDeltaEnd];
        } else {
          startPoint = [center[0] - halfOfWidth, center[1] - pointPadding];
          endPoint = [center[0] - halfOfWidth, center[1] + pointPadding];
        }
        break;
      case 'top-left':
        rstart = halfOfWidth;
        rend = halfOfHeight;
        if (shapeType === 'circle') {
          sinDeltaStart = rstart * SELF_LINK_SIN;
          cosDeltaStart = rstart * SELF_LINK_COS;
          sinDeltaEnd = rend * SELF_LINK_SIN;
          cosDeltaEnd = rend * SELF_LINK_COS;
          startPoint = [center[0] - cosDeltaStart, center[1] - sinDeltaStart];
          endPoint = [center[0] - sinDeltaEnd, center[1] - cosDeltaEnd];
        } else {
          startPoint = [center[0] - halfOfWidth + pointPadding, center[1] - halfOfHeight];
          endPoint = [center[0] - halfOfWidth, center[1] - halfOfHeight + pointPadding];
        }
        break;
      default:
        rstart = halfOfWidth;
        rend = halfOfWidth;
        sinDeltaStart = rstart * SELF_LINK_SIN;
        cosDeltaStart = rstart * SELF_LINK_COS;
        sinDeltaEnd = rend * SELF_LINK_SIN;
        cosDeltaEnd = rend * SELF_LINK_COS;
        startPoint = [center[0] - sinDeltaStart, center[1] - cosDeltaStart];
        endPoint = [center[0] + sinDeltaEnd, center[1] - cosDeltaEnd];
    }
    // 如果逆时针画，交换起点和终点
    if (loopCfg.clockwise === false) {
      var swap = [startPoint[0], startPoint[1]];
      startPoint = [endPoint[0], endPoint[1]];
      endPoint = [swap[0], swap[1]];
    }
  }
  var startVec = [startPoint[0] - center[0], startPoint[1] - center[1]];
  var scaleRateStart = (rstart + dist) / rstart;
  var scaleRateEnd = (rend + dist) / rend;
  if (loopCfg.clockwise === false) {
    scaleRateStart = (rend + dist) / rend;
    scaleRateEnd = (rstart + dist) / rstart;
  }
  var startExtendVec = vec2.scale([0, 0], startVec, scaleRateStart);
  var controlPoint1 = [center[0] + startExtendVec[0], center[1] + startExtendVec[1]];
  var endVec = [endPoint[0] - center[0], endPoint[1] - center[1]];
  var endExtendVec = vec2.scale([0, 0], endVec, scaleRateEnd);
  var controlPoint2 = [center[0] + endExtendVec[0], center[1] + endExtendVec[1]];
  cfg.startPoint = {
    x: startPoint[0],
    y: startPoint[1]
  };
  cfg.endPoint = {
    x: endPoint[0],
    y: endPoint[1]
  };
  cfg.controlPoints = [{
    x: controlPoint1[0],
    y: controlPoint1[1]
  }, {
    x: controlPoint2[0],
    y: controlPoint2[1]
  }];
  return cfg;
};
/**
 * 根据 label 所在线条的位置百分比，计算 label 坐标
 * @param {object}  pathShape  G 的 path 实例，一般是 Edge 实例的 keyShape
 * @param {number}  percent    范围 0 - 1 的线条百分比
 * @param {number}  refX     x 轴正方向为基准的 label 偏移
 * @param {number}  refY     y 轴正方向为基准的 label 偏移
 * @param {boolean} rotate     是否根据线条斜率旋转文本
 * @return {object} 文本的 x, y, 文本的旋转角度
 */
export var getLabelPosition = function getLabelPosition(pathShape, percent, refX, refY, rotate) {
  var TAN_OFFSET = 0.0001;
  var vector = [];
  var point = pathShape === null || pathShape === void 0 ? void 0 : pathShape.getPoint(percent);
  if (!point) {
    return {
      x: 0,
      y: 0,
      angle: 0
    };
  }
  // 头尾最可能，放在最前面，使用 g path 上封装的方法
  if (percent < TAN_OFFSET) {
    vector = pathShape.getStartTangent().reverse();
  } else if (percent > 1 - TAN_OFFSET) {
    vector = pathShape.getEndTangent();
  } else {
    // 否则取指定位置的点,与少量偏移的点，做微分向量
    var offsetPoint = pathShape === null || pathShape === void 0 ? void 0 : pathShape.getPoint(percent + TAN_OFFSET);
    vector.push([point.x, point.y]);
    vector.push([offsetPoint.x, offsetPoint.y]);
  }
  var rad = Math.atan2(vector[1][1] - vector[0][1], vector[1][0] - vector[0][0]);
  if (rad < 0) {
    rad += PI * 2;
  }
  if (refX) {
    point.x += cos(rad) * refX;
    point.y += sin(rad) * refX;
  }
  if (refY) {
    // 默认方向是 x 轴正方向，法线是 求出角度 - 90°
    var normal = rad - PI / 2;
    // 若法线角度在 y 轴负方向，切到正方向，保证 refY 相对于 y 轴正方向
    if (rad > 1 / 2 * PI && rad < 3 * 1 / 2 * PI) {
      normal -= PI;
    }
    point.x += cos(normal) * refY;
    point.y += sin(normal) * refY;
  }
  var result = {
    x: point.x,
    y: point.y,
    angle: rad
  };
  if (rotate) {
    if (rad > 0.5 * PI && rad < 1.5 * PI) {
      rad -= PI;
    }
    return __assign({
      rotate: rad
    }, result);
  }
  return result;
};
/**
 * depth first traverse, from root to leaves, children in inverse order
 *  if the fn returns false, terminate the traverse
 */
var traverse = function traverse(data, parent, index, fn) {
  if (fn(data, parent, index) === false) {
    return false;
  }
  if (data && data.children) {
    for (var i = data.children.length - 1; i >= 0; i--) {
      if (!traverse(data.children[i], data, i, fn)) return false;
    }
  }
  return true;
};
/**
 * depth first traverse, from leaves to root, children in inverse order
 *  if the fn returns false, terminate the traverse
 */
var traverseUp = function traverseUp(data, parent, index, fn) {
  if (data && data.children) {
    for (var i = data.children.length - 1; i >= 0; i--) {
      if (!traverseUp(data.children[i], data, i, fn)) return;
    }
  }
  if (fn(data, parent, index) === false) {
    return false;
  }
  return true;
};
/**
 * depth first traverse, from root to leaves, children in inverse order
 *  if the fn returns false, terminate the traverse
 */
export var traverseTree = function traverseTree(data, fn) {
  if (typeof fn !== 'function') {
    return;
  }
  traverse(data, null, -1, fn);
};
/**
 * depth first traverse, from leaves to root, children in inverse order
 * if the fn returns false, terminate the traverse
 */
export var traverseTreeUp = function traverseTreeUp(data, fn) {
  if (typeof fn !== 'function') {
    return;
  }
  traverseUp(data, null, -1, fn);
};
/**
 *
 * @param letter the letter
 * @param fontSize
 * @return the letter's width
 */
export var getLetterWidth = function getLetterWidth(letter, fontSize) {
  return fontSize * (letterAspectRatio[letter] || 1);
};
/**
 *
 * @param text the text
 * @param fontSize
 * @return the text's size
 */
export var getTextSize = function getTextSize(text, fontSize) {
  var width = 0;
  var pattern = new RegExp("[\u4E00-\u9FA5]+");
  text.split('').forEach(function (letter) {
    if (pattern.test(letter)) {
      // 中文字符
      width += fontSize;
    } else {
      width += getLetterWidth(letter, fontSize);
    }
  });
  return [width, fontSize];
};
export var truncateLabelByLength = function truncateLabelByLength(text, length) {
  if (typeof length !== 'number' || length <= 0 || length >= text.length) {
    return text;
  }
  return text.substring(0, length) + '...';
};
/**
 * construct the trees from combos data
 * @param array the combos array
 * @param nodes the nodes array
 * @return the tree
 */
export var plainCombosToTrees = function plainCombosToTrees(array, nodes) {
  var result = [];
  var addedMap = {};
  var modelMap = {};
  array.forEach(function (d) {
    modelMap[d.id] = d;
  });
  array.forEach(function (d, i) {
    var cd = clone(d);
    cd.itemType = 'combo';
    cd.children = undefined;
    if (cd.parentId === cd.id) {
      console.warn("The parentId for combo ".concat(cd.id, " can not be the same as the combo's id"));
      delete cd.parentId;
    } else if (cd.parentId && !modelMap[cd.parentId]) {
      console.warn("The parent combo for combo ".concat(cd.id, " does not exist!"));
      delete cd.parentId;
    }
    var mappedObj = addedMap[cd.id];
    if (mappedObj) {
      cd.children = mappedObj.children;
      addedMap[cd.id] = cd;
      mappedObj = cd;
      if (!mappedObj.parentId) {
        result.push(mappedObj);
        return;
      }
      var mappedParent = addedMap[mappedObj.parentId];
      if (mappedParent) {
        if (mappedParent.children) mappedParent.children.push(cd);else mappedParent.children = [cd];
      } else {
        var parent_1 = {
          id: mappedObj.parentId,
          children: [mappedObj]
        };
        addedMap[mappedObj.parentId] = parent_1;
        addedMap[cd.id] = cd;
      }
      return;
    }
    if (isString(d.parentId)) {
      var parent_2 = addedMap[d.parentId];
      if (parent_2) {
        if (parent_2.children) parent_2.children.push(cd);else parent_2.children = [cd];
        addedMap[cd.id] = cd;
      } else {
        var pa = {
          id: d.parentId,
          children: [cd]
        };
        addedMap[pa.id] = pa;
        addedMap[cd.id] = cd;
      }
    } else {
      result.push(cd);
      addedMap[cd.id] = cd;
    }
  });
  // proccess the nodes
  var nodeMap = {};
  (nodes || []).forEach(function (node) {
    nodeMap[node.id] = node;
    var combo = addedMap[node.comboId];
    if (combo) {
      var cnode = {
        id: node.id,
        comboId: node.comboId
      };
      if (combo.children) combo.children.push(cnode);else combo.children = [cnode];
      cnode.itemType = 'node';
      addedMap[node.id] = cnode;
    }
  });
  // assign the depth for each element
  var maxDepth = 0;
  result.forEach(function (tree) {
    tree.depth = maxDepth + 10;
    traverseTree(tree, function (child) {
      var parent;
      var itemType = addedMap[child.id].itemType;
      if (itemType === 'node') {
        parent = addedMap[child.comboId];
      } else {
        parent = addedMap[child.parentId];
      }
      if (parent) {
        if (itemType === 'node') child.depth = maxDepth + 1;else child.depth = maxDepth + 10;
      } else {
        child.depth = maxDepth + 10;
      }
      if (maxDepth < child.depth) maxDepth = child.depth;
      var oriNodeModel = nodeMap[child.id];
      if (oriNodeModel) {
        oriNodeModel.depth = child.depth;
      }
      return true;
    });
  });
  return result;
};
export var reconstructTree = function reconstructTree(trees, subtreeId, newParentId) {
  var _a;
  var brothers = trees;
  var subtree;
  var comboChildsMap = {
    root: {
      children: trees
    }
  };
  var foundSubTree = false;
  var oldParentId = 'root';
  (trees || []).forEach(function (tree) {
    if (foundSubTree) return;
    if (tree.id === subtreeId) {
      subtree = tree;
      if (tree.itemType === 'combo') {
        subtree.parentId = newParentId;
      } else {
        subtree.comboId = newParentId;
      }
      foundSubTree = true;
      return;
    }
    traverseTree(tree, function (child) {
      var _a;
      comboChildsMap[child.id] = {
        children: (child === null || child === void 0 ? void 0 : child.children) || []
      };
      // store the old parent id to delete the subtree from the old parent's children in next recursion
      brothers = (_a = comboChildsMap[child.parentId || child.comboId || 'root']) === null || _a === void 0 ? void 0 : _a.children;
      if (child && (child.removed || subtreeId === child.id) && brothers) {
        oldParentId = child.parentId || child.comboId || 'root';
        subtree = child;
        // re-assign the parentId or comboId for the moved subtree
        if (child.itemType === 'combo') {
          subtree.parentId = newParentId;
        } else {
          subtree.comboId = newParentId;
        }
        foundSubTree = true;
        return false;
      }
      return true;
    });
  });
  brothers = (_a = comboChildsMap[oldParentId]) === null || _a === void 0 ? void 0 : _a.children;
  var index = brothers ? brothers.indexOf(subtree) : -1;
  if (index > -1) brothers.splice(index, 1);
  // 如果遍历完整棵树还没有找到，说明之前就不在树中
  if (!foundSubTree) {
    subtree = {
      id: subtreeId,
      itemType: 'node',
      comboId: newParentId
    };
    comboChildsMap[subtreeId] = {
      children: undefined
    };
  }
  // append to new parent
  if (subtreeId) {
    var found_1 = false;
    // newParentId is undefined means the subtree will have no parent
    if (newParentId) {
      var newParentDepth_1 = 0;
      (trees || []).forEach(function (tree) {
        if (found_1) return; // terminate
        traverseTree(tree, function (child) {
          // append subtree to the new parent ans assign the depth to the subtree
          if (newParentId === child.id) {
            found_1 = true;
            if (child.children) child.children.push(subtree);else child.children = [subtree];
            newParentDepth_1 = child.depth;
            if (subtree.itemType === 'node') subtree.depth = newParentDepth_1 + 2;else subtree.depth = newParentDepth_1 + 1;
            return false; // terminate
          }

          return true;
        });
      });
    } else if ((!newParentId || !found_1) && subtree.itemType !== 'node') {
      // if the newParentId is undefined or it is not found in the tree, add the subTree to the root
      trees.push(subtree);
    }
    // update the depth of the subtree and its children from the subtree
    var currentDepth_1 = subtree.depth;
    traverseTree(subtree, function (child) {
      if (child.itemType === 'node') currentDepth_1 += 2;else currentDepth_1 += 1;
      child.depth = currentDepth_1;
      return true;
    });
  }
  return trees;
};
export var getComboBBox = function getComboBBox(children, graph, combo) {
  var comboBBox = {
    minX: Infinity,
    minY: Infinity,
    maxX: -Infinity,
    maxY: -Infinity,
    x: undefined,
    y: undefined,
    width: undefined,
    height: undefined,
    centerX: undefined,
    centerY: undefined
  };
  if (!children || children.length === 0) {
    var comboModel = combo === null || combo === void 0 ? void 0 : combo.getModel();
    var _a = comboModel || {},
      x = _a.x,
      y = _a.y,
      fixSize = _a.fixSize,
      collapsed = _a.collapsed,
      fixCollapseSize = _a.fixCollapseSize;
    var useFixSize = collapsed ? fixCollapseSize : fixSize;
    var _b = isArray(useFixSize) ? useFixSize : [useFixSize, useFixSize],
      width = _b[0],
      height = _b[1];
    var halfSize = [width / 2, height / 2];
    return {
      minX: x - halfSize[0],
      minY: y - halfSize[1],
      maxX: x + halfSize[0],
      maxY: y + halfSize[1],
      x: x,
      y: y,
      width: width,
      height: height
    };
  }
  children.forEach(function (child) {
    var childItem = graph.findById(child.id);
    if (!childItem || !childItem.isVisible()) return; // ignore hidden children
    childItem.set('bboxCanvasCache', undefined);
    var childBBox = childItem.getCanvasBBox();
    if (childBBox.x && comboBBox.minX > childBBox.minX) comboBBox.minX = childBBox.minX;
    if (childBBox.y && comboBBox.minY > childBBox.minY) comboBBox.minY = childBBox.minY;
    if (childBBox.x && comboBBox.maxX < childBBox.maxX) comboBBox.maxX = childBBox.maxX;
    if (childBBox.y && comboBBox.maxY < childBBox.maxY) comboBBox.maxY = childBBox.maxY;
  });
  comboBBox.x = (comboBBox.minX + comboBBox.maxX) / 2;
  comboBBox.y = (comboBBox.minY + comboBBox.maxY) / 2;
  comboBBox.width = comboBBox.maxX - comboBBox.minX;
  comboBBox.height = comboBBox.maxY - comboBBox.minY;
  comboBBox.centerX = (comboBBox.minX + comboBBox.maxX) / 2;
  comboBBox.centerY = (comboBBox.minY + comboBBox.maxY) / 2;
  // if it is a circle combo, diagnal length of the children's bbox should be the diameter of the combo's bbox
  if ((combo === null || combo === void 0 ? void 0 : combo.getKeyShape().get('type')) === 'circle') {
    comboBBox.width = Math.hypot(comboBBox.height, comboBBox.width);
    comboBBox.height = comboBBox.width;
  }
  Object.keys(comboBBox).forEach(function (key) {
    if (comboBBox[key] === Infinity || comboBBox[key] === -Infinity) {
      comboBBox[key] = undefined;
    }
  });
  return comboBBox;
};
export var shouldRefreshEdge = function shouldRefreshEdge(cfg) {
  var refreshEdge = isNumber(cfg.x) || isNumber(cfg.y) || cfg.type || cfg.anchorPoints || cfg.size;
  if (cfg.style) refreshEdge = refreshEdge || isNumber(cfg.style.r) || isNumber(cfg.style.width) || isNumber(cfg.style.height) || isNumber(cfg.style.rx) || isNumber(cfg.style.ry);
  return refreshEdge;
};
export var cloneBesidesImg = function cloneBesidesImg(obj) {
  var clonedObj = {};
  Object.keys(obj).forEach(function (key1) {
    var obj2 = obj[key1];
    if (key1 === 'img' && !isString(obj2)) return;
    if (isObject(obj2) && !isArray(obj2)) {
      var clonedObj2_1 = {};
      Object.keys(obj2).forEach(function (key2) {
        var v = obj2[key2];
        if (key2 === 'img' && !isString(v)) return;
        clonedObj2_1[key2] = clone(v);
      });
      clonedObj[key1] = clonedObj2_1;
    } else {
      clonedObj[key1] = clone(obj2);
    }
  });
  return clonedObj;
};
export var getAnimateCfgWithCallback = function getAnimateCfgWithCallback(_a) {
  var animateCfg = _a.animateCfg,
    callback = _a.callback;
  var animateConfig;
  if (!animateCfg) {
    animateConfig = {
      duration: 500,
      callback: callback
    };
  } else {
    animateConfig = clone(animateCfg);
    if (animateCfg.callback) {
      var animateCfgCallback_1 = animateCfg.callback;
      animateConfig.callback = function () {
        callback();
        animateCfgCallback_1();
      };
    } else {
      animateConfig.callback = callback;
    }
  }
  return animateConfig;
};