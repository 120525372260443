import { __assign, __rest } from "tslib";
import { registerNode, BaseGlobal as Global } from '@antv/g6-core';
import { deepMix } from '@antv/util';
// 带有图标的圆，可用于拓扑图中
registerNode('circle', {
  // 自定义节点时的配置
  options: {
    size: Global.defaultNode.size,
    style: {
      x: 0,
      y: 0,
      stroke: Global.defaultNode.style.stroke,
      fill: Global.defaultNode.style.fill,
      lineWidth: Global.defaultNode.style.lineWidth
    },
    labelCfg: {
      style: {
        fill: Global.nodeLabel.style.fill,
        fontSize: Global.nodeLabel.style.fontSize,
        fontFamily: Global.windowFontFamily
      }
    },
    // 节点上左右上下四个方向上的链接circle配置
    linkPoints: {
      top: false,
      right: false,
      bottom: false,
      left: false,
      // circle的大小
      size: Global.defaultNode.linkPoints.size,
      lineWidth: Global.defaultNode.linkPoints.lineWidth,
      fill: Global.defaultNode.linkPoints.fill,
      stroke: Global.defaultNode.linkPoints.stroke
    },
    // 节点中icon配置
    icon: {
      // 是否显示icon，值为 false 则不渲染icon
      show: false,
      // icon的地址，字符串类型
      img: 'https://gw.alipayobjects.com/zos/bmw-prod/5d015065-8505-4e7a-baec-976f81e3c41d.svg',
      width: 20,
      height: 20
    },
    stateStyles: __assign({}, Global.nodeStateStyles)
  },
  shapeType: 'circle',
  // 文本位置
  labelPosition: 'center',
  drawShape: function drawShape(cfg, group) {
    var _a = (this.mergeStyle || this.getOptions(cfg)).icon,
      defaultIcon = _a === void 0 ? {} : _a;
    var style = this.getShapeStyle(cfg);
    var icon = deepMix({}, defaultIcon, cfg.icon);
    var name = "".concat(this.type, "-keyShape");
    var keyShape = group.addShape('circle', {
      attrs: style,
      className: name,
      name: name,
      draggable: true
    });
    group['shapeMap'][name] = keyShape;
    var width = icon.width,
      height = icon.height,
      show = icon.show,
      text = icon.text;
    if (show) {
      var iconName = "".concat(this.type, "-icon");
      if (text) {
        group['shapeMap'][iconName] = group.addShape('text', {
          attrs: __assign({
            x: 0,
            y: 0,
            fontSize: 12,
            fill: '#000',
            stroke: '#000',
            textBaseline: 'middle',
            textAlign: 'center'
          }, icon),
          className: iconName,
          name: iconName,
          draggable: true
        });
      } else {
        group['shapeMap'][iconName] = group.addShape('image', {
          attrs: __assign({
            x: -width / 2,
            y: -height / 2
          }, icon),
          className: iconName,
          name: iconName,
          draggable: true
        });
      }
    }
    this.drawLinkPoints(cfg, group);
    return keyShape;
  },
  /**
   * 绘制节点上的LinkPoints
   * @param {Object} cfg data数据配置项
   * @param {Group} group Group实例
   */
  drawLinkPoints: function drawLinkPoints(cfg, group) {
    var linkPoints = (this.mergeStyle || this.getOptions(cfg)).linkPoints;
    if (!linkPoints) return;
    var _a = linkPoints || {},
      top = _a.top,
      left = _a.left,
      right = _a.right,
      bottom = _a.bottom,
      markSize = _a.size,
      markR = _a.r,
      markStyle = __rest(_a, ["top", "left", "right", "bottom", "size", "r"]);
    var size = this.getSize(cfg);
    var r = size[0] / 2;
    if (left) {
      // left circle
      var name_1 = 'link-point-left';
      group['shapeMap'][name_1] = group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: -r,
          y: 0,
          r: markSize / 2 || markR || 5
        }),
        className: name_1,
        name: name_1,
        isAnchorPoint: true
      });
    }
    if (right) {
      // right circle
      var name_2 = 'link-point-right';
      group['shapeMap'][name_2] = group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: r,
          y: 0,
          r: markSize / 2 || markR || 5
        }),
        className: name_2,
        name: name_2,
        isAnchorPoint: true
      });
    }
    if (top) {
      // top circle
      var name_3 = 'link-point-top';
      group['shapeMap'][name_3] = group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: 0,
          y: -r,
          r: markSize / 2 || markR || 5
        }),
        className: name_3,
        name: name_3,
        isAnchorPoint: true
      });
    }
    if (bottom) {
      // bottom circle
      var name_4 = 'link-point-bottom';
      group['shapeMap'][name_4] = group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: 0,
          y: r,
          r: markSize / 2 || markR || 5
        }),
        className: name_4,
        name: name_4,
        isAnchorPoint: true
      });
    }
  },
  /**
   * 获取节点的样式，供基于该节点自定义时使用
   * @param {Object} cfg 节点数据模型
   * @return {Object} 节点的样式
   */
  getShapeStyle: function getShapeStyle(cfg) {
    var defaultStyle = (this.mergeStyle || this.getOptions(cfg)).style;
    var strokeStyle = {
      stroke: cfg.color
    };
    // 如果设置了color，则覆盖默认的stroke属性
    var style = deepMix({}, defaultStyle, strokeStyle);
    var size = this.getSize(cfg);
    var r = size[0] / 2;
    var styles = __assign({
      x: 0,
      y: 0,
      r: r
    }, style);
    return styles;
  },
  update: function update(cfg, item, updateType) {
    var group = item.getContainer();
    var size = this.getSize(cfg);
    var style = __assign({}, cfg.style);
    if (cfg.style.stroke === undefined && cfg.color) {
      style.stroke = cfg.color;
    }
    if (cfg.style.r === undefined && !isNaN(size[0])) {
      style.r = size[0] / 2;
    }
    this.updateShape(cfg, item, style, true, updateType);
    this.updateLinkPoints(cfg, group);
  }
}, 'single-node');