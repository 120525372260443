var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import { each, mix } from '@antv/util';
import { Util } from '@antv/g6-core';
import Base from '../base';
var pointLineDistance = Util.pointLineDistance;
// 对齐线样式
var alignLineStyle = {
  stroke: '#FA8C16',
  lineWidth: 1
};
var SnapLine = /** @class */function (_super) {
  __extends(SnapLine, _super);
  function SnapLine(props) {
    return _super.call(this, props) || this;
  }
  SnapLine.prototype.getDefaultCfgs = function () {
    return {
      line: alignLineStyle,
      /**
       * item align type
       * @type {String|True|False}
       */
      itemAlignType: 'center',
      /**
       * tolerance to item force align
       * @type {String|True|False}
       */
      tolerance: 5,
      horizontalLines: {},
      verticalLines: {},
      alignLines: []
    };
  };
  SnapLine.prototype.init = function () {};
  // class-methods-use-this
  SnapLine.prototype.getEvents = function () {
    return {
      'node:dragstart': 'onDragStart',
      'node:drag': 'onDrag',
      'node:dragend': 'onDragEnd'
    };
  };
  SnapLine.prototype.onDragStart = function () {
    this.initBoxLine();
  };
  SnapLine.prototype.onDrag = function (e) {
    var item = e.item;
    // 计算辅助线位置,拖动过程中更新辅助线
    var delegateShape = item.get('delegateShape') || item;
    var bbox = delegateShape.getBBox();
    var model = item.getModel();
    var dx = model.x - bbox.x;
    var dy = model.y - bbox.y;
    this.show({
      x: bbox.minX + dx,
      y: bbox.minY + dy
    }, {
      width: bbox.width,
      height: bbox.height
    });
  };
  SnapLine.prototype.onDragEnd = function () {
    // 拖动结束时候删除辅助线
    this.destory();
  };
  /**
   * 每次开始拖动之前，计算出所有节点在水平和垂直方向上，左中右三条中线，并缓存起来
   *
   * @param {object} item Node节点
   * @memberof AlignLine
   */
  SnapLine.prototype.initBoxLine = function () {
    var _a = this._cfgs,
      horizontalLines = _a.horizontalLines,
      verticalLines = _a.verticalLines,
      itemAlignType = _a.itemAlignType;
    var graph = this.get('graph');
    var nodes = graph.getNodes();
    nodes.forEach(function (item) {
      var bbox = item.getBBox();
      var nodeId = item.get('id');
      // 设置水平方向辅助线
      if (itemAlignType === true || itemAlignType === 'horizontal') {
        // tltr: top left top right
        // lcrc: left center right center
        // blbr: bottom left bottom right
        horizontalLines["".concat(nodeId, "tltr")] = [bbox.minX, bbox.minY, bbox.maxX, bbox.minY, item];
        horizontalLines["".concat(nodeId, "lcrc")] = [bbox.minX, bbox.centerY, bbox.maxX, bbox.centerY, item];
        horizontalLines["".concat(nodeId, "blbr")] = [bbox.minX, bbox.maxY, bbox.maxX, bbox.maxY, item];
      } else if (itemAlignType === 'center') {
        horizontalLines["".concat(nodeId, "lcrc")] = [bbox.minX, bbox.centerY, bbox.maxX, bbox.centerY, item];
      }
      // 设置垂直方向辅助线
      if (itemAlignType === true || itemAlignType === 'vertical') {
        // tlbl: top left bottom left
        // tcbc: top center bottom center
        // trbr: top right bottom right
        verticalLines["".concat(nodeId, "tlbl")] = [bbox.minX, bbox.minY, bbox.minX, bbox.maxY, item];
        verticalLines["".concat(nodeId, "tcbc")] = [bbox.centerX, bbox.minY, bbox.centerX, bbox.maxY, item];
        verticalLines["".concat(nodeId, "trbr")] = [bbox.maxX, bbox.minY, bbox.maxX, bbox.maxY, item];
      } else if (itemAlignType === 'center') {
        verticalLines["".concat(nodeId, "tcbc")] = [bbox.centerX, bbox.minY, bbox.centerX, bbox.maxY, item];
      }
    });
  };
  /**
   * 显示AlignLine
   *
   * @param {object} point 起始点
   * @param {object} bbox BBox
   * @returns
   * @memberof AlignLine
   */
  SnapLine.prototype.show = function (point, bbox) {
    var originPoint = mix({}, point);
    this.itemAlign(point, bbox, originPoint);
    return point;
  };
  /**
   * 拖动拖出中添加辅助线
   *
   * @param {object} point 起始点
   * @param {object} bbox 代理形状的bbox
   * @param {object} originPoint 原始点，同point
   * @memberof AlignLine
   */
  SnapLine.prototype.itemAlign = function (point, bbox, originPoint) {
    var _this = this;
    var _a = this._cfgs,
      horizontalLines = _a.horizontalLines,
      verticalLines = _a.verticalLines,
      tolerance = _a.tolerance;
    var tc = {
      x: originPoint.x + bbox.width / 2,
      y: originPoint.y
    };
    var cc = {
      x: originPoint.x + bbox.width / 2,
      y: originPoint.y + bbox.height / 2
    };
    var bc = {
      x: originPoint.x + bbox.width / 2,
      y: originPoint.y + bbox.height
    };
    var lc = {
      x: originPoint.x,
      y: originPoint.y + bbox.height / 2
    };
    var rc = {
      x: originPoint.x + bbox.width,
      y: originPoint.y + bbox.height / 2
    };
    var horizontalDis = [];
    var verticalDis = [];
    var alignCfg = null;
    this.clearAlignLine();
    each(horizontalLines, function (line) {
      if (line[4].isVisible) {
        horizontalDis.push(_this.getLineDisObject(line, tc));
        horizontalDis.push(_this.getLineDisObject(line, cc));
        horizontalDis.push(_this.getLineDisObject(line, bc));
      }
    });
    each(verticalLines, function (line) {
      if (line[4].isVisible) {
        verticalDis.push(_this.getLineDisObject(line, lc));
        verticalDis.push(_this.getLineDisObject(line, cc));
        verticalDis.push(_this.getLineDisObject(line, rc));
      }
    });
    horizontalDis.sort(function (a, b) {
      return a.dis - b.dis;
    });
    verticalDis.sort(function (a, b) {
      return a.dis - b.dis;
    });
    if (horizontalDis.length !== 0 && horizontalDis[0].dis < tolerance) {
      point.y = horizontalDis[0].line[1] - horizontalDis[0].point.y + originPoint.y;
      alignCfg = {
        type: 'item',
        horizontals: [horizontalDis[0]]
      };
      for (var i = 1; i < 3; i++) {
        if (horizontalDis[0].dis === horizontalDis[i].dis) {
          alignCfg.horizontals.push(horizontalDis[i]);
        }
      }
    }
    if (verticalDis.length !== 0 && verticalDis[0].dis < tolerance) {
      point.x = verticalDis[0].line[0] - verticalDis[0].point.x + originPoint.x;
      if (!alignCfg) {
        alignCfg = {
          type: 'item',
          verticals: [verticalDis[0]]
        };
      } else {
        alignCfg.verticals = [verticalDis[0]];
      }
      for (var i = 1; i < 3; i++) {
        if (verticalDis[0].dis === verticalDis[i].dis) {
          alignCfg.verticals.push(verticalDis[i]);
        }
      }
    }
    if (alignCfg) {
      alignCfg.bbox = bbox;
      this.addAlignLine(alignCfg);
    }
  };
  /**
   * 根据配置项添加辅助线
   *
   * @param {object} cfg
   * @memberof AlignLine
   */
  SnapLine.prototype.addAlignLine = function (cfg) {
    var bbox = cfg.bbox,
      type = cfg.type,
      horizontals = cfg.horizontals,
      verticals = cfg.verticals;
    var _a = this._cfgs,
      lineStyle = _a.line,
      alignLines = _a.alignLines;
    var graph = this.get('graph');
    var group = graph.get('group');
    if (type === 'item') {
      if (horizontals) {
        each(horizontals, function (horizontal) {
          var refLine = horizontal.line,
            refPoint = horizontal.point;
          var lineCenterX = (refLine[0] + refLine[2]) / 2;
          var x1;
          var x2;
          if (refPoint.x < lineCenterX) {
            x1 = refPoint.x - bbox.width / 2;
            x2 = Math.max(refLine[0], refLine[2]);
          } else {
            x1 = refPoint.x + bbox.width / 2;
            x2 = Math.min(refLine[0], refLine[2]);
          }
          var lineAttrs = mix({
            x1: x1,
            y1: refLine[1],
            x2: x2,
            y2: refLine[1]
          }, lineStyle);
          var line = group.addShape('line', {
            attrs: lineAttrs,
            capture: false
          });
          alignLines.push(line);
        });
      }
      if (verticals) {
        each(verticals, function (vertical) {
          var refLine = vertical.line,
            refPoint = vertical.point;
          var lineCenterY = (refLine[1] + refLine[3]) / 2;
          var y1;
          var y2;
          if (refPoint.y < lineCenterY) {
            y1 = refPoint.y - bbox.height / 2;
            y2 = Math.max(refLine[1], refLine[3]);
          } else {
            y1 = refPoint.y + bbox.height / 2;
            y2 = Math.min(refLine[1], refLine[3]);
          }
          var lineAtts = mix({
            x1: refLine[0],
            y1: y1,
            x2: refLine[0],
            y2: y2
          }, lineStyle);
          var line = group.addShape('line', {
            attrs: lineAtts,
            capture: false
          });
          alignLines.push(line);
        });
      }
    }
  };
  /**
   * 获取点到线的距离
   *
   * @param {array} line [x1, y1, x2, y2] 线的四个点
   * @param {object} point 点的x和y坐标点 {x, y}
   * @returns
   * @memberof AlignLine
   */
  SnapLine.prototype.getLineDisObject = function (line, point) {
    return {
      line: line,
      point: point,
      dis: pointLineDistance(line, point)
    };
  };
  SnapLine.prototype.getContainer = function () {
    return this.get('container');
  };
  /**
   * 拖动过程中，清楚上次绘制的线
   *
   * @memberof AlignLine
   */
  SnapLine.prototype.clearAlignLine = function () {
    var alignLines = this._cfgs.alignLines;
    each(alignLines, function (line) {
      line.remove();
    });
    alignLines.length = 0;
  };
  /**
   * 拖动结束时候，情况缓存的节点的辅助线，同时删除绘制的线
   *
   * @memberof AlignLine
   */
  SnapLine.prototype.destory = function () {
    var _a = this._cfgs,
      horizontalLines = _a.horizontalLines,
      verticalLines = _a.verticalLines;
    var graph = this.get('graph');
    var nodes = graph.getNodes();
    nodes.forEach(function (node) {
      var itemId = node.get('id');
      delete horizontalLines["".concat(itemId, "tltr")];
      delete horizontalLines["".concat(itemId, "lcrc")];
      delete horizontalLines["".concat(itemId, "blbr")];
      delete verticalLines["".concat(itemId, "tlbl")];
      delete verticalLines["".concat(itemId, "tcbc")];
      delete verticalLines["".concat(itemId, "trbr")];
    });
    this.clearAlignLine();
  };
  return SnapLine;
}(Base);
export default SnapLine;