import { __assign } from "tslib";
import { isNumber, isString } from '@antv/util';
import { formatPadding, isNaN } from '../../util/base';
import { applyMatrix, invertMatrix, lerpArray } from '../../util/math';
import { transform } from '@antv/matrix-util/lib/ext';
import { getAnimateCfgWithCallback } from '../../util/graphic';
var ViewController = /** @class */function () {
  function ViewController(graph) {
    this.destroyed = false;
    this.graph = graph;
    this.destroyed = false;
  }
  // get view center coordinate
  ViewController.prototype.getViewCenter = function () {
    var padding = this.getFormatPadding();
    var graph = this.graph;
    var width = this.graph.get('width');
    var height = graph.get('height');
    return {
      x: (width - padding[1] - padding[3]) / 2 + padding[3],
      y: (height - padding[0] - padding[2]) / 2 + padding[0]
    };
  };
  ViewController.prototype.fitCenter = function (animate, animateCfg) {
    var graph = this.graph;
    var group = graph.get('group');
    var bbox;
    // if reaches optimizeThreshold, fitView according to the 4 corner nodes
    var nodes = graph.getNodes();
    if (nodes.length > graph.get('optimizeThreshold')) {
      var minX_1 = Infinity;
      var minY_1 = Infinity;
      var maxX_1 = -Infinity;
      var maxY_1 = -Infinity;
      nodes.forEach(function (node) {
        var _a = node.getModel(),
          x = _a.x,
          y = _a.y;
        if (minX_1 > x) minX_1 = x;
        if (minY_1 > y) minY_1 = y;
        if (maxX_1 < x) maxX_1 = x;
        if (maxY_1 < y) maxY_1 = y;
      });
      var matrix = group.getMatrix() || [1, 0, 0, 0, 1, 0, 0, 0, 1];
      var _a = applyMatrix({
          x: minX_1,
          y: minY_1
        }, matrix),
        transMinX = _a.x,
        transMinY = _a.y;
      var _b = applyMatrix({
          x: maxX_1,
          y: maxY_1
        }, matrix),
        transMaxX = _b.x,
        transMaxY = _b.y;
      bbox = {
        minX: transMinX,
        maxX: transMaxX,
        minY: transMinY,
        maxY: transMaxY,
        width: transMaxX - transMinX,
        height: transMaxY - transMinY,
        x: transMinX,
        y: transMinY
      };
    } else {
      bbox = group.getCanvasBBox();
    }
    if (bbox.width === 0 || bbox.height === 0) return;
    var viewCenter = this.getViewCenter();
    var groupCenter = {
      x: bbox.x + bbox.width / 2,
      y: bbox.y + bbox.height / 2
    };
    graph.translate(viewCenter.x - groupCenter.x, viewCenter.y - groupCenter.y, animate, animateCfg);
  };
  ViewController.prototype.animatedFitView = function (group, startMatrix, animateCfg, bbox, viewCenter, groupCenter, ratio, zoomToFit) {
    var graph = this.graph;
    animateCfg = animateCfg ? animateCfg : {
      duration: 500,
      easing: 'easeCubic'
    };
    // start from the default matrix
    var matrix = [1, 0, 0, 0, 1, 0, 0, 0, 1];
    // Translate
    var vx = bbox.x + viewCenter.x - groupCenter.x - bbox.minX;
    var vy = bbox.y + viewCenter.y - groupCenter.y - bbox.minY;
    if (isNaN(vx) || isNaN(vy)) return;
    var translatedMatrix = transform(matrix, [['t', vx, vy]]);
    if (!zoomToFit) {
      // If zooming is not needed just animate the current translated matrix and return
      var animationConfig_1 = getAnimateCfgWithCallback({
        animateCfg: animateCfg,
        callback: function callback() {
          graph.emit('viewportchange', {
            action: 'translate',
            matrix: translatedMatrix
          });
        }
      });
      group.animate(function (ratio) {
        return {
          matrix: lerpArray(startMatrix, translatedMatrix, ratio)
        };
      }, animationConfig_1);
      return;
    }
    // Zoom
    var minZoom = graph.get('minZoom');
    var maxZoom = graph.get('maxZoom');
    var realRatio = ratio;
    if (minZoom && ratio < minZoom) {
      realRatio = minZoom;
      console.warn('fitview failed, ratio out of range, ratio: %f', ratio, 'graph minzoom has been used instead');
    } else if (maxZoom && ratio > maxZoom) {
      realRatio = maxZoom;
      console.warn('fitview failed, ratio out of range, ratio: %f', ratio, 'graph maxzoom has been used instead');
    }
    var zoomedMatrix = transform(translatedMatrix, [['t', -viewCenter.x, -viewCenter.y], ['s', realRatio, realRatio], ['t', viewCenter.x, viewCenter.y]]);
    // Animation
    var animationConfig = getAnimateCfgWithCallback({
      animateCfg: animateCfg,
      callback: function callback() {
        group.setMatrix(zoomedMatrix);
        graph.emit('viewportchange', {
          action: 'translate',
          matrix: translatedMatrix
        });
        graph.emit('viewportchange', {
          action: 'zoom',
          matrix: zoomedMatrix
        });
      }
    });
    group.stopAnimate();
    group.setMatrix(startMatrix);
    group.animate(function (ratio) {
      return {
        matrix: lerpArray(startMatrix, zoomedMatrix, ratio)
      };
    }, animationConfig);
  };
  // fit view graph
  ViewController.prototype.fitView = function (animate, animateCfg) {
    var graph = this.graph;
    var padding = this.getFormatPadding();
    var width = graph.get('width');
    var height = graph.get('height');
    var group = graph.get('group');
    var startMatrix = group.getMatrix() || [1, 0, 0, 0, 1, 0, 0, 0, 1];
    group.resetMatrix();
    var bbox;
    // if reaches optimizeThreshold, fitView according to the 4 corner nodes
    var nodes = graph.getNodes();
    if (nodes.length > graph.get('optimizeThreshold')) {
      var minX_2 = Infinity;
      var minY_2 = Infinity;
      var maxX_2 = -Infinity;
      var maxY_2 = -Infinity;
      nodes.forEach(function (node) {
        var _a = node.getModel(),
          x = _a.x,
          y = _a.y;
        if (minX_2 > x) minX_2 = x;
        if (minY_2 > y) minY_2 = y;
        if (maxX_2 < x) maxX_2 = x;
        if (maxY_2 < y) maxY_2 = y;
      });
      bbox = {
        minX: minX_2,
        maxX: maxX_2,
        minY: minY_2,
        maxY: maxY_2,
        width: maxX_2 - minX_2,
        height: maxY_2 - minY_2,
        x: minX_2,
        y: minY_2
      };
    } else {
      bbox = group.getCanvasBBox();
    }
    if (bbox.width === 0 || bbox.height === 0) return;
    var viewCenter = this.getViewCenter();
    var groupCenter = {
      x: bbox.x + bbox.width / 2,
      y: bbox.y + bbox.height / 2
    };
    // Compute ratio
    var w = (width - padding[1] - padding[3]) / bbox.width;
    var h = (height - padding[0] - padding[2]) / bbox.height;
    var ratio = w;
    if (w > h) {
      ratio = h;
    }
    if (animate) {
      this.animatedFitView(group, startMatrix, animateCfg, bbox, viewCenter, groupCenter, ratio, true);
    } else {
      var dx = viewCenter.x - groupCenter.x;
      var dy = viewCenter.y - groupCenter.y;
      if (isNaN(dx) || isNaN(dy)) return;
      graph.translate(dx, dy);
      if (!graph.zoom(ratio, viewCenter)) {
        console.warn('zoom failed, ratio out of range, ratio: %f', ratio);
      }
    }
  };
  // fit view graph by rule
  ViewController.prototype.fitViewByRules = function (rules, animate, animateCfg) {
    var _a = rules.onlyOutOfViewPort,
      onlyOutOfViewPort = _a === void 0 ? false : _a,
      _b = rules.direction,
      direction = _b === void 0 ? 'both' : _b,
      _c = rules.ratioRule,
      ratioRule = _c === void 0 ? 'min' : _c;
    var graph = this.graph;
    var padding = this.getFormatPadding();
    var width = graph.get('width');
    var height = graph.get('height');
    var group = graph.get('group');
    var startMatrix = group.getMatrix() || [1, 0, 0, 0, 1, 0, 0, 0, 1];
    group.resetMatrix();
    var bbox;
    // if reaches optimizeThreshold, fitView according to the 4 corner nodes
    var nodes = graph.getNodes();
    if (nodes.length > graph.get('optimizeThreshold')) {
      var minX_3 = Infinity;
      var minY_3 = Infinity;
      var maxX_3 = -Infinity;
      var maxY_3 = -Infinity;
      nodes.forEach(function (node) {
        var _a = node.getModel(),
          x = _a.x,
          y = _a.y;
        if (minX_3 > x) minX_3 = x;
        if (minY_3 > y) minY_3 = y;
        if (maxX_3 < x) maxX_3 = x;
        if (maxY_3 < y) maxY_3 = y;
      });
      bbox = {
        minX: minX_3,
        maxX: maxX_3,
        minY: minY_3,
        maxY: maxY_3,
        width: maxX_3 - minX_3,
        height: maxY_3 - minY_3,
        x: minX_3,
        y: minY_3
      };
    } else {
      bbox = group.getCanvasBBox();
    }
    if (bbox.width === 0 || bbox.height === 0) return;
    var viewCenter = this.getViewCenter();
    var groupCenter = {
      x: bbox.x + bbox.width / 2,
      y: bbox.y + bbox.height / 2
    };
    // Compute ratio
    var wRatio = (width - padding[1] - padding[3]) / bbox.width;
    var hRatio = (height - padding[0] - padding[2]) / bbox.height;
    var ratio;
    if (direction === 'x') {
      ratio = wRatio;
    } else if (direction === 'y') {
      ratio = hRatio;
    } else {
      // ratioRule
      ratio = ratioRule === 'max' ? Math.max(wRatio, hRatio) : Math.min(wRatio, hRatio);
    }
    // 如果设置了仅对超出视口宽高的场景进行fitview，则没超出的场景zoom取1
    if (onlyOutOfViewPort) {
      ratio = ratio < 1 ? ratio : 1;
    }
    if (animate) {
      this.animatedFitView(group, startMatrix, animateCfg, bbox, viewCenter, groupCenter, ratio, true);
    } else {
      var initZoomRatio = graph.getZoom();
      var endZoom = initZoomRatio * ratio;
      var minZoom = graph.get('minZoom');
      // 如果zoom小于最小zoom, 则以最小zoom为准
      if (endZoom < minZoom) {
        endZoom = minZoom;
        console.warn('fitview failed, ratio out of range, ratio: %f', ratio, 'graph minzoom has been used instead');
      }
      graph.translate(viewCenter.x - groupCenter.x, viewCenter.y - groupCenter.y);
      graph.zoomTo(endZoom, viewCenter);
    }
  };
  ViewController.prototype.getFormatPadding = function () {
    var padding = this.graph.get('fitViewPadding');
    return formatPadding(padding);
  };
  ViewController.prototype.focusPoint = function (point, animate, animateCfg) {
    var _this = this;
    var viewCenter = this.getViewCenter();
    var modelCenter = this.getPointByCanvas(viewCenter.x, viewCenter.y);
    var viewportMatrix = this.graph.get('group').getMatrix();
    if (!viewportMatrix) viewportMatrix = [1, 0, 0, 0, 1, 0, 0, 0, 1];
    if (animate) {
      var dx_1 = (modelCenter.x - point.x) * viewportMatrix[0];
      var dy_1 = (modelCenter.y - point.y) * viewportMatrix[4];
      var lastX_1 = 0;
      var lastY_1 = 0;
      var newX_1 = 0;
      var newY_1 = 0;
      // 动画每次平移一点，直到目标位置
      this.graph.get('canvas').animate(function (ratio) {
        newX_1 = dx_1 * ratio;
        newY_1 = dy_1 * ratio;
        _this.graph.translate(newX_1 - lastX_1, newY_1 - lastY_1);
        lastX_1 = newX_1;
        lastY_1 = newY_1;
      }, __assign({}, animateCfg));
    } else {
      this.graph.translate((modelCenter.x - point.x) * viewportMatrix[0], (modelCenter.y - point.y) * viewportMatrix[4]);
    }
  };
  /**
   * 将 Canvas 坐标转成视口坐标
   * @param canvasX canvas x 坐标
   * @param canvasY canvas y 坐标
   */
  ViewController.prototype.getPointByCanvas = function (canvasX, canvasY) {
    var viewportMatrix = this.graph.get('group').getMatrix();
    if (!viewportMatrix) {
      viewportMatrix = [1, 0, 0, 0, 1, 0, 0, 0, 1];
    }
    var point = invertMatrix({
      x: canvasX,
      y: canvasY
    }, viewportMatrix);
    return point;
  };
  /**
   * 将页面坐标转成视口坐标
   * @param clientX 页面 x 坐标
   * @param clientY 页面 y 坐标
   */
  ViewController.prototype.getPointByClient = function (clientX, clientY) {
    var canvas = this.graph.get('canvas');
    var canvasPoint = canvas.getPointByClient(clientX, clientY);
    return this.getPointByCanvas(canvasPoint.x, canvasPoint.y);
  };
  /**
   * 将视口坐标转成页面坐标
   * @param x 视口 x 坐标
   * @param y 视口 y 坐标
   */
  ViewController.prototype.getClientByPoint = function (x, y) {
    var canvas = this.graph.get('canvas');
    var canvasPoint = this.getCanvasByPoint(x, y);
    var point = canvas.getClientByPoint(canvasPoint.x, canvasPoint.y);
    return {
      x: point.x,
      y: point.y
    };
  };
  /**
   * 将视口坐标转成 Canvas 坐标
   * @param x 视口 x 坐标
   * @param y 视口 y 坐标
   */
  ViewController.prototype.getCanvasByPoint = function (x, y) {
    var viewportMatrix = this.graph.get('group').getMatrix();
    if (!viewportMatrix) {
      viewportMatrix = [1, 0, 0, 0, 1, 0, 0, 0, 1];
    }
    return applyMatrix({
      x: x,
      y: y
    }, viewportMatrix);
  };
  /**
   * 将元素移动到画布中心
   * @param item Item 实例或 id
   * @param {boolean} animate 是否带有动画地移动
   * @param {GraphAnimateConfig} animateCfg 若带有动画，动画的配置项
   */
  ViewController.prototype.focus = function (item, animate, animateCfg) {
    if (isString(item)) {
      item = this.graph.findById(item);
    }
    if (item) {
      var x = 0,
        y = 0;
      if (item.getType && item.getType() === 'edge') {
        var sourceMatrix = item.getSource().get('group').getMatrix();
        var targetMatrix = item.getTarget().get('group').getMatrix();
        if (sourceMatrix && targetMatrix) {
          x = (sourceMatrix[6] + targetMatrix[6]) / 2;
          y = (sourceMatrix[7] + targetMatrix[7]) / 2;
        } else if (sourceMatrix || targetMatrix) {
          x = sourceMatrix ? sourceMatrix[6] : targetMatrix[6];
          y = sourceMatrix ? sourceMatrix[7] : targetMatrix[7];
        }
      } else {
        var group = item.get('group');
        var matrix = group.getMatrix();
        if (!matrix) matrix = [1, 0, 0, 0, 1, 0, 0, 0, 1];
        x = matrix[6];
        y = matrix[7];
      }
      // 用实际位置而不是model中的x,y,防止由于拖拽等的交互导致model的x,y并不是当前的x,y
      this.focusPoint({
        x: x,
        y: y
      }, animate, animateCfg);
    }
  };
  ViewController.prototype.focusItems = function (items, zoomToFit, animate, animateCfg) {
    if (!items.length) {
      return;
    }
    var graph = this.graph;
    var padding = this.getFormatPadding();
    var width = graph.get('width');
    var height = graph.get('height');
    var group = graph.get('group');
    var startMatrix = group.getMatrix() || [1, 0, 0, 0, 1, 0, 0, 0, 1];
    group.resetMatrix();
    var bbox = {
      x: 0,
      y: 0,
      minX: Number.MAX_SAFE_INTEGER,
      minY: Number.MAX_SAFE_INTEGER,
      maxX: Number.MIN_SAFE_INTEGER,
      maxY: Number.MIN_SAFE_INTEGER,
      width: 0,
      height: 0
    };
    for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
      var item = items_1[_i];
      var itemBBox = item.getBBox();
      if (itemBBox.minX < bbox.minX) {
        bbox.minX = itemBBox.minX;
      }
      if (itemBBox.minY < bbox.minY) {
        bbox.minY = itemBBox.minY;
      }
      if (itemBBox.maxX > bbox.maxX) {
        bbox.maxX = itemBBox.maxX;
      }
      if (itemBBox.maxY > bbox.maxY) {
        bbox.maxY = itemBBox.maxY;
      }
    }
    bbox.x = bbox.minX;
    bbox.y = bbox.minY;
    bbox.width = bbox.maxX - bbox.minX;
    bbox.height = bbox.maxY - bbox.minY;
    if (bbox.width === 0 || bbox.height === 0) return;
    var viewCenter = this.getViewCenter();
    var groupCenter = {
      x: bbox.x + bbox.width / 2,
      y: bbox.y + bbox.height / 2
    };
    // Compute ratio
    var w = (width - padding[1] - padding[3]) / bbox.width;
    var h = (height - padding[0] - padding[2]) / bbox.height;
    var ratio = w;
    if (w > h) {
      ratio = h;
    }
    if (animate) {
      this.animatedFitView(group, startMatrix, animateCfg, bbox, viewCenter, groupCenter, ratio, zoomToFit);
    } else {
      graph.translate(viewCenter.x - groupCenter.x, viewCenter.y - groupCenter.y);
      if (zoomToFit && !graph.zoom(ratio, viewCenter)) {
        console.warn('zoom failed, ratio out of range, ratio: %f', ratio);
      }
    }
  };
  /**
   * 改变 canvas 画布的宽度和高度
   * @param width canvas 宽度
   * @param height canvas 高度
   */
  ViewController.prototype.changeSize = function (width, height) {
    var graph = this.graph;
    if (!isNumber(width) || !isNumber(height)) {
      throw Error('invalid canvas width & height, please make sure width & height type is number');
    }
    graph.set({
      width: width,
      height: height
    });
    var canvas = graph.get('canvas');
    canvas.changeSize(width, height);
    // change the size of grid plugin if it exists on graph
    var plugins = graph.get('plugins');
    plugins.forEach(function (plugin) {
      if (plugin.get('gridContainer')) {
        // 网格定位信息初始化
        plugin.positionInit();
      }
    });
  };
  ViewController.prototype.destroy = function () {
    this.graph = null;
    this.destroyed = false;
  };
  return ViewController;
}();
export default ViewController;