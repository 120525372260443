import Behavior from './behavior';
import AbstractGraph from './graph/graph';
import Shape, { Arrow, Marker } from './element';
import Global from './global';
import Util from './util';
// 用于 PC 和 Mobile 端分别实现 layout 和 updateLayoutCfg 方法
import AbstractLayout from './graph/controller/layout';
import AbstractEvent from './graph/controller/event';
import Node from './item/node';
import Edge from './item/edge';
import Hull from './item/hull';
import Combo from './item/combo';
var registerNode = Shape.registerNode;
var registerEdge = Shape.registerEdge;
var registerCombo = Shape.registerCombo;
var registerBehavior = Behavior.registerBehavior;
var BaseGlobal = Global;
export * from './types';
export { Arrow, Shape, Node, Edge, Combo, Hull, Marker, registerNode, registerCombo, AbstractGraph, Util, registerEdge, registerBehavior, AbstractLayout, AbstractEvent, BaseGlobal };
export default {
  version: Global.version,
  AbstractGraph: AbstractGraph,
  BaseGlobal: BaseGlobal,
  Util: Util,
  Shape: Shape,
  Node: Node,
  Edge: Edge,
  Combo: Combo,
  Hull: Hull,
  registerNode: Shape.registerNode,
  registerEdge: Shape.registerEdge,
  registerCombo: Shape.registerCombo,
  registerBehavior: Behavior.registerBehavior,
  Arrow: Arrow,
  Marker: Marker,
  AbstractLayout: AbstractLayout,
  AbstractEvent: AbstractEvent
};