import { __assign } from "tslib";
import { isArray, isNil, clone } from '@antv/util';
import Global from '../global';
import Shape from './shape';
import { shapeBase } from './shapeBase';
var singleCombo = {
  itemType: 'combo',
  // 单个图形的类型
  shapeType: 'single-combo',
  /**
   * Combo 标题文本相对图形的位置，默认为 top
   * 位置包括： top, bottom, left, right, center
   * @type {String}
   */
  labelPosition: 'top',
  /**
   * 标题文本相对偏移，当 labelPosition 不为 center 时有效
   * @type {Number}
   */
  refX: Global.comboLabel.refX,
  refY: Global.comboLabel.refY,
  options: {
    style: {
      stroke: Global.defaultCombo.style.stroke,
      fill: Global.defaultCombo.style.fill,
      lineWidth: Global.defaultCombo.style.lineWidth
    },
    labelCfg: {
      style: {
        fill: Global.comboLabel.style.fill,
        fontSize: Global.comboLabel.style.fontSize,
        fontFamily: Global.windowFontFamily
      }
    },
    stateStyles: __assign({}, Global.comboStateStyles),
    collapsedSubstituteIcon: {
      show: false,
      img: 'https://gw.alipayobjects.com/mdn/rms_f8c6a0/afts/img/A*RsnHRqLfJn4AAAAAAAAAAAAAARQnAQ'
    }
  },
  /**
   * 获取 Combo 宽高
   * @internal 返回 Combo 的大小，以 [width, height] 的方式维护
   * @param  {Object} cfg Combo 的配置项
   * @return {Array} 宽高
   */
  getSize: function getSize(cfg) {
    var size = clone(cfg.size || this.options.size || Global.defaultCombo.size);
    // size 是数组，若长度为 1，则补长度为 2
    if (isArray(size) && size.length === 1) {
      size = [size[0], size[0]];
    }
    // size 为数字，则转换为数组
    if (!isArray(size)) {
      size = [size, size];
    }
    return size;
  },
  // 私有方法，不希望扩展的 Combo 复写这个方法
  getLabelStyleByPosition: function getLabelStyleByPosition(cfg, labelCfg) {
    var labelPosition = labelCfg.position || this.labelPosition;
    var cfgStyle = cfg.style;
    var padding = cfg.padding || this.options.padding;
    if (isArray(padding)) padding = Math.max.apply(Math, padding);
    var refX = labelCfg.refX,
      refY = labelCfg.refY;
    // 考虑 refX 和 refY = 0 的场景，不用用 labelCfg.refX || Global.nodeLabel.refX
    if (isNil(refX)) {
      refX = this.refX; // 不居中时的偏移量
    }

    if (isNil(refY)) {
      refY = this.refY; // 不居中时的偏移量
    }

    var size = this.getSize(cfg);
    var r = Math.max(cfgStyle.r, size[0] / 2) || size[0] / 2;
    var dis = r + padding;
    var style;
    switch (labelPosition) {
      case 'top':
        style = {
          x: 0,
          y: -dis - refY,
          textBaseline: 'bottom',
          textAlign: 'center'
        };
        break;
      case 'bottom':
        style = {
          x: 0,
          y: dis + refY,
          textBaseline: 'bottom',
          textAlign: 'center'
        };
        break;
      case 'left':
        style = {
          x: -dis + refX,
          y: 0,
          textAlign: 'left'
        };
        break;
      case 'center':
        style = {
          x: 0,
          y: 0,
          text: cfg.label,
          textAlign: 'center'
        };
        break;
      default:
        style = {
          x: dis + refX,
          y: 0,
          textAlign: 'right'
        };
        break;
    }
    style.text = cfg.label;
    return style;
  },
  drawShape: function drawShape(cfg, group) {
    var shapeType = this.shapeType; // || this.type，都已经加了 shapeType
    var style = this.getShapeStyle(cfg);
    var shape = group.addShape(shapeType, {
      attrs: style,
      draggable: true,
      name: 'combo-shape'
    });
    return shape;
  },
  updateCollapsedIcon: function updateCollapsedIcon(cfg, item, keyShapeStyle) {
    var collapsed = cfg.collapsed,
      _a = cfg.collapsedSubstituteIcon,
      collapsedSubstituteIcon = _a === void 0 ? {} : _a;
    var substituteIconConfig = Object.assign({}, this.options.collapsedSubstituteIcon, collapsedSubstituteIcon);
    var show = substituteIconConfig.show,
      img = substituteIconConfig.img,
      width = substituteIconConfig.width,
      height = substituteIconConfig.height;
    var group = item.getContainer();
    var collapsedIconShape = group.find(function (ele) {
      return ele.get('name') === 'combo-collapsed-substitute-icon';
    });
    var iconShapeExist = collapsedIconShape && !collapsedIconShape.destroyed;
    var keyShape = item.get('keyShape');
    if (collapsed && show) {
      if (iconShapeExist) {
        collapsedIconShape.show();
      } else {
        var sizeAttr = {
          width: width || keyShapeStyle.r * 2 || keyShapeStyle.width,
          height: height || keyShapeStyle.r * 2 || keyShapeStyle.height
        };
        collapsedIconShape = group.addShape('image', {
          attrs: __assign({
            img: img,
            x: -sizeAttr.width / 2,
            y: -sizeAttr.height / 2
          }, sizeAttr),
          name: 'combo-collapsed-substitute-icon',
          draggable: true
        });
      }
      keyShape.hide();
    } else if (iconShapeExist) {
      collapsedIconShape.hide();
      keyShape.show();
    }
  },
  updateShape: function updateShape(cfg, item, keyShapeStyle) {
    var _this = this;
    var keyShape = item.get('keyShape');
    var itemAnimate = item.get('animate');
    var animate = itemAnimate && (cfg.animate === undefined ? this.options.animate : cfg.animate);
    if (animate && keyShape.animate) {
      // 更新到展开状态，先将 collapsedIcon 隐藏。否则在动画完成后再出现 collapsedIcon
      if (!cfg.collapsed) {
        this.updateCollapsedIcon(cfg, item, keyShapeStyle);
      }
      keyShape.animate(keyShapeStyle, {
        duration: 200,
        easing: 'easeLinear',
        callback: function callback() {
          if (cfg.collapsed) {
            _this.updateCollapsedIcon(cfg, item, keyShapeStyle);
          }
        }
      });
    } else {
      keyShape.attr(__assign({}, keyShapeStyle));
      this.updateCollapsedIcon(cfg, item, keyShapeStyle);
    }
    this.updateLabel(cfg, item);
    // special for some types of nodes
  }
};

var singleComboDef = __assign(__assign({}, shapeBase), singleCombo);
Shape.registerCombo('single-combo', singleComboDef);