function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import { __assign } from "tslib";
import Global from '../global';
import { ext } from '@antv/matrix-util';
import { deepMix, each, mix, isBoolean, isPlainObject, clone } from '@antv/util';
import { cloneBesidesImg } from '../util/graphic';
var transform = ext.transform;
var CLS_SHAPE_SUFFIX = '-shape';
var CLS_LABEL_SUFFIX = '-label';
var ARROWS = ['startArrow', 'endArrow'];
var SHAPE_DEFAULT_ATTRS = {
  lineWidth: 1,
  stroke: undefined,
  fill: undefined,
  lineAppendWidth: 1,
  opacity: undefined,
  strokeOpacity: undefined,
  fillOpacity: undefined,
  x: 0,
  y: 0,
  r: 10,
  width: 20,
  height: 20,
  shadowColor: undefined,
  shadowBlur: 0,
  shadowOffsetX: 0,
  shadowOffsetY: 0
};
var PATH_SHAPE_DEFAULT_ATTRS = {
  lineWidth: 1,
  stroke: '#000',
  lineDash: undefined,
  startArrow: false,
  endArrow: false,
  opacity: undefined,
  strokeOpacity: undefined,
  fillOpacity: undefined,
  shadowColor: undefined,
  shadowBlur: 0,
  shadowOffsetX: 0,
  shadowOffsetY: 0
};
var SHAPES_DEFAULT_ATTRS = {
  edge: PATH_SHAPE_DEFAULT_ATTRS,
  node: SHAPE_DEFAULT_ATTRS,
  combo: SHAPE_DEFAULT_ATTRS
};
export var CLS_LABEL_BG_SUFFIX = '-label-bg';
// 单个 shape 带有一个 label，共用这段代码
export var shapeBase = {
  // 默认样式及配置
  options: {
    labelCfg: {
      style: {
        fontFamily: Global.windowFontFamily
      }
    },
    descriptionCfg: {
      style: {
        fontFamily: Global.windowFontFamily
      }
    }
  },
  itemType: '',
  /**
   * 形状的类型，例如 circle，ellipse，polyline...
   */
  type: '',
  getCustomConfig: function getCustomConfig(cfg) {
    return {};
  },
  getOptions: function getOptions(cfg, updateType) {
    if (updateType === 'move' || (updateType === null || updateType === void 0 ? void 0 : updateType.includes('bbox'))) return cfg;
    return deepMix({}, this.options, this.getCustomConfig(cfg) || {}, cfg);
  },
  /**
   * 绘制节点/边，包含文本
   * @override
   * @param  {Object} cfg 节点的配置项
   * @param  {G.Group} group 节点的容器
   * @return {IShape} 绘制的图形
   */
  draw: function draw(cfg, group) {
    group['shapeMap'] = {};
    this.mergeStyle = this.getOptions(cfg);
    var shape = this.drawShape(cfg, group);
    shape.set('className', this.itemType + CLS_SHAPE_SUFFIX);
    group['shapeMap'][this.itemType + CLS_SHAPE_SUFFIX] = shape;
    if (cfg.label) {
      var label = this.drawLabel(cfg, group);
      label.set('className', this.itemType + CLS_LABEL_SUFFIX);
      group['shapeMap'][this.itemType + CLS_LABEL_SUFFIX] = label;
    }
    return shape;
  },
  /**
   * 绘制完成后的操作，便于用户继承现有的节点、边
   * @param cfg
   * @param group
   * @param keyShape
   */
  afterDraw: function afterDraw(cfg, group, keyShape) {},
  drawShape: function drawShape(cfg, group) {
    return null;
  },
  drawLabel: function drawLabel(cfg, group) {
    var defaultLabelCfg = (this.mergeStyle || this.getOptions(cfg) || {}).labelCfg;
    // image的情况下有可能为null
    var labelCfg = defaultLabelCfg || {};
    var labelStyle = this.getLabelStyle(cfg, labelCfg, group);
    var rotate = labelStyle.rotate;
    delete labelStyle.rotate;
    var label = group.addShape('text', {
      attrs: labelStyle,
      draggable: true,
      className: 'text-shape',
      name: 'text-shape',
      labelRelated: true
    });
    group['shapeMap']['text-shape'] = label;
    if (!isNaN(rotate) && rotate !== '') {
      var labelBBox = label.getBBox();
      var labelMatrix = [1, 0, 0, 0, 1, 0, 0, 0, 1];
      if (labelStyle.rotateCenter) {
        switch (labelStyle.rotateCenter) {
          case 'center':
            labelMatrix = transform(labelMatrix, [['t', -labelBBox.width / 2, -labelBBox.height / 2], ['r', rotate], ['t', labelBBox.width / 2, labelBBox.height / 2]]);
            break;
          case 'lefttop':
            labelMatrix = transform(labelMatrix, [['t', -labelStyle.x, -labelStyle.y], ['r', rotate], ['t', labelStyle.x, labelStyle.y]]);
            break;
          case 'leftcenter':
            labelMatrix = transform(labelMatrix, [['t', -labelStyle.x, -labelStyle.y - labelBBox.height / 2], ['r', rotate], ['t', labelStyle.x, labelStyle.y + labelBBox.height / 2]]);
            break;
          default:
            labelMatrix = transform(labelMatrix, [['t', -labelBBox.width / 2, -labelBBox.height / 2], ['r', rotate], ['t', labelBBox.width / 2, labelBBox.height / 2]]);
            break;
        }
      } else {
        labelMatrix = transform(labelMatrix, [['t', -labelStyle.x, -labelStyle.y - labelBBox.height / 2], ['r', rotate], ['t', labelStyle.x, labelStyle.y + labelBBox.height / 2]]);
      }
      label.setMatrix(labelMatrix);
    }
    if (labelStyle.background) {
      var rect = this.drawLabelBg(cfg, group, label);
      var labelBgClassname = this.itemType + CLS_LABEL_BG_SUFFIX;
      rect.set('classname', labelBgClassname);
      group['shapeMap'][labelBgClassname] = rect;
      label.toFront();
    }
    return label;
  },
  drawLabelBg: function drawLabelBg(cfg, group, label) {
    var defaultLabelCfg = this.options.labelCfg;
    var labelCfg = mix({}, defaultLabelCfg, cfg.labelCfg);
    var style = this.getLabelBgStyleByPosition(label, labelCfg);
    var rect = group.addShape('rect', {
      name: 'text-bg-shape',
      attrs: style,
      labelRelated: true
    });
    group['shapeMap']['text-bg-shape'] = rect;
    return rect;
  },
  getLabelStyleByPosition: function getLabelStyleByPosition(cfg, labelCfg, group) {
    return {
      text: cfg.label
    };
  },
  getLabelBgStyleByPosition: function getLabelBgStyleByPosition(label, labelCfg) {
    return {};
  },
  /**
   * 获取文本的配置项
   * @param cfg 节点的配置项
   * @param labelCfg 文本的配置项
   * @param group 父容器，label 的定位可能与图形相关
   */
  getLabelStyle: function getLabelStyle(cfg, labelCfg, group) {
    var calculateStyle = this.getLabelStyleByPosition(cfg, labelCfg, group);
    var attrName = "".concat(this.itemType, "Label"); // 取 nodeLabel，edgeLabel 的配置项
    var defaultStyle = Global[attrName] ? Global[attrName].style : null;
    return __assign(__assign(__assign({}, defaultStyle), calculateStyle), labelCfg.style);
  },
  /**
   * 获取图形的配置项
   * @param cfg
   */
  getShapeStyle: function getShapeStyle(cfg) {
    return cfg.style;
  },
  /**
   * 更新节点，包含文本
   * @override
   * @param  {Object} cfg 节点/边的配置项
   * @param  {G6.Item} item 节点/边
   */
  update: function update(cfg, item, updateType) {
    this.updateShapeStyle(cfg, item, updateType);
    this.updateLabel(cfg, item, updateType);
  },
  updateShapeStyle: function updateShapeStyle(cfg, item, updateType) {
    var _a;
    var group = item.getContainer();
    var shape = item.getKeyShape();
    var shapeStyle = mix({}, shape.attr(), cfg.style);
    var _loop_1 = function _loop_1(key) {
      var _b;
      var style = shapeStyle[key];
      if (isPlainObject(style)) {
        // 更新图元素样式，支持更新子元素
        var subShape = ((_a = group['shapeMap']) === null || _a === void 0 ? void 0 : _a[key]) || group.find(function (element) {
          return element.get('name') === key;
        });
        subShape === null || subShape === void 0 ? void 0 : subShape.attr(style);
      } else {
        shape.attr((_b = {}, _b[key] = style, _b));
      }
    };
    for (var key in shapeStyle) {
      _loop_1(key);
    }
  },
  updateLabel: function updateLabel(cfg, item, updateType) {
    var _a, _b;
    var group = item.getContainer();
    var _c = (this.mergeStyle || this.getOptions({}, updateType) || {}).labelCfg,
      labelCfg = _c === void 0 ? {} : _c;
    var labelClassName = this.itemType + CLS_LABEL_SUFFIX;
    var label = group['shapeMap'][labelClassName] || group.find(function (ele) {
      return ele.get('className') === labelClassName;
    });
    var labelBgClassname = this.itemType + CLS_LABEL_BG_SUFFIX;
    var labelBg = group['shapeMap'][labelBgClassname] || group.find(function (ele) {
      return ele.get('className') === labelBgClassname;
    });
    if (label && cfg.label === undefined) {
      group.removeChild(label);
      delete group['shapeMap'][labelClassName];
      if (labelBg) {
        group.removeChild(labelBg);
        delete group['shapeMap'][labelBgClassname];
      }
    }
    // 防止 cfg.label = "" 的情况
    if (cfg.label || cfg.label === '') {
      // 若传入的新配置中有 label，（用户没传入但原先有 label，label 也会有值）
      if (!label) {
        // 若原先不存在 label，则绘制一个新的 label
        var newLabel = this.drawLabel(cfg, group);
        newLabel.set('className', labelClassName);
        group['shapeMap'][labelClassName] = newLabel;
      } else {
        // 若原先存在 label，则更新样式。与 getLabelStyle 不同在于这里需要融合当前 label 的样式
        // 融合 style 以外的属性：position, offset, ...
        if (!updateType || updateType === 'bbox|label' || this.itemType === 'edge' && updateType !== 'style') {
          labelCfg = deepMix(labelCfg, cfg.labelCfg);
        }
        // 获取位置信息
        var calculateStyle = this.getLabelStyleByPosition(cfg, labelCfg, group);
        // 取 nodeLabel，edgeLabel 的配置项
        var cfgStyle = (_a = cfg.labelCfg) === null || _a === void 0 ? void 0 : _a.style;
        // const cfgBgStyle = labelCfg.style?.background;
        // 需要融合当前 label 的样式 label.attr()。不再需要全局/默认样式，因为已经应用在当前的 label 上
        var labelStyle = __assign(__assign({}, calculateStyle), cfgStyle);
        var rotate = labelStyle.rotate;
        delete labelStyle.rotate;
        // 计算 label 的旋转矩阵
        if (!isNaN(rotate) && rotate !== '') {
          // if G 4.x define the rotateAtStart, use it directly instead of using the following codes
          var rotateMatrix = [1, 0, 0, 0, 1, 0, 0, 0, 1];
          rotateMatrix = transform(rotateMatrix, [['t', -labelStyle.x, -labelStyle.y], ['r', rotate], ['t', labelStyle.x, labelStyle.y]]);
          labelStyle.matrix = rotateMatrix;
          label.attr(labelStyle);
        } else {
          if (((_b = label.getMatrix()) === null || _b === void 0 ? void 0 : _b[4]) !== 1) {
            label.resetMatrix();
          }
          label.attr(labelStyle);
        }
        if (!labelBg) {
          if (labelStyle.background) {
            labelBg = this.drawLabelBg(cfg, group, label);
            labelBg.set('classname', labelBgClassname);
            group['shapeMap'][labelBgClassname] = labelBg;
            label.toFront();
          }
        } else if (labelStyle.background) {
          var calculateBgStyle = this.getLabelBgStyleByPosition(label, labelCfg);
          labelBg.attr(calculateBgStyle);
        } else {
          group.removeChild(labelBg);
        }
      }
    }
  },
  // update(cfg, item) // 默认不定义
  afterUpdate: function afterUpdate(cfg, item) {},
  /**
   * 设置节点的状态，主要是交互状态，业务状态请在 draw 方法中实现
   * 单图形的节点仅考虑 selected、active 状态，有其他状态需求的用户自己复写这个方法
   * @override
   * @param  {String} name 状态名称
   * @param  {String | Boolean} value 状态值
   * @param  {G6.Item} item 节点
   */
  setState: function setState(name, value, item) {
    var _a, _b;
    var _c;
    var shape = item.get('keyShape');
    if (!shape || shape.destroyed) return;
    var type = item.getType();
    var stateName = isBoolean(value) ? name : "".concat(name, ":").concat(value);
    var shapeStateStyle = this.getStateStyle(stateName, item);
    var itemStateStyle = item.getStateStyle(stateName);
    // const originStyle = item.getOriginStyle();
    // 不允许设置一个不存在的状态
    if (!itemStateStyle && !shapeStateStyle) {
      return;
    }
    // 要设置或取消的状态的样式
    // 当没有 state 状态时，默认使用 model.stateStyles 中的样式
    var styles = mix({}, itemStateStyle || shapeStateStyle);
    var group = item.getContainer();
    // 从图元素现有的样式中删除本次要取消的 states 中存在的属性值。使用对象检索更快
    var keptAttrs = {
      x: 1,
      y: 1,
      cx: 1,
      cy: 1,
      matrix: 1
    };
    if (type === 'combo') {
      keptAttrs.r = 1;
      keptAttrs.width = 1;
      keptAttrs.height = 1;
    }
    if (value) {
      var _loop_2 = function _loop_2(key) {
        var _d;
        var style = styles[key];
        if (isPlainObject(style) && !ARROWS.includes(key)) {
          var subShape = ((_c = group['shapeMap']) === null || _c === void 0 ? void 0 : _c[key]) || group.find(function (element) {
            return element.get('name') === key;
          });
          subShape === null || subShape === void 0 ? void 0 : subShape.attr(style);
        } else {
          // 非纯对象，则认为是设置到 keyShape 上面的
          shape.attr((_d = {}, _d[key] = style, _d));
        }
      };
      // style 为要设置的状态的样式
      for (var key in styles) {
        _loop_2(key);
      }
    } else {
      // 所有生效的 state 的样式
      var enableStatesStyle = cloneBesidesImg(item.getCurrentStatesStyle());
      var model = item.getModel();
      // 原始样式
      var originStyle_1 = mix({}, model.style, cloneBesidesImg(item.getOriginStyle()));
      var keyShapeName_1 = shape.get('name');
      // cloning  shape.attr(), keys.forEach to avoid cloning the img attr, which leads to maximum clone heap #2383
      // const keyShapeStyles = clone(shape.attr())
      var shapeAttrs_1 = shape.attr();
      var keyShapeStyles_1 = {};
      Object.keys(shapeAttrs_1).forEach(function (key) {
        if (key === 'img') return;
        var attr = shapeAttrs_1[key];
        if (attr && _typeof(attr) === 'object') {
          keyShapeStyles_1[key] = clone(attr);
        } else {
          keyShapeStyles_1[key] = attr;
        }
      });
      // 已有样式 - 要取消的状态的样式
      var filtetDisableStatesStyle = {};
      var _loop_3 = function _loop_3(p) {
        var style = styles[p];
        if (isPlainObject(style) && !ARROWS.includes(p)) {
          var subShape_1 = group['shapeMap'][p] || group.find(function (ele) {
            return ele.get('name') === p;
          });
          if (subShape_1) {
            var subShapeStyles_1 = cloneBesidesImg(subShape_1.attr());
            each(style, function (v, key) {
              if (p === keyShapeName_1 && keyShapeStyles_1[key] && !keptAttrs[key]) {
                delete keyShapeStyles_1[key];
                var value_1 = originStyle_1[p][key] || SHAPES_DEFAULT_ATTRS[type][key];
                shape.attr(key, value_1);
              } else if (subShapeStyles_1[key] || subShapeStyles_1[key] === 0) {
                delete subShapeStyles_1[key];
                var value_2 = originStyle_1[p][key] || SHAPES_DEFAULT_ATTRS[type][key];
                subShape_1.attr(key, value_2);
              }
            });
            filtetDisableStatesStyle[p] = subShapeStyles_1;
          }
        } else {
          if (keyShapeStyles_1[p] && !keptAttrs[p]) {
            delete keyShapeStyles_1[p];
            var value_3 = originStyle_1[p] || (originStyle_1[keyShapeName_1] ? originStyle_1[keyShapeName_1][p] : undefined) || SHAPES_DEFAULT_ATTRS[type][p];
            shape.attr(p, value_3);
          }
        }
      };
      // styles 为要取消的状态的样式
      for (var p in styles) {
        _loop_3(p);
      }
      // 从图元素现有的样式中删除本次要取消的 states 中存在的属性值后，
      // 如果 keyShape 有 name 属性，则 filtetDisableStatesStyle 的格式为 { keyShapeName: {} }
      // 否则为普通对象
      if (!keyShapeName_1) {
        mix(filtetDisableStatesStyle, keyShapeStyles_1);
      } else {
        filtetDisableStatesStyle[keyShapeName_1] = keyShapeStyles_1;
      }
      for (var key in enableStatesStyle) {
        if (keptAttrs[key]) continue;
        var enableStyle = enableStatesStyle[key];
        if (!isPlainObject(enableStyle) || ARROWS.includes(key)) {
          // 把样式属性merge到keyShape中
          if (!keyShapeName_1) {
            mix(originStyle_1, (_a = {}, _a[key] = enableStyle, _a));
          } else {
            mix(originStyle_1[keyShapeName_1], (_b = {}, _b[key] = enableStyle, _b));
            delete originStyle_1[key];
          }
          delete enableStatesStyle[key];
        }
      }
      var originstyles = {};
      deepMix(originstyles, originStyle_1, filtetDisableStatesStyle, enableStatesStyle);
      var keyShapeSetted = false;
      var _loop_4 = function _loop_4(originKey) {
        var _e, _f;
        var style = originstyles[originKey];
        if (isPlainObject(style) && !ARROWS.includes(originKey)) {
          var subShape = group['shapeMap'][originKey] || group.find(function (ele) {
            return ele.get('name') === originKey;
          });
          if (subShape) {
            // The text's position and matrix is not allowed to be affected by states
            if (subShape.get('type') === 'text' || subShape.get('labelRelated')) {
              delete style.x;
              delete style.y;
              delete style.matrix;
            }
            if (originKey === keyShapeName_1) {
              if (type === 'combo') {
                delete style.r;
                delete style.width;
                delete style.height;
              }
              keyShapeSetted = true;
            }
            subShape.attr(style);
          }
        } else if (!keyShapeSetted) {
          var value_4 = style || SHAPES_DEFAULT_ATTRS[type][originKey];
          // 当更新 combo 状态时，当不存在 keyShapeName 时候，则认为是设置到 keyShape 上面的
          if (type === 'combo') {
            if (!keyShapeName_1) {
              shape.attr((_e = {}, _e[originKey] = value_4, _e));
            }
          } else {
            shape.attr((_f = {}, _f[originKey] = value_4, _f));
          }
        }
      };
      for (var originKey in originstyles) {
        _loop_4(originKey);
      }
    }
  },
  /**
   * 获取不同状态下的样式
   *
   * @param {string} name 状态名称
   * @param {Item} item Node或Edge的实例
   * @return {object} 样式
   */
  getStateStyle: function getStateStyle(name, item) {
    var model = item.getModel();
    var type = item.getType();
    var _a = this.getOptions(model),
      stateStyles = _a.stateStyles,
      _b = _a.style,
      style = _b === void 0 ? {} : _b;
    var modelStateStyle = model.stateStyles ? model.stateStyles[name] : stateStyles && stateStyles[name];
    if (type === 'combo') {
      return clone(modelStateStyle);
    }
    return mix({}, style, modelStateStyle);
  },
  /**
   * 获取控制点
   * @param  {Object} cfg 节点、边的配置项
   * @return {Array|null} 控制点的数组,如果为 null，则没有控制点
   */
  getControlPoints: function getControlPoints(cfg) {
    return cfg.controlPoints;
  },
  /**
   * 获取控制点
   * @param  {Object} cfg 节点、边的配置项
   * @return {Array|null} 锚点的数组,如果为 null，则没有锚点
   */
  getAnchorPoints: function getAnchorPoints(cfg) {
    var _a, _b;
    var anchorPoints = (cfg === null || cfg === void 0 ? void 0 : cfg.anchorPoints) || ((_a = this.getCustomConfig(cfg)) === null || _a === void 0 ? void 0 : _a.anchorPoints) || ((_b = this.options) === null || _b === void 0 ? void 0 : _b.anchorPoints);
    return anchorPoints;
  }
};