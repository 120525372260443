var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import { modifyCSS, createDom } from '@antv/dom-util';
import { isArray, isString } from '@antv/util';
import insertCss from 'insert-css';
import Base from '../base';
typeof document !== 'undefined' && insertCss("\n  .g6-component-tooltip {\n    border: 1px solid #e2e2e2;\n    border-radius: 4px;\n    font-size: 12px;\n    color: #545454;\n    background-color: rgba(255, 255, 255, 0.9);\n    padding: 10px 8px;\n    box-shadow: rgb(174, 174, 174) 0px 0px 10px;\n  }\n  .tooltip-type {\n    padding: 0;\n    margin: 0;\n  }\n  .tooltip-id {\n    color: #531dab;\n  }\n");
var Tooltip = /** @class */function (_super) {
  __extends(Tooltip, _super);
  function Tooltip(config) {
    return _super.call(this, config) || this;
  }
  Tooltip.prototype.getDefaultCfgs = function () {
    return {
      offsetX: 6,
      offsetY: 6,
      // 指定菜单内容，function(e) {...}
      getContent: function getContent(e) {
        return "\n          <h4 class='tooltip-type'>\u7C7B\u578B\uFF1A".concat(e.item.getType(), "</h4>\n          <span class='tooltip-id'>ID\uFF1A").concat(e.item.getID(), "</span>\n        ");
      },
      shouldBegin: function shouldBegin(e) {
        return true;
      },
      itemTypes: ['node', 'edge', 'combo'],
      trigger: 'mouseenter',
      fixToNode: undefined
    };
  };
  // class-methods-use-this
  Tooltip.prototype.getEvents = function () {
    if (this.get('trigger') === 'click') {
      return {
        'node:click': 'onClick',
        'edge:click': 'onClick',
        'combo:click': 'onClick',
        'canvas:click': 'onMouseLeave',
        afterremoveitem: 'onMouseLeave',
        contextmenu: 'onMouseLeave',
        drag: 'onMouseLeave'
      };
    }
    return {
      'node:mouseenter': 'onMouseEnter',
      'node:mouseleave': 'onMouseLeave',
      'node:mousemove': 'onMouseMove',
      'edge:mouseenter': 'onMouseEnter',
      'edge:mouseleave': 'onMouseLeave',
      'edge:mousemove': 'onMouseMove',
      'combo:mouseenter': 'onMouseEnter',
      'combo:mouseleave': 'onMouseLeave',
      'combo:mousemove': 'onMouseMove',
      afterremoveitem: 'onMouseLeave',
      contextmenu: 'onMouseLeave',
      'node:drag': 'onMouseLeave'
    };
  };
  Tooltip.prototype.init = function () {
    var self = this;
    var className = self.get('className') || 'g6-component-tooltip';
    var tooltip = createDom("<div class='".concat(className, "'></div>"));
    var container = self.get('container');
    if (!container) {
      container = self.get('graph').get('container');
    }
    if (isString(container)) {
      container = document.getElementById(container);
    }
    modifyCSS(tooltip, {
      position: 'absolute',
      visibility: 'hidden',
      display: 'none'
    });
    container.appendChild(tooltip);
    if (self.get('trigger') !== 'click') {
      tooltip.addEventListener('mouseenter', function (e) {
        modifyCSS(tooltip, {
          visibility: 'visible',
          display: 'unset'
        });
      });
      tooltip.addEventListener('mouseleave', function (e) {
        self.hideTooltip();
      });
    }
    self.set('tooltip', tooltip);
  };
  Tooltip.prototype.onClick = function (e) {
    var itemTypes = this.get('itemTypes');
    if (e.item && e.item.getType && itemTypes.indexOf(e.item.getType()) === -1) return;
    var item = e.item;
    var graph = this.get('graph');
    // 若与上一次同一 item，隐藏该 tooltip
    if (this.currentTarget === item) {
      this.currentTarget = null;
      this.hideTooltip();
      graph.emit('tooltipchange', {
        item: e.item,
        action: 'hide'
      });
    } else {
      this.currentTarget = item;
      this.showTooltip(e);
      graph.emit('tooltipchange', {
        item: e.item,
        action: 'show'
      });
    }
  };
  Tooltip.prototype.onMouseEnter = function (e) {
    var itemTypes = this.get('itemTypes');
    if (e.item && e.item.getType && itemTypes.indexOf(e.item.getType()) === -1) return;
    var item = e.item;
    var graph = this.get('graph');
    this.currentTarget = item;
    this.showTooltip(e);
    graph.emit('tooltipchange', {
      item: e.item,
      action: 'show'
    });
  };
  Tooltip.prototype.onMouseMove = function (e) {
    var itemTypes = this.get('itemTypes');
    if (e.item && e.item.getType && itemTypes.indexOf(e.item.getType()) === -1) return;
    if (!this.currentTarget || e.item !== this.currentTarget) {
      return;
    }
    this.showTooltip(e);
  };
  Tooltip.prototype.onMouseLeave = function () {
    this.hideTooltip();
    var graph = this.get('graph');
    graph.emit('tooltipchange', {
      item: this.currentTarget,
      action: 'hide'
    });
    this.currentTarget = null;
  };
  Tooltip.prototype.clearContainer = function () {
    var container = this.get('tooltip');
    if (container) {
      container.innerHTML = '';
    }
  };
  Tooltip.prototype.showTooltip = function (e) {
    if (!e.item) {
      return;
    }
    var itemTypes = this.get('itemTypes');
    if (e.item.getType && itemTypes.indexOf(e.item.getType()) === -1) return;
    var container = this.get('tooltip');
    var getContent = this.get('getContent');
    var tooltip = getContent(e);
    if (isString(tooltip)) {
      container.innerHTML = tooltip;
    } else {
      this.clearContainer();
      container.appendChild(tooltip);
    }
    this.updatePosition(e);
  };
  Tooltip.prototype.hideTooltip = function () {
    var tooltip = this.get('tooltip');
    if (tooltip) {
      modifyCSS(tooltip, {
        visibility: 'hidden',
        display: 'none'
      });
    }
  };
  Tooltip.prototype.updatePosition = function (e) {
    var shouldBegin = this.get('shouldBegin');
    var tooltip = this.get('tooltip');
    if (!shouldBegin(e)) {
      modifyCSS(tooltip, {
        visibility: 'hidden',
        display: 'none'
      });
      return;
    }
    var graph = this.get('graph');
    var width = graph.get('width');
    var height = graph.get('height');
    var offsetX = this.get('offsetX') || 0;
    var offsetY = this.get('offsetY') || 0;
    var point = graph.getPointByClient(e.clientX, e.clientY);
    var fixToNode = this.get('fixToNode');
    var item = e.item;
    if (item.getType && item.getType() === 'node' && fixToNode && isArray(fixToNode) && fixToNode.length >= 2) {
      var itemBBox = item.getBBox();
      point = {
        x: itemBBox.minX + itemBBox.width * fixToNode[0],
        y: itemBBox.minY + itemBBox.height * fixToNode[1]
      };
    }
    var _a = graph.getCanvasByPoint(point.x, point.y),
      x = _a.x,
      y = _a.y;
    var graphContainer = graph.getContainer();
    var res = {
      x: x + graphContainer.offsetLeft + offsetX,
      y: y + graphContainer.offsetTop + offsetY
    };
    // 先修改为 visible 方可正确计算 bbox
    modifyCSS(tooltip, {
      visibility: 'visible',
      display: 'unset'
    });
    var bbox = tooltip.getBoundingClientRect();
    if (x + bbox.width + offsetX > width) {
      res.x -= bbox.width + offsetX;
    }
    if (y + bbox.height + offsetY > height) {
      res.y -= bbox.height + offsetY;
      if (res.y < 0) {
        res.y = 0;
      }
    }
    modifyCSS(tooltip, {
      left: "".concat(res.x, "px"),
      top: "".concat(res.y, "px")
    });
  };
  Tooltip.prototype.hide = function () {
    this.onMouseLeave();
  };
  Tooltip.prototype.destroy = function () {
    var tooltip = this.get('tooltip');
    if (tooltip) {
      var container = this.get('container');
      if (!container) {
        container = this.get('graph').get('container');
      }
      if (isString(container)) {
        container = document.getElementById(container);
      }
      container.removeChild(tooltip);
    }
  };
  return Tooltip;
}(Base);
export default Tooltip;