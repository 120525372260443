import { __assign, __rest } from "tslib";
import { mix, isString, deepMix } from '@antv/util';
import { registerNode, BaseGlobal as Global } from '@antv/g6-core';
registerNode('modelRect', {
  // 自定义节点时的配置
  options: {
    size: [185, 70],
    style: {
      radius: 5,
      stroke: '#69c0ff',
      fill: '#ffffff',
      lineWidth: Global.defaultNode.style.lineWidth,
      fillOpacity: 1
    },
    // 文本样式配置
    labelCfg: {
      style: {
        fill: '#595959',
        fontSize: 14,
        fontFamily: Global.windowFontFamily
      },
      offset: 30 // 距离左侧的 offset，没有设置 y 轴上移动的配置
    },

    descriptionCfg: {
      style: {
        fontSize: 12,
        fill: '#bfbfbf',
        fontFamily: Global.windowFontFamily
      },
      paddingTop: 0
    },
    preRect: {
      show: true,
      width: 4,
      fill: '#40a9ff',
      radius: 2
    },
    // 节点上左右上下四个方向上的链接circle配置
    linkPoints: {
      top: false,
      right: false,
      bottom: false,
      left: false,
      // circle的大小
      size: 10,
      lineWidth: 1,
      fill: '#72CC4A',
      stroke: '#72CC4A'
    },
    // 节点中icon配置
    logoIcon: {
      // 是否显示icon，值为 false 则不渲染icon
      show: true,
      x: 0,
      y: 0,
      // icon的地址，字符串类型
      img: 'https://gw.alipayobjects.com/zos/basement_prod/4f81893c-1806-4de4-aff3-9a6b266bc8a2.svg',
      width: 16,
      height: 16,
      // 用于调整图标的左右位置
      offset: 0
    },
    // 节点中表示状态的icon配置
    stateIcon: {
      // 是否显示icon，值为 false 则不渲染icon
      show: true,
      x: 0,
      y: 0,
      // icon的地址，字符串类型
      img: 'https://gw.alipayobjects.com/zos/basement_prod/300a2523-67e0-4cbf-9d4a-67c077b40395.svg',
      width: 16,
      height: 16,
      // 用于调整图标的左右位置
      offset: -5
    },
    // 连接点，默认为左右
    // anchorPoints: [{ x: 0, y: 0.5 }, { x: 1, y: 0.5 }]
    anchorPoints: [[0, 0.5], [1, 0.5]]
  },
  shapeType: 'modelRect',
  drawShape: function drawShape(cfg, group) {
    var _a = (this.mergeStyle || this.getOptions(cfg)).preRect,
      preRect = _a === void 0 ? {} : _a;
    var style = this.getShapeStyle(cfg);
    var size = this.getSize(cfg);
    var width = size[0];
    var height = size[1];
    var keyShape = group.addShape('rect', {
      attrs: style,
      className: "".concat(this.type, "-keyShape"),
      name: "".concat(this.type, "-keyShape"),
      draggable: true
    });
    group['shapeMap']["".concat(this.type, "-keyShape")] = keyShape;
    var preRectShow = preRect.show,
      preRectStyle = __rest(preRect, ["show"]);
    if (preRectShow) {
      group['shapeMap']['pre-rect'] = group.addShape('rect', {
        attrs: __assign({
          x: -width / 2,
          y: -height / 2,
          height: height
        }, preRectStyle),
        className: 'pre-rect',
        name: 'pre-rect',
        draggable: true
      });
    }
    this.drawLogoIcon(cfg, group);
    this.drawStateIcon(cfg, group);
    this.drawLinkPoints(cfg, group);
    return keyShape;
  },
  /**
   * 绘制模型矩形左边的logo图标
   * @param {Object} cfg 数据配置项
   * @param {Group} group Group实例
   */
  drawLogoIcon: function drawLogoIcon(cfg, group) {
    var _a = (this.mergeStyle || this.getOptions(cfg)).logoIcon,
      logoIcon = _a === void 0 ? {} : _a;
    var size = this.getSize(cfg);
    var width = size[0];
    if (logoIcon.show) {
      var w = logoIcon.width,
        h = logoIcon.height,
        x = logoIcon.x,
        y = logoIcon.y,
        offset = logoIcon.offset,
        text = logoIcon.text,
        logoIconStyle = __rest(logoIcon, ["width", "height", "x", "y", "offset", "text"]);
      if (text) {
        group['shapeMap']['rect-logo-icon'] = group.addShape('text', {
          attrs: __assign({
            x: 0,
            y: 0,
            fontSize: 12,
            fill: '#000',
            stroke: '#000',
            textBaseline: 'middle',
            textAlign: 'center'
          }, logoIconStyle),
          className: 'rect-logo-icon',
          name: 'rect-logo-icon',
          draggable: true
        });
      } else {
        group['shapeMap']['rect-logo-icon'] = group.addShape('image', {
          attrs: __assign(__assign({}, logoIconStyle), {
            x: x || -width / 2 + w + offset,
            y: y || -h / 2,
            width: w,
            height: h
          }),
          className: 'rect-logo-icon',
          name: 'rect-logo-icon',
          draggable: true
        });
      }
    }
  },
  /**
   * 绘制模型矩形右边的状态图标
   * @param {Object} cfg 数据配置项
   * @param {Group} group Group实例
   */
  drawStateIcon: function drawStateIcon(cfg, group) {
    var _a = (this.mergeStyle || this.getOptions(cfg)).stateIcon,
      stateIcon = _a === void 0 ? {} : _a;
    var size = this.getSize(cfg);
    var width = size[0];
    if (stateIcon.show) {
      var w = stateIcon.width,
        h = stateIcon.height,
        x = stateIcon.x,
        y = stateIcon.y,
        offset = stateIcon.offset,
        text = stateIcon.text,
        iconStyle = __rest(stateIcon, ["width", "height", "x", "y", "offset", "text"]);
      if (text) {
        group['shapeMap']['rect-state-icon'] = group.addShape('text', {
          attrs: __assign({
            x: 0,
            y: 0,
            fontSize: 12,
            fill: '#000',
            stroke: '#000',
            textBaseline: 'middle',
            textAlign: 'center'
          }, iconStyle),
          className: 'rect-state-icon',
          name: 'rect-state-icon',
          draggable: true
        });
      } else {
        group['shapeMap']['rect-state-icon'] = group.addShape('image', {
          attrs: __assign(__assign({}, iconStyle), {
            x: x || width / 2 - w + offset,
            y: y || -h / 2,
            width: w,
            height: h
          }),
          className: 'rect-state-icon',
          name: 'rect-state-icon',
          draggable: true
        });
      }
    }
  },
  /**
   * 绘制节点上的LinkPoints
   * @param {Object} cfg data数据配置项
   * @param {Group} group Group实例
   */
  drawLinkPoints: function drawLinkPoints(cfg, group) {
    var _a = (this.mergeStyle || this.getOptions(cfg)).linkPoints,
      linkPoints = _a === void 0 ? {} : _a;
    var top = linkPoints.top,
      left = linkPoints.left,
      right = linkPoints.right,
      bottom = linkPoints.bottom,
      markSize = linkPoints.size,
      markR = linkPoints.r,
      markStyle = __rest(linkPoints, ["top", "left", "right", "bottom", "size", "r"]);
    var size = this.getSize(cfg);
    var width = size[0];
    var height = size[1];
    if (left) {
      // left circle
      group['shapeMap']['link-point-left'] = group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: -width / 2,
          y: 0,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-left',
        name: 'link-point-left',
        isAnchorPoint: true
      });
    }
    if (right) {
      // right circle
      group['shapeMap']['link-point-right'] = group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: width / 2,
          y: 0,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-right',
        name: 'link-point-right',
        isAnchorPoint: true
      });
    }
    if (top) {
      // top circle
      group['shapeMap']['link-point-top'] = group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: 0,
          y: -height / 2,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-top',
        name: 'link-point-top',
        isAnchorPoint: true
      });
    }
    if (bottom) {
      // bottom circle
      group['shapeMap']['link-point-bottom'] = group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: 0,
          y: height / 2,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-bottom',
        name: 'link-point-bottom',
        isAnchorPoint: true
      });
    }
  },
  drawLabel: function drawLabel(cfg, group) {
    var _a = this.getOptions(cfg),
      _b = _a.labelCfg,
      labelCfg = _b === void 0 ? {} : _b,
      _c = _a.logoIcon,
      logoIcon = _c === void 0 ? {} : _c,
      _d = _a.descriptionCfg,
      descriptionCfg = _d === void 0 ? {} : _d;
    var size = this.getSize(cfg);
    var width = size[0];
    var label = null;
    var show = logoIcon.show,
      w = logoIcon.width;
    var offsetX = -width / 2 + labelCfg.offset;
    if (show) {
      offsetX = -width / 2 + w + labelCfg.offset;
    }
    var fontStyle = labelCfg.style;
    var descriptionStyle = descriptionCfg.style,
      descriptionPaddingTop = descriptionCfg.paddingTop;
    if (isString(cfg.description)) {
      label = group.addShape('text', {
        attrs: __assign(__assign({}, fontStyle), {
          x: offsetX,
          y: -5,
          text: cfg.label
        }),
        className: 'text-shape',
        name: 'text-shape',
        draggable: true,
        labelRelated: true
      });
      group['shapeMap']['text-shape'] = label;
      group['shapeMap']['rect-description'] = group.addShape('text', {
        attrs: __assign(__assign({}, descriptionStyle), {
          x: offsetX,
          y: 17 + (descriptionPaddingTop || 0),
          text: cfg.description
        }),
        className: 'rect-description',
        name: 'rect-description',
        draggable: true,
        labelRelated: true
      });
    } else {
      label = group.addShape('text', {
        attrs: __assign(__assign({}, fontStyle), {
          x: offsetX,
          y: 7,
          text: cfg.label
        }),
        className: 'text-shape',
        name: 'text-shape',
        draggable: true,
        labelRelated: true
      });
      group['shapeMap']['text-shape'] = label;
    }
    return label;
  },
  /**
   * 获取节点的样式，供基于该节点自定义时使用
   * @param {Object} cfg 节点数据模型
   * @return {Object} 节点的样式
   */
  getShapeStyle: function getShapeStyle(cfg) {
    var defaultStyle = (this.mergeStyle || this.getOptions(cfg)).style;
    var strokeStyle = {
      stroke: cfg.color
    };
    // 如果设置了color，则覆盖默认的stroke属性
    var style = mix({}, defaultStyle, strokeStyle);
    var size = this.getSize(cfg);
    var width = style.width || size[0];
    var height = style.height || size[1];
    var styles = __assign({
      x: -width / 2,
      y: -height / 2,
      width: width,
      height: height
    }, style);
    return styles;
  },
  update: function update(cfg, item) {
    var _a = this.mergeStyle || this.getOptions(cfg),
      _b = _a.style,
      style = _b === void 0 ? {} : _b,
      _c = _a.labelCfg,
      labelCfg = _c === void 0 ? {} : _c,
      _d = _a.descriptionCfg,
      descriptionCfg = _d === void 0 ? {} : _d;
    var size = this.getSize(cfg);
    var width = size[0];
    var height = size[1];
    var keyShape = item.get('keyShape');
    keyShape.attr(__assign(__assign({}, style), {
      x: -width / 2,
      y: -height / 2,
      width: width,
      height: height
    }));
    var group = item.getContainer();
    var logoIconShape = group['shapeMap']['rect-logo-icon'] || group.find(function (element) {
      return element.get('className') === 'rect-logo-icon';
    });
    var currentLogoIconAttr = logoIconShape ? logoIconShape.attr() : {};
    var logoIcon = mix({}, currentLogoIconAttr, cfg.logoIcon);
    var w = logoIcon.width;
    if (w === undefined) {
      w = this.options.logoIcon.width;
    }
    var show = cfg.logoIcon ? cfg.logoIcon.show : undefined;
    var offset = labelCfg.offset;
    var offsetX = -width / 2 + w + offset;
    if (!show && show !== undefined) {
      offsetX = -width / 2 + offset;
    }
    var label = group['shapeMap']['node-label'] || group.find(function (element) {
      return element.get('className') === 'node-label';
    });
    var description = group['shapeMap']['rect-description'] || group.find(function (element) {
      return element.get('className') === 'rect-description';
    });
    if (cfg.label) {
      if (!label) {
        group['shapeMap']['node-label'] = group.addShape('text', {
          attrs: __assign(__assign({}, labelCfg.style), {
            x: offsetX,
            y: cfg.description ? -5 : 7,
            text: cfg.label
          }),
          className: 'node-label',
          name: 'node-label',
          draggable: true,
          labelRelated: true
        });
      } else {
        var cfgStyle = cfg.labelCfg ? cfg.labelCfg.style : {};
        var labelStyle = mix({}, label.attr(), cfgStyle);
        if (cfg.label) labelStyle.text = cfg.label;
        labelStyle.x = offsetX;
        if (isString(cfg.description)) labelStyle.y = -5;
        if (description) {
          description.resetMatrix();
          description.attr({
            x: offsetX
          });
        }
        label.resetMatrix();
        label.attr(labelStyle);
      }
    }
    if (isString(cfg.description)) {
      var paddingTop = descriptionCfg.paddingTop;
      if (!description) {
        group['shapeMap']['rect-description'] = group.addShape('text', {
          attrs: __assign(__assign({}, descriptionCfg.style), {
            x: offsetX,
            y: 17 + (paddingTop || 0),
            text: cfg.description
          }),
          className: 'rect-description',
          name: 'rect-description',
          draggable: true,
          labelRelated: true
        });
      } else {
        var cfgStyle = cfg.descriptionCfg ? cfg.descriptionCfg.style : {};
        var descriptionStyle = mix({}, description.attr(), cfgStyle);
        if (isString(cfg.description)) descriptionStyle.text = cfg.description;
        descriptionStyle.x = offsetX;
        description.resetMatrix();
        description.attr(__assign(__assign({}, descriptionStyle), {
          y: 17 + (paddingTop || 0)
        }));
      }
    }
    var preRectShape = group['shapeMap']['pre-rect'] || group.find(function (element) {
      return element.get('className') === 'pre-rect';
    });
    if (preRectShape && !preRectShape.destroyed) {
      var preRect = mix({}, preRectShape.attr(), cfg.preRect);
      preRectShape.attr(__assign(__assign({}, preRect), {
        x: -width / 2,
        y: -height / 2,
        height: height
      }));
    }
    if (logoIconShape && !logoIconShape.destroyed) {
      if (!show && show !== undefined) {
        logoIconShape.remove();
        delete group['shapeMap']['pre-rect'];
      } else {
        var logoW = logoIcon.width,
          h = logoIcon.height,
          x = logoIcon.x,
          y = logoIcon.y,
          logoOffset = logoIcon.offset,
          logoIconStyle = __rest(logoIcon, ["width", "height", "x", "y", "offset"]);
        logoIconShape.attr(__assign(__assign({}, logoIconStyle), {
          x: x || -width / 2 + logoW + logoOffset,
          y: y || -h / 2,
          width: logoW,
          height: h
        }));
      }
    } else if (show) {
      this.drawLogoIcon(cfg, group);
    }
    var stateIconShape = group['shapeMap']['rect-state-icon'] || group.find(function (element) {
      return element.get('className') === 'rect-state-icon';
    });
    var currentStateIconAttr = stateIconShape ? stateIconShape.attr() : {};
    var stateIcon = mix({}, currentStateIconAttr, cfg.stateIcon);
    if (stateIconShape) {
      if (!stateIcon.show && stateIcon.show !== undefined) {
        stateIconShape.remove();
        delete group['shapeMap']['rect-state-icon'];
      }
      var stateW = stateIcon.width,
        h = stateIcon.height,
        x = stateIcon.x,
        y = stateIcon.y,
        stateOffset = stateIcon.offset,
        stateIconStyle = __rest(stateIcon, ["width", "height", "x", "y", "offset"]);
      stateIconShape.attr(__assign(__assign({}, stateIconStyle), {
        x: x || width / 2 - stateW + stateOffset,
        y: y || -h / 2,
        width: stateW,
        height: h
      }));
    } else if (stateIcon.show) {
      this.drawStateIcon(cfg, group);
    }
    this.updateLinkPoints(cfg, group);
  },
  getOptions: function getOptions(cfg, updateType) {
    if (updateType === 'move') return cfg;
    // different from baseShape, the config should be mixed when the updateType is not 'move'
    return deepMix({}, this.options, this.getCustomConfig(cfg) || {}, cfg);
  }
}, 'single-node');