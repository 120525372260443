import { __assign, __awaiter, __generator, __rest } from "tslib";
import { isFunction, groupBy } from '@antv/util';
import { isNaN, calculationItemsBBox } from '../../util/base';
import { isForce } from '../../util/layout';
var LayoutController = /** @class */function () {
  function LayoutController(graph) {
    this.graph = graph;
    this.layoutCfg = graph.get('layout') || {};
    this.layoutType = this.getLayoutType();
    this.layoutMethods = [];
    this.initLayout();
  }
  // eslint-disable-next-line class-methods-use-this
  LayoutController.prototype.initLayout = function () {
    // no data before rendering
  };
  LayoutController.prototype.getLayoutType = function () {
    return this.getLayoutCfgType(this.layoutCfg);
  };
  LayoutController.prototype.getLayoutCfgType = function (layoutCfg) {
    var type = layoutCfg.type;
    // type should be top priority
    if (type) {
      return type;
    }
    var pipes = layoutCfg.pipes;
    if (Array.isArray(pipes)) {
      return pipes.map(function (pipe) {
        return (pipe === null || pipe === void 0 ? void 0 : pipe.type) || '';
      });
    }
    return null;
  };
  LayoutController.prototype.isLayoutTypeSame = function (cfg) {
    var current = this.getLayoutCfgType(cfg);
    var preHasPipes = Array.isArray(this.layoutType);
    var currentHasPipes = Array.isArray(current);
    // already has pipes, and the new one is pipes
    if (preHasPipes && currentHasPipes) {
      return this.layoutType.every(function (type, index) {
        return type === current[index];
      });
    }
    // only one of the pre and current is pipes
    if (Array.isArray(current) || Array.isArray(this.layoutType)) {
      return false;
    }
    // both of the pre and current are not pipes
    return (cfg === null || cfg === void 0 ? void 0 : cfg.type) === this.layoutType;
  };
  // 绘制
  LayoutController.prototype.refreshLayout = function () {
    var _a = this,
      graph = _a.graph,
      layoutType = _a.layoutType,
      _b = _a.layoutCfg,
      layoutCfg = _b === void 0 ? {} : _b;
    if (!graph) return;
    var animate = layoutCfg.animate;
    var isDefaultAnimateLayout = animate === undefined && (layoutType === 'force' || layoutType === 'force2');
    var forceAnimate = isForce(layoutType) && (animate || isDefaultAnimateLayout);
    if (graph.get('animate') && !forceAnimate) {
      graph.positionsAnimate(layoutType === 'comboCombined');
    } else {
      graph.refreshPositions(layoutType === 'comboCombined');
    }
  };
  // 更换布局
  LayoutController.prototype.changeLayout = function (cfg) {
    var disableTriggerLayout = cfg.disableTriggerLayout,
      otherCfgs = __rest(cfg, ["disableTriggerLayout"]);
    this.layoutCfg = otherCfgs;
    this.layoutType = otherCfgs.type || this.layoutType;
    // 不触发重新布局，仅更新参数
    if (disableTriggerLayout) return;
    this.layout();
  };
  // 更换数据
  LayoutController.prototype.changeData = function (success) {
    this.layout(success);
  };
  LayoutController.prototype.destoryLayoutMethods = function () {
    var layoutMethods = this.layoutMethods;
    var destroyedLayoutTypes = [];
    layoutMethods === null || layoutMethods === void 0 ? void 0 : layoutMethods.forEach(function (layoutMethod) {
      var _a;
      var layoutType = (_a = layoutMethod.getType) === null || _a === void 0 ? void 0 : _a.call(layoutMethod);
      if (layoutType) destroyedLayoutTypes.push(layoutType);
      layoutMethod.destroy();
    });
    this.layoutMethods = [];
    return destroyedLayoutTypes;
  };
  // 销毁布局，不能使用 this.destroy，因为 controller 还需要被使用，只是把布局算法销毁
  LayoutController.prototype.destroyLayout = function () {
    this.destoryLayoutMethods();
    var graph = this.graph;
    if (graph && !graph.get('destroyed')) {
      graph.set('layout', undefined);
    }
    this.layoutCfg = undefined;
    this.layoutType = undefined;
    this.layoutMethods = undefined;
  };
  // 从 this.graph 获取数据
  LayoutController.prototype.setDataFromGraph = function () {
    var _a;
    var nodes = [];
    var hiddenNodes = [];
    var edges = [];
    var hiddenEdges = [];
    var comboEdges = [];
    var combos = [];
    var hiddenCombos = [];
    var nodeItems = this.graph.getNodes();
    var edgeItems = this.graph.getEdges();
    var comboItems = this.graph.getCombos();
    var nodeLength = nodeItems.length;
    for (var i = 0; i < nodeLength; i++) {
      var nodeItem = nodeItems[i];
      if (!nodeItem || nodeItem.destroyed) continue;
      var model = nodeItem.getModel();
      if (!nodeItem.isVisible()) {
        hiddenNodes.push(model);
        continue;
      }
      nodes.push(model);
    }
    var edgeLength = edgeItems.length;
    for (var i = 0; i < edgeLength; i++) {
      var edgeItem = edgeItems[i];
      if (!edgeItem || edgeItem.destroyed) continue;
      var model = edgeItem.getModel();
      if (!edgeItem.isVisible()) {
        hiddenEdges.push(model);
        continue;
      }
      if (!model.isComboEdge) edges.push(model);else comboEdges.push(model);
    }
    var comboLength = comboItems.length;
    for (var i = 0; i < comboLength; i++) {
      var comboItem = comboItems[i];
      if (comboItem.destroyed) continue;
      var model = comboItem.getModel();
      if (!comboItem.isVisible()) {
        hiddenCombos.push(model);
        continue;
      }
      combos.push(model);
    }
    return {
      nodes: nodes,
      hiddenNodes: hiddenNodes,
      edges: edges,
      hiddenEdges: hiddenEdges,
      combos: combos,
      hiddenCombos: hiddenCombos,
      comboEdges: comboEdges,
      vedges: (_a = this.graph.get('vedges')) === null || _a === void 0 ? void 0 : _a.map(function (edge) {
        return edge.getModel();
      })
    };
  };
  // 重新布局
  LayoutController.prototype.relayout = function (reloadData) {
    var _this = this;
    var _a = this,
      graph = _a.graph,
      layoutMethods = _a.layoutMethods,
      layoutCfg = _a.layoutCfg;
    if (!graph || graph.get('destroyed')) return;
    var start = Promise.resolve();
    if (reloadData) {
      this.data = this.setDataFromGraph();
      var nodes = this.data.nodes;
      if (!nodes) {
        return false;
      }
      start = this.initPositions(layoutCfg.center, nodes);
    }
    graph.emit('beforelayout');
    layoutMethods === null || layoutMethods === void 0 ? void 0 : layoutMethods.forEach(function (layoutMethod, index) {
      var currentCfg = layoutCfg[index] || layoutCfg;
      start = start.then(function () {
        var _a;
        var relayoutPromise = _this.execLayoutMethod(currentCfg, index);
        if (index === layoutMethods.length - 1) {
          (_a = layoutCfg.onAllLayoutEnd) === null || _a === void 0 ? void 0 : _a.call(layoutCfg);
        }
        return relayoutPromise;
      });
    });
  };
  // 筛选参与布局的nodes和edges
  LayoutController.prototype.filterLayoutData = function (data, cfg) {
    var nodes = data.nodes,
      edges = data.edges,
      rest = __rest(data, ["nodes", "edges"]);
    if (!nodes) {
      return data;
    }
    var nodesFilter;
    var edegsFilter;
    if (isFunction(cfg === null || cfg === void 0 ? void 0 : cfg.nodesFilter)) {
      nodesFilter = cfg.nodesFilter;
    } else {
      nodesFilter = function nodesFilter() {
        return true;
      };
    }
    var fNodes = nodes.filter(nodesFilter);
    if (isFunction(cfg === null || cfg === void 0 ? void 0 : cfg.edgesFilter)) {
      edegsFilter = cfg.edgesFilter;
    } else {
      var nodesMap_1 = fNodes.reduce(function (acc, cur) {
        acc[cur.id] = true;
        return acc;
      }, {});
      edegsFilter = function edegsFilter(edge) {
        return nodesMap_1[edge.source] && nodesMap_1[edge.target];
      };
    }
    return __assign({
      nodes: fNodes,
      edges: edges.filter(edegsFilter)
    }, rest);
  };
  LayoutController.prototype.getLayoutBBox = function (nodes) {
    var graph = this.graph;
    var graphGroupNodes = groupBy(graph.getNodes(), function (n) {
      return n.getModel().layoutOrder;
    });
    var layoutNodes = Object.values(graphGroupNodes).map(function (value) {
      var bbox = calculationItemsBBox(value);
      bbox.size = [bbox.width, bbox.height];
      return bbox;
    });
    var groupNodes = Object.values(groupBy(nodes, 'layoutOrder'));
    return {
      groupNodes: groupNodes,
      layoutNodes: layoutNodes
    };
  };
  // 控制布局动画
  // eslint-disable-next-line class-methods-use-this
  LayoutController.prototype.layoutAnimate = function () {};
  // 将当前节点的平均中心移动到原点
  LayoutController.prototype.moveToZero = function () {
    var graph = this.graph;
    var data = graph.get('data');
    var nodes = data.nodes;
    if (nodes[0].x === undefined || nodes[0].x === null || isNaN(nodes[0].x)) {
      return;
    }
    var meanCenter = [0, 0];
    var nodeLength = nodes.length;
    for (var i = 0; i < nodeLength; i++) {
      var node = nodes[i];
      meanCenter[0] += node.x;
      meanCenter[1] += node.y;
    }
    meanCenter[0] /= nodes.length;
    meanCenter[1] /= nodes.length;
    for (var i = 0; i < nodeLength; i++) {
      var node = nodes[i];
      node.x -= meanCenter[0];
      node.y -= meanCenter[1];
    }
  };
  // 初始化节点到 center 附近
  LayoutController.prototype.initPositions = function (center, nodes) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var graph, nodesToInit, nodeLength;
      return __generator(this, function (_b) {
        graph = this.graph;
        if (!(nodes === null || nodes === void 0 ? void 0 : nodes.length)) {
          return [2 /*return*/, Promise.resolve()];
        }
        nodesToInit = nodes.filter(function (node) {
          return isNaN(node.x) || isNaN(node.y);
        });
        nodeLength = nodesToInit ? nodesToInit.length : 0;
        if (!nodeLength) return [2 /*return*/];
        return [2 /*return*/, (_a = this.initWithPreset) === null || _a === void 0 ? void 0 : _a.call(this, function () {},
        // onFullfillment
        function () {
          var width = graph.get('width') * 0.85;
          var height = graph.get('height') * 0.85;
          var horiNum = Math.ceil(Math.sqrt(nodeLength) * (width / height));
          var vertiNum = Math.ceil(nodeLength / horiNum);
          var horiGap = width / (horiNum - 1);
          var vertiGap = height / (vertiNum - 1);
          if (!isFinite(horiGap) || !horiGap) horiGap = 0;
          if (!isFinite(vertiGap) || !horiGap) vertiGap = 0;
          var beginX = center[0] - width / 2;
          var beginY = center[1] - height / 2;
          var allHavePos = true;
          for (var i = 0; i < nodeLength; i++) {
            var node = nodesToInit[i];
            if (isNaN(+node.x)) {
              allHavePos = false;
              node.x = i % horiNum * horiGap + beginX;
            }
            if (isNaN(+node.y)) {
              allHavePos = false;
              node.y = Math.floor(i / horiNum) * vertiGap + beginY;
            }
          }
        })];
      });
    });
  };
  LayoutController.prototype.destroy = function () {
    this.graph = null;
    this.destoryLayoutMethods();
    this.destroyed = true;
  };
  return LayoutController;
}();
export default LayoutController;