var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import { modifyCSS, createDom } from '@antv/dom-util';
import Base from '../base';
// 网格背景图片
var GRID_PNG = 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDEwIEwgNDAgMTAgTSAxMCAwIEwgMTAgNDAgTSAwIDIwIEwgNDAgMjAgTSAyMCAwIEwgMjAgNDAgTSAwIDMwIEwgNDAgMzAgTSAzMCAwIEwgMzAgNDAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2UwZTBlMCIgb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjEiLz48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZTBlMGUwIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=)';
var Grid = /** @class */function (_super) {
  __extends(Grid, _super);
  function Grid(config) {
    return _super.call(this, config) || this;
  }
  Grid.prototype.getDefaultCfgs = function () {
    return {
      img: GRID_PNG,
      follow: true
    };
  };
  Grid.prototype.init = function () {
    var graph = this.get('graph');
    var graphContainer = graph.get('container');
    var canvas = graph.get('canvas').get('el');
    var img = this.get('img') || GRID_PNG;
    if (!img.includes('url(')) {
      img = "url(\"".concat(img, "\")");
    }
    var container = createDom("<div class='g6-grid-container' style=\"position:absolute;overflow:hidden;z-index: -1;\"></div>");
    var gridContainer = createDom("<div\n        class='g6-grid'\n        style='position:absolute;\n        background-image: ".concat(img, ";\n        user-select: none\n        '></div>"));
    this.set('container', container);
    this.set('gridContainer', gridContainer);
    this.positionInit();
    container.appendChild(gridContainer);
    graphContainer.insertBefore(container, canvas);
  };
  /** 定位信息初始化 */
  Grid.prototype.positionInit = function () {
    var graph = this.get('graph');
    var minZoom = graph.get('minZoom');
    var width = graph.get('width');
    var height = graph.get('height');
    modifyCSS(this.get('container'), {
      width: "".concat(width, "px"),
      height: "".concat(height, "px")
    });
    // 网格 40*40 需保证 (gridContainerWidth / 2) % 40 = 0 才能让网格线对齐左上角 故 * 80
    var gridContainerWidth = width * 80 / minZoom;
    var gridContainerHeight = height * 80 / minZoom;
    modifyCSS(this.get('gridContainer'), {
      width: "".concat(gridContainerWidth, "px"),
      height: "".concat(gridContainerHeight, "px"),
      left: "-".concat(gridContainerWidth / 2, "px"),
      top: "-".concat(gridContainerHeight / 2, "px")
    });
  };
  // class-methods-use-this
  Grid.prototype.getEvents = function () {
    return {
      viewportchange: 'updateGrid'
    };
  };
  /**
   * viewport change 事件的响应函数
   * @param param
   */
  Grid.prototype.updateGrid = function (param) {
    var gridContainer = this.get('gridContainer');
    var matrix = param.matrix;
    if (!matrix) matrix = [1, 0, 0, 0, 1, 0, 0, 0, 1];
    var isFollow = this.get('follow');
    var transform = "matrix(".concat(matrix[0], ", ").concat(matrix[1], ", ").concat(matrix[3], ", ").concat(matrix[4], ", ").concat(isFollow ? matrix[6] : '0', ", ").concat(isFollow ? matrix[7] : '0', ")");
    modifyCSS(gridContainer, {
      transform: transform
    });
  };
  Grid.prototype.getContainer = function () {
    return this.get('container');
  };
  Grid.prototype.destroy = function () {
    var graph = this.get('graph');
    var graphContainer = graph.get('container');
    var container = this.get('container');
    graphContainer.removeChild(container);
  };
  return Grid;
}(Base);
export default Grid;