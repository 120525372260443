import { isString } from '@antv/util';
var StateController = /** @class */function () {
  function StateController(graph) {
    this.graph = graph;
    this.destroyed = false;
  }
  /**
   * 更新 Item 的状态
   *
   * @param {Item} item Item实例
   * @param {string} state 状态名称
   * @param {boolean} enabled 状态是否可用
   * @memberof State
   */
  StateController.prototype.updateState = function (item, state, enabled) {
    var graphStates = this.graph.get('states');
    var key = state;
    if (isString(enabled)) key = "".concat(state, ":").concat(enabled);
    if (!graphStates[key]) graphStates[key] = [];
    if (enabled) graphStates[key].push(item);else graphStates[key] = graphStates[key].filter(function (itemInState) {
      return itemInState !== item;
    });
    this.graph.set('states', graphStates);
    this.graph.emit('graphstatechange', {
      states: graphStates
    });
  };
  /**
   * 批量更新 states，兼容 updateState，支持更新一个 state
   *
   * @param {Item} item
   * @param {(string | string[])} states
   * @param {boolean} enabled
   * @memberof State
   */
  StateController.prototype.updateStates = function (item, states, enabled) {
    var graphStates = this.graph.get('states');
    var stateNames = isString(states) ? [states] : states;
    stateNames.forEach(function (stateName) {
      var key = stateName;
      if (!graphStates[key]) graphStates[key] = [];
      if (isString(enabled)) key = "".concat(stateName, ":").concat(enabled);
      if (enabled) graphStates[key].push(item);else graphStates[key] = graphStates[key].filter(function (itemInState) {
        return itemInState !== item;
      });
    });
    this.graph.set('states', graphStates);
    this.graph.emit('graphstatechange', {
      states: states
    });
  };
  StateController.prototype.destroy = function () {
    this.graph = null;
    this.destroyed = true;
  };
  return StateController;
}();
export default StateController;