import { __assign } from "tslib";
import { isArray, isNil, mix } from '@antv/util';
import { formatPadding } from '../util/base';
import Global from '../global';
import Shape from './shape';
import { shapeBase } from './shapeBase';
import { truncateLabelByLength } from '../util/graphic';
var singleNode = {
  itemType: 'node',
  // 单个图形的类型
  shapeType: 'single-node',
  /**
   * 文本相对图形的位置，默认以中心点
   * 位置包括： top, bottom, left, right, center
   * @type {String}
   */
  labelPosition: 'center',
  /**
   * 文本相对偏移，当 labelPosition 不为 center 时有效
   * @type {Number}
   */
  offset: Global.nodeLabel.offset,
  /**
   * 获取节点宽高
   * @internal 返回节点的大小，以 [width, height] 的方式维护
   * @param  {Object} cfg 节点的配置项
   * @return {Array} 宽高
   */
  getSize: function getSize(cfg) {
    var _a;
    var size = ((_a = this.mergeStyle) === null || _a === void 0 ? void 0 : _a.size) || cfg.size || this.getOptions({}).size || Global.defaultNode.size; // Global.defaultNode.size; //  
    // size 是数组，但长度为1，则补长度为2
    if (isArray(size) && size.length === 1) {
      size = [size[0], size[0]];
    }
    // size 为数字，则转换为数组
    if (!isArray(size)) {
      size = [size, size];
    }
    return size;
  },
  // 私有方法，不希望扩展的节点复写这个方法
  getLabelStyleByPosition: function getLabelStyleByPosition(cfg, labelCfg) {
    var labelMaxLength = labelCfg.maxLength;
    var text = cfg.label;
    if (labelMaxLength) {
      text = truncateLabelByLength(text, labelMaxLength);
    }
    var labelPosition = labelCfg.position || this.labelPosition;
    // 默认的位置（最可能的情形），所以放在最上面
    if (labelPosition === 'center') {
      return {
        x: 0,
        y: 0,
        text: text,
        textBaseline: 'middle',
        textAlign: 'center'
      };
    }
    var offset = labelCfg.offset;
    if (isNil(offset)) {
      // 考虑 offset = 0 的场景，不用用 labelCfg.offset || Global.nodeLabel.offset
      offset = this.offset; // 不居中时的偏移量
    }

    var size = this.getSize(cfg);
    var style;
    switch (labelPosition) {
      case 'top':
        style = {
          x: 0,
          y: -size[1] / 2 - offset,
          textBaseline: 'bottom',
          textAlign: 'center'
        };
        break;
      case 'bottom':
        style = {
          x: 0,
          y: size[1] / 2 + offset,
          textBaseline: 'top',
          textAlign: 'center'
        };
        break;
      case 'left':
        style = {
          x: -size[0] / 2 - offset,
          y: 0,
          textBaseline: 'middle',
          textAlign: 'right'
        };
        break;
      default:
        style = {
          x: size[0] / 2 + offset,
          y: 0,
          textBaseline: 'middle',
          textAlign: 'left'
        };
        break;
    }
    style.text = text;
    return style;
  },
  getLabelBgStyleByPosition: function getLabelBgStyleByPosition(label, labelCfg) {
    var _a;
    if (!label) return {};
    var backgroundStyle = (_a = labelCfg.style) === null || _a === void 0 ? void 0 : _a.background;
    if (!backgroundStyle) return {};
    var bbox = label.getBBox();
    var padding = formatPadding(backgroundStyle.padding);
    var backgroundWidth = bbox.width + padding[1] + padding[3];
    var backgroundHeight = bbox.height + padding[0] + padding[2];
    return __assign(__assign({
      x: bbox.minX - padding[3],
      y: bbox.minY - padding[0]
    }, backgroundStyle), {
      width: backgroundWidth,
      height: backgroundHeight
    });
  },
  drawShape: function drawShape(cfg, group) {
    var shapeType = this.shapeType; // || this.type，都已经加了 shapeType
    var style = this.getShapeStyle(cfg);
    var shape = group.addShape(shapeType, {
      attrs: style,
      draggable: true,
      name: 'node-shape'
    });
    group['shapeMap']['node-shape'] = shape;
    return shape;
  },
  /**
   * 更新linkPoints
   * @param {Object} cfg 节点数据配置项
   * @param {Group} group Item所在的group
   */
  updateLinkPoints: function updateLinkPoints(cfg, group) {
    var defaultLinkPoints = (this.mergeStyle || this.getOptions(cfg)).linkPoints;
    var markLeft = group['shapeMap']['link-point-left'] || group.find(function (element) {
      return element.get('className') === 'link-point-left';
    });
    var markRight = group['shapeMap']['link-point-right'] || group.find(function (element) {
      return element.get('className') === 'link-point-right';
    });
    var markTop = group['shapeMap']['link-point-top'] || group.find(function (element) {
      return element.get('className') === 'link-point-top';
    });
    var markBottom = group['shapeMap']['link-point-bottom'] || group.find(function (element) {
      return element.get('className') === 'link-point-bottom';
    });
    var currentLinkPoints;
    if (markLeft) {
      currentLinkPoints = markLeft.attr();
    }
    if (markRight && !currentLinkPoints) {
      currentLinkPoints = markRight.attr();
    }
    if (markTop && !currentLinkPoints) {
      currentLinkPoints = markTop.attr();
    }
    if (markBottom && !currentLinkPoints) {
      currentLinkPoints = markBottom.attr();
    }
    if (!currentLinkPoints) currentLinkPoints = defaultLinkPoints;
    var linkPoints = mix({}, currentLinkPoints, cfg.linkPoints);
    var markFill = linkPoints.fill,
      markStroke = linkPoints.stroke,
      borderWidth = linkPoints.lineWidth;
    var markSize = linkPoints.size / 2;
    if (!markSize) markSize = linkPoints.r;
    var _a = cfg.linkPoints ? cfg.linkPoints : {
        left: undefined,
        right: undefined,
        top: undefined,
        bottom: undefined
      },
      left = _a.left,
      right = _a.right,
      top = _a.top,
      bottom = _a.bottom;
    var size = this.getSize(cfg);
    var width = size[0];
    var height = size[1];
    var styles = {
      r: markSize,
      fill: markFill,
      stroke: markStroke,
      lineWidth: borderWidth
    };
    if (markLeft) {
      if (!left && left !== undefined) {
        markLeft.remove();
        delete group['shapeMap']['link-point-left'];
      } else {
        markLeft.attr(__assign(__assign({}, styles), {
          x: -width / 2,
          y: 0
        }));
      }
    } else if (left) {
      var name_1 = 'link-point-left';
      group['shapeMap'][name_1] = group.addShape('circle', {
        attrs: __assign(__assign({}, styles), {
          x: -width / 2,
          y: 0
        }),
        className: name_1,
        name: name_1,
        isAnchorPoint: true
      });
    }
    if (markRight) {
      if (!right && right !== undefined) {
        markRight.remove();
        delete group['shapeMap']['link-point-right'];
      }
      markRight.attr(__assign(__assign({}, styles), {
        x: width / 2,
        y: 0
      }));
    } else if (right) {
      var name_2 = 'link-point-right';
      group['shapeMap'][name_2] = group.addShape('circle', {
        attrs: __assign(__assign({}, styles), {
          x: width / 2,
          y: 0
        }),
        className: name_2,
        name: name_2,
        isAnchorPoint: true
      });
    }
    if (markTop) {
      if (!top && top !== undefined) {
        markTop.remove();
        delete group['shapeMap']['link-point-top'];
      }
      markTop.attr(__assign(__assign({}, styles), {
        x: 0,
        y: -height / 2
      }));
    } else if (top) {
      var name_3 = 'link-point-top';
      group['shapeMap'][name_3] = group.addShape('circle', {
        attrs: __assign(__assign({}, styles), {
          x: 0,
          y: -height / 2
        }),
        className: name_3,
        name: name_3,
        isAnchorPoint: true
      });
    }
    if (markBottom) {
      if (!bottom && bottom !== undefined) {
        markBottom.remove();
        delete group['shapeMap']['link-point-bottom'];
      } else {
        markBottom.attr(__assign(__assign({}, styles), {
          x: 0,
          y: height / 2
        }));
      }
    } else if (bottom) {
      var name_4 = 'link-point-bottom';
      group['shapeMap'][name_4] = group.addShape('circle', {
        attrs: __assign(__assign({}, styles), {
          x: 0,
          y: height / 2
        }),
        className: name_4,
        name: name_4,
        isAnchorPoint: true
      });
    }
  },
  updateShape: function updateShape(cfg, item, keyShapeStyle, hasIcon, updateType) {
    var keyShape = item.get('keyShape');
    keyShape.attr(__assign({}, keyShapeStyle));
    if (!undefined || (updateType === null || updateType === void 0 ? void 0 : updateType.includes('label'))) {
      this.updateLabel(cfg, item, updateType);
    }
    if (hasIcon) {
      this.updateIcon(cfg, item);
    }
  },
  updateIcon: function updateIcon(cfg, item) {
    var _this = this;
    var group = item.getContainer();
    var icon = (this.mergeStyle || this.getOptions(cfg)).icon;
    var _a = cfg.icon ? cfg.icon : {
        show: undefined,
        text: undefined
      },
      show = _a.show,
      text = _a.text;
    var iconShape = group['shapeMap']["".concat(this.type, "-icon")] || group.find(function (ele) {
      return ele.get('name') === "".concat(_this.type, "-icon");
    });
    if (iconShape) {
      // 若原先存在 icon
      if (show || show === undefined) {
        // 若传入 show: true, 或没有设置，则更新原有的 icon 样式
        var iconConfig = mix({}, iconShape.attr(), icon);
        var _b = iconConfig.width,
          w = _b === void 0 ? 20 : _b,
          _c = iconConfig.height,
          h = _c === void 0 ? 20 : _c;
        if (iconConfig.fontFamily === 'iconfont' || iconConfig.hasOwnProperty('text')) {
          w = 0;
          h = 0;
        }
        iconShape.attr(__assign(__assign({}, iconConfig), {
          x: -w / 2,
          y: -h / 2
        }));
      } else {
        // 若传入了 show: false 则删除原先的 icon
        iconShape.remove();
        delete group['shapeMap']["".concat(this.type, "-icon")];
      }
    } else if (show) {
      // 如果原先不存在 icon，但传入了 show: true，则新增 icon
      var name_5 = "".concat(this.type, "-icon");
      if (text) {
        group['shapeMap'][name_5] = group.addShape('text', {
          attrs: __assign({
            x: 0,
            y: 0,
            fontSize: 12,
            fill: '#000',
            stroke: '#000',
            textBaseline: 'middle',
            textAlign: 'center'
          }, icon),
          className: name_5,
          name: name_5
        });
      } else {
        var w = icon.width,
          h = icon.height;
        group['shapeMap'][name_5] = group.addShape('image', {
          attrs: __assign(__assign({}, icon), {
            x: -w / 2,
            y: -h / 2
          }),
          className: name_5,
          name: name_5
        });
      }
      // to ensure the label is on the top of all the shapes
      var labelShape = group['shapeMap']['node-label'] || group.find(function (ele) {
        return ele.get('name') === 'node-label';
      });
      if (labelShape) {
        labelShape.toFront();
      }
    }
  }
};
var singleNodeDef = __assign(__assign({}, shapeBase), singleNode);
Shape.registerNode('single-node', singleNodeDef);