import { __extends } from "tslib";
import { each, isNil, isNumber } from '@antv/util';
import { distance, getCircleIntersectByPoint, getEllipseIntersectByPoint, getRectIntersectByPoint } from '../util/math';
import Item from './item';
var CACHE_ANCHOR_POINTS = 'anchorPointsCache';
var CACHE_BBOX = 'bboxCache';
var Node = /** @class */function (_super) {
  __extends(Node, _super);
  function Node() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Node.prototype.getNearestPoint = function (points, curPoint) {
    var index = 0;
    var nearestPoint = points[0];
    var minDistance = distance(points[0], curPoint);
    for (var i = 0; i < points.length; i++) {
      var point = points[i];
      var dis = distance(point, curPoint);
      if (dis < minDistance) {
        nearestPoint = point;
        minDistance = dis;
        index = i;
      }
    }
    nearestPoint.anchorIndex = index;
    return nearestPoint;
  };
  Node.prototype.getDefaultCfg = function () {
    return {
      type: 'node',
      edges: []
    };
  };
  /**
   * 获取从节点关联的所有边
   */
  Node.prototype.getEdges = function () {
    return this.get('edges');
  };
  /**
   * 获取所有的入边
   */
  Node.prototype.getInEdges = function () {
    var self = this;
    return this.get('edges').filter(function (edge) {
      return edge.get('target') === self;
    });
  };
  /**
   * 获取所有的出边
   */
  Node.prototype.getOutEdges = function () {
    var self = this;
    return this.get('edges').filter(function (edge) {
      return edge.get('source') === self;
    });
  };
  /**
   * 获取节点的邻居节点
   *
   * @returns {INode[]}
   * @memberof Node
   */
  Node.prototype.getNeighbors = function (type) {
    var _this = this;
    var edges = this.get('edges');
    if (type === 'target') {
      // 当前节点为 source，它所指向的目标节点
      var neighborsConverter_1 = function neighborsConverter_1(edge) {
        return edge.getSource() === _this;
      };
      return edges.filter(neighborsConverter_1).map(function (edge) {
        return edge.getTarget();
      });
    }
    if (type === 'source') {
      // 当前节点为 target，它所指向的源节点
      var neighborsConverter_2 = function neighborsConverter_2(edge) {
        return edge.getTarget() === _this;
      };
      return edges.filter(neighborsConverter_2).map(function (edge) {
        return edge.getSource();
      });
    }
    // 若未指定 type ，则返回所有邻居
    var neighborsConverter = function neighborsConverter(edge) {
      return edge.getSource() === _this ? edge.getTarget() : edge.getSource();
    };
    return edges.map(neighborsConverter);
  };
  /**
   * 根据锚点的索引获取连接点
   * @param  {Number} index 索引
   */
  Node.prototype.getLinkPointByAnchor = function (index) {
    var anchorPoints = this.getAnchorPoints();
    return anchorPoints[index];
  };
  /**
   * 获取连接点
   * @param point
   */
  Node.prototype.getLinkPoint = function (point) {
    var keyShape = this.get('keyShape');
    var type = keyShape.get('type');
    var itemType = this.get('type');
    var centerX;
    var centerY;
    var bbox = this.getBBox();
    if (itemType === 'combo') {
      centerX = bbox.centerX || (bbox.maxX + bbox.minX) / 2;
      centerY = bbox.centerY || (bbox.maxY + bbox.minY) / 2;
    } else {
      centerX = bbox.centerX;
      centerY = bbox.centerY;
    }
    var anchorPoints = this.getAnchorPoints();
    var intersectPoint;
    switch (type) {
      case 'circle':
        intersectPoint = getCircleIntersectByPoint({
          x: centerX,
          y: centerY,
          r: bbox.width / 2
        }, point);
        break;
      case 'ellipse':
        intersectPoint = getEllipseIntersectByPoint({
          x: centerX,
          y: centerY,
          rx: bbox.width / 2,
          ry: bbox.height / 2
        }, point);
        break;
      default:
        intersectPoint = getRectIntersectByPoint(bbox, point);
    }
    var linkPoint = intersectPoint;
    // 如果存在锚点，则使用交点计算最近的锚点
    if (anchorPoints.length) {
      if (!linkPoint) {
        // 如果计算不出交点
        linkPoint = point;
      }
      linkPoint = this.getNearestPoint(anchorPoints, linkPoint);
    }
    if (!linkPoint) {
      // 如果最终依然没法找到锚点和连接点，直接返回中心点
      linkPoint = {
        x: centerX,
        y: centerY
      };
    }
    return linkPoint;
  };
  /**
   * 获取锚点的定义
   * @return {array} anchorPoints
   */
  Node.prototype.getAnchorPoints = function () {
    var anchorPoints = this.get(CACHE_ANCHOR_POINTS);
    if (!anchorPoints) {
      anchorPoints = [];
      var shapeFactory = this.get('shapeFactory');
      var bbox_1 = this.getBBox();
      var model = this.get('model');
      var shapeCfg = this.getShapeCfg(model);
      var type = model.type;
      var points = shapeFactory.getAnchorPoints(type, shapeCfg) || [];
      each(points, function (pointArr, index) {
        var point = {
          x: bbox_1.minX + pointArr[0] * bbox_1.width,
          y: bbox_1.minY + pointArr[1] * bbox_1.height,
          anchorIndex: index
        };
        anchorPoints.push(point);
      });
      this.set(CACHE_ANCHOR_POINTS, anchorPoints);
    }
    return anchorPoints;
  };
  /**
   * add edge
   * @param edge Edge instance
   */
  Node.prototype.addEdge = function (edge) {
    this.get('edges').push(edge);
  };
  /**
   * 锁定节点
   */
  Node.prototype.lock = function () {
    this.set('locked', true);
  };
  /**
   * 解锁锁定的节点
   */
  Node.prototype.unlock = function () {
    this.set('locked', false);
  };
  Node.prototype.hasLocked = function () {
    return this.get('locked');
  };
  /**
   * 移除边
   * @param {Edge} edge 边
   */
  Node.prototype.removeEdge = function (edge) {
    var edges = this.getEdges();
    var index = edges.indexOf(edge);
    if (index > -1) edges.splice(index, 1);
  };
  Node.prototype.clearCache = function () {
    this.set(CACHE_BBOX, null); // 清理缓存的 bbox
    this.set(CACHE_ANCHOR_POINTS, null);
  };
  /**
   * 判断更新的种类，move 表示仅移动，bbox 表示大小有变化，style 表示仅与大小无关的参数变化
   * @param cfg 节点数据模型
   */
  Node.prototype.getUpdateType = function (cfg) {
    var _a, _b, _c, _d, _e;
    if (!cfg) return undefined;
    var existX = !isNil(cfg.x);
    var existY = !isNil(cfg.y);
    var keys = Object.keys(cfg);
    // 仅有一个字段，包含 x 或者 包含 y
    // 两个字段，同时有 x，同时有 y
    if (keys.length === 1 && (existX || existY) || keys.length === 2 && existX && existY) return 'move';
    if (isNumber(cfg.x) || isNumber(cfg.y) || cfg.type || cfg.anchorPoints || cfg.size || (cfg === null || cfg === void 0 ? void 0 : cfg.style) && (((_a = cfg === null || cfg === void 0 ? void 0 : cfg.style) === null || _a === void 0 ? void 0 : _a.r) || ((_b = cfg === null || cfg === void 0 ? void 0 : cfg.style) === null || _b === void 0 ? void 0 : _b.width) || ((_c = cfg === null || cfg === void 0 ? void 0 : cfg.style) === null || _c === void 0 ? void 0 : _c.height) || ((_d = cfg === null || cfg === void 0 ? void 0 : cfg.style) === null || _d === void 0 ? void 0 : _d.rx) || ((_e = cfg === null || cfg === void 0 ? void 0 : cfg.style) === null || _e === void 0 ? void 0 : _e.ry))) return 'bbox|label';
    var updateLabel = keys.includes('label') || keys.includes('labelCfg');
    return updateLabel ? 'style|label' : 'style';
  };
  Node.prototype.setState = function (state, value) {
    var _this = this;
    if (this.optimize) {
      _super.prototype.setState.call(this, state, value);
      return;
    }
    this.runWithBBoxAffected(function () {
      return _super.prototype.setState.call(_this, state, value);
    });
  };
  Node.prototype.clearStates = function (states) {
    var _this = this;
    if (this.optimize) {
      _super.prototype.clearStates.call(this, states);
      return;
    }
    this.runWithBBoxAffected(function () {
      return _super.prototype.clearStates.call(_this, states);
    });
  };
  Node.prototype.runWithBBoxAffected = function (fn) {
    var bboxAffectedStyleKeys = ['r', 'width', 'height', 'rx', 'ry', 'lineWidth'];
    var beforeAttrs = this.getKeyShape().attr();
    var bboxAffectedStyleBefore = {};
    Object.keys(this.getKeyShape().attr()).forEach(function (key) {
      if (bboxAffectedStyleKeys.includes(key)) bboxAffectedStyleBefore[key] = beforeAttrs[key];
    });
    fn();
    // if the state styles affect the bbox, the bbox cache should be cleared to get correct edge connecting points
    var afterAttrs = this.getKeyShape().attr();
    for (var i = 0; i < bboxAffectedStyleKeys.length; i++) {
      var key = bboxAffectedStyleKeys[i];
      if (afterAttrs[key] !== bboxAffectedStyleBefore[key]) {
        this.clearCache();
        this.getEdges().forEach(function (edge) {
          return edge.refresh();
        });
        break;
      }
    }
  };
  return Node;
}(Item);
export default Node;