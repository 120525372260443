var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { modifyCSS, createDom } from '@antv/dom-util';
import Base from '../base';
import { isArray, isNumber, uniqueId } from '@antv/util';
import { Util } from '@antv/g6-core';
import { Canvas } from '@antv/g-canvas';
var ALLOW_EVENTS = ['click', 'mouseenter'];
var Legend = /** @class */function (_super) {
  __extends(Legend, _super);
  function Legend(config) {
    return _super.call(this, config) || this;
  }
  Legend.prototype.getDefaultCfgs = function () {
    return {
      data: {},
      position: 'top',
      padding: 8,
      margin: 8,
      offsetX: 0,
      offsetY: 0,
      layout: 'horizontal',
      flipPage: false,
      containerStyle: {},
      align: undefined,
      horiSep: 8,
      vertiSep: 8,
      filter: {
        enable: false,
        trigger: 'click'
      }
    };
  };
  Legend.prototype.init = function () {
    this.formatArray('padding');
    this.formatArray('margin');
    var filter = this.get('filter') || {};
    var multiple = filter.multiple;
    if (multiple && filter.trigger === 'mouseenter') this.set('multiple', false);
    var align = this.get('align');
    if (!align) {
      var positions = this.get('position').split('-');
      if (positions.includes('left')) align = 'left';
      if (positions.includes('right')) align = 'right';else align = 'center';
      this.set('align', align);
    }
    var graph = this.get('graph');
    var graphContainer = graph.get('container');
    var container = createDom("<div class='g6-legend-container' style=\"position: absolute;\"></div>");
    graphContainer.appendChild(container);
    this.set('container', container);
    var size = this.render();
    modifyCSS(container, this.getContainerPos(size));
    this.bindEvents();
  };
  Legend.prototype.getContainerPos = function (size) {
    if (size === void 0) {
      size = [0, 0];
    }
    var self = this;
    var graph = self.get('graph');
    var offsetX = this.get('offsetX');
    var offsetY = this.get('offsetY');
    var margin = this.get('margin');
    var positions = this.get('position').split('-');
    var posIdxMap = {
      'top': 0,
      'right': 1,
      'bottom': 2,
      'left': 3
    };
    var x = 0,
      y = 0;
    var containerCSS = {
      left: (graph.getWidth() - size[0]) / 2 + x,
      top: (graph.getHeight() - size[1]) / 2 + y
    };
    positions.forEach(function (pos) {
      var marginValue = margin[posIdxMap[pos]];
      var key = pos;
      switch (pos) {
        case 'top':
          marginValue += y;
          break;
        case 'left':
          marginValue += x;
          break;
        case 'bottom':
          marginValue = graph.getHeight() - size[1] - marginValue + y;
          key = 'top';
          break;
        default:
          marginValue = graph.getWidth() - size[0] - marginValue + x;
          key = 'left';
          break;
      }
      containerCSS[key] = marginValue;
    });
    containerCSS.top += offsetY + graph.getContainer().offsetTop;
    containerCSS.left += offsetX + graph.getContainer().offsetLeft;
    Object.keys(containerCSS).forEach(function (key) {
      containerCSS[key] = "".concat(containerCSS[key], "px");
    });
    return containerCSS;
  };
  // class-methods-use-this
  Legend.prototype.bindEvents = function () {
    var self = this;
    var filter = self.get('filter');
    if (!filter || !filter.enable) return;
    var trigger = filter.trigger || 'click';
    if (!ALLOW_EVENTS.includes(trigger)) {
      console.warn('Trigger for legend filterling must be \'click\' or \'mouseenter\', \'click\' will take effect by default.');
      trigger = 'click';
    }
    var lc = self.get('legendCanvas');
    if (trigger === 'mouseenter') {
      lc.on('node-container:mouseenter', function (e) {
        return self.filterData(e);
      });
      lc.on('node-container:mouseleave', function (e) {
        self.clearFilter();
        self.clearActiveLegend();
      });
    } else {
      lc.on('node-container:click', function (e) {
        return self.filterData(e);
      });
      lc.on('click', function (e) {
        if (e.target && e.target.isCanvas && e.target.isCanvas()) {
          self.clearFilter();
          self.clearActiveLegend();
        }
      });
    }
  };
  /**
   * 更新 legend 数据，开放给用户控制
   * @param param
   */
  Legend.prototype.changeData = function (data) {
    this.set('data', data);
    var size = this.render();
    modifyCSS(this.get('container'), this.getContainerPos(size));
  };
  Legend.prototype.activateLegend = function (shape) {
    var filter = this.get('filter');
    var multiple = filter === null || filter === void 0 ? void 0 : filter.multiple;
    if (!multiple) this.clearActiveLegend();
    var shapeGroup = shape.get('parent');
    // 若被高亮元素已经处于 active 状态，则取消它的 active 状态
    // 并根据目前是否有其他 active 状态的元素决定是否要设置为 inactive 状态
    if (shapeGroup.get('active')) {
      shapeGroup.set('active', false);
      if (this.findLegendItemsByState('active').length) shapeGroup.set('inactive', true);
    } else {
      shapeGroup.set('inactive', false);
      shapeGroup.set('active', true);
    }
    // 当目前有元素为 active 状态时，将非 active 的元素设置为 inactive
    if (this.findLegendItemsByState('active').length) {
      this.findLegendItemsByState('active', 'all', false).forEach(function (subGroup) {
        subGroup.set('inactive', true);
      });
    } else {
      this.clearActiveLegend();
    }
    // 设置样式
    var stateStyles = (filter === null || filter === void 0 ? void 0 : filter.legendStateStyles) || {};
    var legendInactive = (stateStyles === null || stateStyles === void 0 ? void 0 : stateStyles.inactive) || {
      opacity: 0.5,
      'text-shape': {
        opacity: 0.5
      }
    };
    var legendTextInactive = legendInactive['text-shape'] || {};
    this.findLegendItemsByState('inactive').forEach(function (subGroup) {
      var _a = subGroup.get('children'),
        keyShape = _a[0],
        text = _a[1];
      keyShape.attr(__assign(__assign({}, keyShape.get('oriAttrs')), legendInactive));
      text.attr(__assign(__assign({}, text.get('oriAttrs')), legendTextInactive));
    });
    var legendActive = (stateStyles === null || stateStyles === void 0 ? void 0 : stateStyles.active) || {
      stroke: '#000',
      lineWidth: 2,
      'text-shape': {
        fontWeight: 'bold'
      }
    };
    var legendTextActive = legendActive['text-shape'] || {};
    this.findLegendItemsByState('active').forEach(function (subGroup) {
      var _a = subGroup.get('children'),
        keyShape = _a[0],
        text = _a[1];
      keyShape.attr(__assign(__assign({}, keyShape.get('oriAttrs')), legendActive));
      text.attr(__assign(__assign({}, text.get('oriAttrs')), legendTextActive));
    });
  };
  Legend.prototype.findLegendItemsByState = function (stateName, type, value) {
    if (type === void 0) {
      type = 'all';
    }
    if (value === void 0) {
      value = true;
    }
    var group = this.get('legendCanvas').find(function (e) {
      return e.get('name') === 'root';
    });
    var nodeGroup = group.find(function (e) {
      return e.get('name') === 'node-group';
    });
    var edgeGroup = group.find(function (e) {
      return e.get('name') === 'edge-group';
    });
    if (type === 'node') return nodeGroup.get('children').filter(function (g) {
      return !!g.get(stateName) === value;
    });
    if (type === 'edge') return edgeGroup.get('children').filter(function (g) {
      return !!g.get(stateName) === value;
    });
    return nodeGroup.get('children').filter(function (g) {
      return !!g.get(stateName) === value;
    }).concat(edgeGroup.get('children').filter(function (g) {
      return !!g.get(stateName) === value;
    }));
  };
  Legend.prototype.clearActiveLegend = function () {
    var lg = this.get('legendCanvas');
    var group = lg.find(function (e) {
      return e.get('name') === 'root';
    });
    var groups = [group.find(function (e) {
      return e.get('name') === 'node-group';
    }), group.find(function (e) {
      return e.get('name') === 'edge-group';
    })];
    groups.forEach(function (itemGroup) {
      itemGroup.get('children').forEach(function (subGroup) {
        subGroup.set('active', false);
        subGroup.set('inactive', false);
        var _a = subGroup.get('children'),
          keyShape = _a[0],
          text = _a[1];
        keyShape.attr(keyShape.get('oriAttrs'));
        text.attr(text.get('oriAttrs'));
      });
    });
  };
  /**
   * 高亮和置灰图例，并过滤主图元素
   * @param param
   */
  Legend.prototype.filterData = function (e) {
    var filter = this.get('filter');
    var filterFunctions = filter === null || filter === void 0 ? void 0 : filter.filterFunctions;
    if (!filter || !filterFunctions) return;
    var lc = this.get('legendCanvas');
    var graph = this.get('graph');
    var activeState = filter.graphActiveState || 'active';
    var inactiveState = filter.graphInactiveState || 'inactive';
    var multiple = filter.multiple;
    this.clearFilter();
    if (!multiple) this.clearActiveLegend();
    // 设置 legend 的高亮状态
    this.activateLegend(e.target);
    var group = lc.find(function (e) {
      return e.get('name') === 'root';
    });
    var nodeGroup = group.find(function (e) {
      return e.get('name') === 'node-group';
    });
    var edgeGroup = group.find(function (e) {
      return e.get('name') === 'edge-group';
    });
    var activeNodeLegend = nodeGroup.get('children').filter(function (e) {
      return e.get('active');
    });
    var activeEdgeLegend = edgeGroup.get('children').filter(function (e) {
      return e.get('active');
    });
    var activeCount = 0;
    var typeFuncs = ['getNodes', 'getEdges'];
    typeFuncs.forEach(function (typeFunc) {
      graph[typeFunc]().forEach(function (graphItem) {
        var active = false;
        var activeLegend = typeFunc === 'getNodes' ? activeNodeLegend : activeEdgeLegend;
        activeLegend.forEach(function (itemGroup) {
          var func = filterFunctions[itemGroup.get('id')];
          active = active || func(graphItem.getModel());
        });
        if (active) {
          graph.setItemState(graphItem, inactiveState, false);
          graph.setItemState(graphItem, activeState, true);
          activeCount++;
        } else {
          graph.setItemState(graphItem, activeState, false);
          graph.setItemState(graphItem, inactiveState, true);
        }
      });
    });
    if (!activeCount) typeFuncs.forEach(function (typeFunc) {
      graph[typeFunc]().forEach(function (graphItem) {
        graph.clearItemStates(graphItem, [inactiveState]);
      });
    });
  };
  /**
   * 清除主图相关状态
   * @param param
   */
  Legend.prototype.clearFilter = function () {
    // 清除 legend 的高亮状态
    var graph = this.get('graph');
    var filter = this.get('filter');
    if (!filter) return;
    var activeState = filter.graphActiveState || 'active';
    var inactiveState = filter.graphInactiveState || 'inactive';
    graph.getNodes().forEach(function (node) {
      graph.clearItemStates(node, [activeState, inactiveState]);
    });
    graph.getEdges().forEach(function (edge) {
      graph.clearItemStates(edge, [activeState, inactiveState]);
    });
  };
  /**
   * 渲染 legend 图
   * @param param
   */
  Legend.prototype.render = function () {
    var _this = this;
    this.processData();
    var lc = this.get('legendCanvas');
    if (!lc) {
      lc = new Canvas({
        container: this.get('container'),
        width: 200,
        height: 200
      });
    } else {
      lc.clear();
    }
    var group = lc.addGroup({
      name: 'root'
    });
    var nodeGroup = group.addGroup({
      name: 'node-group'
    });
    var edgeGroup = group.addGroup({
      name: 'edge-group'
    });
    this.set('legendCanvas', lc);
    var itemsData = this.get('itemsData');
    var itemTypes = ['nodes', 'edges'];
    var itemGroup = [nodeGroup, edgeGroup];
    itemTypes.forEach(function (itemType, i) {
      itemsData[itemType].forEach(function (data) {
        var _a;
        var subGroup = itemGroup[i].addGroup({
          id: data.id,
          name: 'node-container'
        });
        var attrs;
        var shapeType = data.type;
        var _b = _this.getShapeSize(data),
          width = _b.width,
          height = _b.height,
          r = _b.r;
        var style = _this.getStyle(itemType.substr(0, 4), data);
        switch (data.type) {
          case 'circle':
            attrs = {
              r: r,
              x: 0,
              y: 0
            };
            break;
          case 'rect':
            attrs = {
              width: width,
              height: height,
              x: -width / 2,
              y: -height / 2
            };
            break;
          case 'ellipse':
            attrs = {
              rx: width,
              ry: height,
              x: 0,
              y: 0
            };
            shapeType = 'ellipse';
            break;
          case 'line':
            attrs = {
              x1: -width / 2,
              y1: 0,
              x2: width / 2,
              y2: 0
            };
            shapeType = 'line';
            break;
          case 'quadratic':
            attrs = {
              path: [['M', -width / 2, 0], ['Q', 0, width / 2, width / 2, 0]]
            };
            shapeType = 'path';
            break;
          case 'cubic':
            attrs = {
              path: [['M', -width / 2, 0], ['C', -width / 6, width / 2, width / 6, -width / 2, width / 2, 0]]
            };
            shapeType = 'path';
            break;
          case 'diamond':
            attrs = {
              path: [['M', 0, -height], ['L', width, 0], ['L', 0, height], ['L', -width, 0], ['Z']]
            };
            shapeType = 'path';
            break;
          case 'triangle':
            attrs = {
              path: [['M', -width, height], ['L', 0, -height], ['L', width, height], ['Z']]
            };
            shapeType = 'path';
            break;
          case 'star':
            attrs = {
              path: Util.getStarPath(r * 3, r * 1.2)
            };
            shapeType = 'path';
            break;
          default:
            attrs = {
              r: r,
              x: 0,
              y: 0
            };
            break;
        }
        var keyShape = subGroup.addShape(shapeType, {
          attrs: __assign(__assign({}, attrs), style),
          name: "".concat(data.type, "-node-keyShape"),
          oriAttrs: __assign({
            opacity: 1
          }, style)
        });
        if (data.label) {
          var keyShapeBBox = keyShape.getBBox();
          var labelStyle = ((_a = data.labelCfg) === null || _a === void 0 ? void 0 : _a.style) || {};
          var attrs_1 = __assign({
            textAlign: 'begin',
            fontSize: 12,
            textBaseline: 'middle',
            fill: '#000',
            opacity: 1,
            fontWeight: 'normal'
          }, labelStyle);
          subGroup.addShape('text', {
            attrs: __assign({
              x: keyShapeBBox.maxX + 4,
              y: 0,
              text: data.label
            }, attrs_1),
            className: 'legend-label',
            name: "".concat(data.type, "-node-text"),
            oriAttrs: attrs_1
          });
        }
      });
    });
    var padding = this.get('padding');
    var titleShape;
    var titleGroup = group.find(function (e) {
      return e.get('name') === 'title-container';
    });
    var titleGroupBBox = {
      height: 0,
      maxY: 0,
      width: 0
    };
    if (this.get('title')) {
      if (!titleGroup) {
        titleGroup = group.addGroup({
          name: 'title-container'
        });
      }
      var defaultTitleStyle = {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 300,
        textBaseline: 'top',
        textAlign: 'center',
        fill: "#000",
        x: 0,
        y: padding[0]
      };
      var titleConfig = this.get('titleConfig') || {};
      var style = Object.assign(defaultTitleStyle, titleConfig.style || {});
      titleShape = titleGroup.addShape('text', {
        attrs: __assign({
          text: this.get('title')
        }, style)
      });
      titleGroupBBox = titleGroup.getCanvasBBox();
      titleGroup.setMatrix([1, 0, 0, 0, 1, 0, titleConfig.offsetX, titleConfig.offsetY, 1]);
    }
    this.layoutItems();
    var lcBBox = group.getCanvasBBox();
    var nodeGroupBBox = nodeGroup.getCanvasBBox();
    // 若有图形超过边界的情况，平移回来
    var nodeGroupBeginX = nodeGroupBBox.minX < 0 ? Math.abs(nodeGroupBBox.minX) + padding[3] : padding[3];
    var nodeGroupBeginY = titleGroupBBox.maxY < nodeGroupBBox.minY ? Math.abs(titleGroupBBox.maxY - nodeGroupBBox.minY) + padding[0] : titleGroupBBox.maxY + padding[0];
    var nodeGroupMatrix = [1, 0, 0, 0, 1, 0, nodeGroupBeginX, nodeGroupBeginY, 1];
    nodeGroup.setMatrix(nodeGroupMatrix);
    lcBBox = group.getCanvasBBox();
    var size = [lcBBox.minX + lcBBox.width + padding[1], lcBBox.minY + lcBBox.height + padding[2]];
    // 根据 size 和 titleConfig 调整 title 位置，再调整 nodeGroup 位置
    if (titleShape) {
      var titleConfig = __assign({
        position: 'center',
        offsetX: 0,
        offsetY: 0
      }, this.get('titleConfig'));
      titleGroupBBox = titleGroup.getCanvasBBox();
      var titleGroupMatrix = titleGroup.getMatrix() || [1, 0, 0, 0, 1, 0, 0, 0, 1];
      if (titleConfig.position === 'center') {
        titleGroupMatrix[6] = size[0] / 2 + titleConfig.offsetX;
      } else if (titleConfig.position === 'right') {
        titleGroupMatrix[6] = size[0] - padding[3] + titleConfig.offsetX;
        titleShape.attr({
          textAlign: 'right'
        });
      } else {
        titleGroupMatrix[6] = padding[3] + titleConfig.offsetX;
        titleShape.attr({
          textAlign: 'left'
        });
      }
      titleGroup.setMatrix(titleGroupMatrix);
      titleGroupBBox = titleGroup.getCanvasBBox();
      // 若有图形超过边界的情况，平移回来
      nodeGroupBeginX = nodeGroupBBox.minX < 0 ? Math.abs(nodeGroupBBox.minX) + padding[3] : padding[3];
      nodeGroupBeginY = nodeGroupBBox.minY < titleGroupBBox.maxY ? Math.abs(titleGroupBBox.maxY - nodeGroupBBox.minY) + padding[0] : titleGroupBBox.maxY + padding[0];
      nodeGroupMatrix = [1, 0, 0, 0, 1, 0, nodeGroupBeginX, nodeGroupBeginY, 1];
      nodeGroup.setMatrix(nodeGroupMatrix);
      var edgeGroupMatrix_1 = [1, 0, 0, 0, 1, 0, nodeGroupBeginX, nodeGroupBeginY, 1];
      if (this.get('layout') === 'vertical') edgeGroupMatrix_1[6] += nodeGroupBBox.maxX + this.get('horiSep');else edgeGroupMatrix_1[7] += nodeGroupBBox.maxY + this.get('vertiSep');
      edgeGroup.setMatrix(edgeGroupMatrix_1);
    } else {
      // 没有 title，也需要平移 edgeGroup
      nodeGroupBBox = nodeGroup.getCanvasBBox();
      var edgeGroupMatrix_2 = [1, 0, 0, 0, 1, 0, 0, 0, 1];
      if (this.get('layout') === 'vertical') edgeGroupMatrix_2[6] += nodeGroupMatrix[6] + nodeGroupBBox.maxX + this.get('horiSep');else edgeGroupMatrix_2[7] += nodeGroupMatrix[7] + nodeGroupBBox.maxY + this.get('vertiSep');
      edgeGroup.setMatrix(edgeGroupMatrix_2);
    }
    lcBBox = group.getCanvasBBox();
    nodeGroupBBox = nodeGroup.getCanvasBBox();
    nodeGroupMatrix = nodeGroup.getMatrix() || [1, 0, 0, 0, 1, 0, 0, 0, 1];
    var edgeGroupMatrix = edgeGroup.getMatrix() || [1, 0, 0, 0, 1, 0, 0, 0, 1];
    var edgeGroupBBox = edgeGroup.getCanvasBBox();
    size = [Math.max(nodeGroupBBox.width + nodeGroupMatrix[6], edgeGroupBBox.width + edgeGroupMatrix[6]) + padding[1], Math.max(nodeGroupBBox.height + nodeGroupMatrix[7], edgeGroupBBox.height + edgeGroupMatrix[7]) + padding[2]];
    lc.changeSize(size[0], size[1]);
    // 更新容器背景样式
    var containerStyle = this.get('containerStyle');
    var viewportMatrix = group.getMatrix() || [1, 0, 0, 0, 1, 0, 0, 0, 1];
    var beginPos = Util.invertMatrix({
      x: 0,
      y: 0
    }, viewportMatrix);
    var backRect = group.addShape('rect', {
      attrs: __assign({
        x: beginPos.x + (containerStyle.lineWidth || 1),
        y: beginPos.y + (containerStyle.lineWidth || 1),
        width: size[0] - 2 * (containerStyle.lineWidth || 1),
        height: size[1] - 2 * (containerStyle.lineWidth || 1),
        fill: "#f00",
        stroke: '#000',
        lineWidth: 1,
        opacity: 0.5
      }, containerStyle),
      name: 'legend-back-rect',
      capture: false
    });
    backRect.toBack();
    return size;
  };
  Legend.prototype.layoutItems = function () {
    var lc = this.get('legendCanvas');
    var horiSep = this.get('horiSep');
    var vertiSep = this.get('vertiSep');
    var layout = this.get('layout');
    var align = this.get('align');
    var begin = [0, 0];
    var group = lc.find(function (e) {
      return e.get('name') === 'root';
    });
    var nodeGroup = group.find(function (e) {
      return e.get('name') === 'node-group';
    });
    var edgeGroup = group.find(function (e) {
      return e.get('name') === 'edge-group';
    });
    var nodeLegendSize = {
      min: 0,
      max: -Infinity
    };
    var rowMaxY = -Infinity;
    nodeGroup.get('children').forEach(function (cNodeGroup, i) {
      if (i === 0) nodeLegendSize.min = begin[0];
      var keyShape = cNodeGroup.get('children')[0];
      var bbox = cNodeGroup.getCanvasBBox();
      var _a = keyShape.getBBox(),
        keyShapeWidth = _a.width,
        keyShapeHeight = _a.height;
      var curHeight = 0,
        x = 0,
        y = 0;
      if (layout === 'vertical') {
        x = begin[1];
        y = begin[0] + keyShapeWidth / 2;
        begin[0] = y + bbox.height + vertiSep;
        curHeight = bbox.maxX + x + keyShapeWidth / 2;
      } else {
        x = begin[0] + keyShapeWidth / 2;
        y = begin[1];
        begin[0] = x + bbox.width + horiSep;
        curHeight = bbox.maxY + y + keyShapeHeight / 2;
      }
      if (begin[0] > nodeLegendSize.max) nodeLegendSize.max = begin[0];
      if (curHeight > rowMaxY) rowMaxY = curHeight;
      cNodeGroup.setMatrix([1, 0, 0, 0, 1, 0, x, y, 1]);
    });
    var nw = nodeLegendSize.max - nodeLegendSize.min;
    var edgeLegendSize = {
      min: 0,
      max: -Infinity
    };
    var nodeGroupBBox = nodeGroup.getCanvasBBox();
    begin[0] = 0;
    begin[1] = layout === 'vertical' ? nodeGroupBBox.maxX + horiSep : nodeGroupBBox.maxY + vertiSep;
    edgeGroup.get('children').forEach(function (subGroup, i) {
      if (i === 0) edgeLegendSize.min = begin[0];
      var keyShape = subGroup.get('children')[0];
      var bbox = subGroup.getCanvasBBox();
      var _a = keyShape.getBBox(),
        keyShapeWidth = _a.width,
        keyShapeHeight = _a.height;
      var x = 0,
        y = 0;
      if (layout === 'vertical') {
        x = begin[1];
        y = begin[0];
        begin[0] = y + bbox.height + vertiSep;
        subGroup.setMatrix([1, 0, 0, 0, 1, 0, 0, y + keyShapeHeight / 2, 1]);
      } else {
        x = begin[0];
        y = begin[1];
        begin[0] = x + bbox.width + horiSep;
        subGroup.setMatrix([1, 0, 0, 0, 1, 0, x + keyShapeWidth / 2, 0, 1]);
      }
      if (begin[0] > edgeLegendSize.max) edgeLegendSize.max = begin[0];
    });
    var ew = edgeLegendSize.max - edgeLegendSize.min;
    if (align && align !== '' && align !== 'left') {
      var widthDiff = nw - ew;
      var movement_1 = align === 'center' ? Math.abs(widthDiff) / 2 : Math.abs(widthDiff);
      var shouldAdjustGroup = widthDiff < 0 ? nodeGroup : edgeGroup;
      shouldAdjustGroup.get('children').forEach(function (subGroup) {
        var matrix = subGroup.getMatrix() || [1, 0, 0, 0, 1, 0, 0, 0, 1];
        if (layout === 'vertical') matrix[7] += movement_1;else matrix[6] += movement_1;
        subGroup.setMatrix(matrix);
      });
    }
  };
  Legend.prototype.processData = function () {
    var data = this.get('data');
    var itemsData = {
      nodes: [],
      edges: []
    };
    if (data.nodes) {
      data.nodes.sort(function (a, b) {
        return a.order - b.order;
      });
      data.nodes.forEach(function (node) {
        var _a, _b, _c, _d, _e;
        var size = node.size || [((_a = node.style) === null || _a === void 0 ? void 0 : _a.width) || ((_b = node.style) === null || _b === void 0 ? void 0 : _b.r) || 8, ((_c = node.style) === null || _c === void 0 ? void 0 : _c.height) || ((_d = node.style) === null || _d === void 0 ? void 0 : _d.r) || 8];
        var labelStyle = ((_e = node.labelCfg) === null || _e === void 0 ? void 0 : _e.style) || {};
        itemsData.nodes.push({
          id: node.id || uniqueId(),
          type: node.type || 'circle',
          style: __assign({}, node.style),
          order: node.order,
          label: node.label,
          itemType: 'node',
          size: size,
          labelCfg: {
            position: 'right',
            style: __assign({
              fontFamily: "Arial"
            }, labelStyle)
          }
        });
      });
    }
    if (data.edges) {
      data.edges.sort(function (a, b) {
        return a.order - b.order;
      });
      data.edges.forEach(function (edge) {
        var _a, _b;
        var type = edge.type || 'line';
        if (edge.type === 'cubic-horizontal') type = 'cubic';
        var labelStyle = ((_a = edge.labelCfg) === null || _a === void 0 ? void 0 : _a.style) || {};
        var size = edge.size || [((_b = edge.style) === null || _b === void 0 ? void 0 : _b.width) || 8, 1];
        itemsData.edges.push({
          id: edge.id || uniqueId(),
          type: type,
          size: size,
          style: __assign({
            lineWidth: isArray(size) ? size[1] : 1
          }, edge.style),
          order: edge.order,
          label: edge.label,
          itemType: 'edge',
          labelCfg: {
            position: 'right',
            style: __assign({
              fontFamily: "Arial"
            }, labelStyle)
          }
        });
      });
    }
    this.set('itemsData', itemsData);
  };
  Legend.prototype.getContainer = function () {
    return this.get('container');
  };
  Legend.prototype.formatArray = function (key) {
    var value = this.get(key);
    if (isNumber(value)) this.set(key, [value, value, value, value]);else if (isArray(value)) {
      switch (value.length) {
        case 0:
          this.set(key, [0, 0, 0, 0]);
          break;
        case 1:
          this.set(key, [value[0], value[0], value[0], value[0]]);
          break;
        case 2:
          this.set(key, [value[0], value[1], value[0], value[1]]);
          break;
        case 3:
          this.set(key, [value[0], value[1], value[2], value[1]]);
          break;
        default:
          break;
      }
    }
    return this.get(key);
  };
  Legend.prototype.getShapeSize = function (data) {
    var width, height, r;
    if (data.size) {
      if (isArray(data.size)) {
        width = data.size[0];
        height = data.size[1] || data.size[0];
        r = data.size[0] / 2;
      } else if (isNumber(data.size)) {
        width = data.size;
        height = data.size;
        r = data.size / 2;
      }
    }
    if (data.style) {
      if (data.style.width) width = data.style.width;
      if (data.style.height) height = data.style.height;
      if (data.style.r) r = data.style.r;
    }
    if (!r) r = 5;
    if (!width) width = r;
    if (!height) height = r;
    return {
      width: width,
      height: height,
      r: r
    };
  };
  Legend.prototype.getStyle = function (type, data) {
    var defaultStyle = type === 'node' ? {
      fill: '#ccc',
      lineWidth: 0
    } : {
      stroke: '#000',
      lineWidth: 1
    };
    return __assign(__assign({}, defaultStyle), data.style || {});
  };
  Legend.prototype.destroy = function () {
    var lc = this.get('legendCanvas');
    lc === null || lc === void 0 ? void 0 : lc.destroy();
    var graph = this.get('graph');
    var graphContainer = graph.get('container');
    var container = this.get('container');
    graphContainer.removeChild(container);
  };
  return Legend;
}(Base);
export default Legend;